import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  Stack,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddToCartButton from "../product/AddToCartButton";
import QuantitySelector from "../product/QuantitySelector";

const DealsOfTheDayCard = ({ product }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 12,
    minutes: 10,
    seconds: 22,
  });
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const discountPercentage = Math.round(
    ((product.price - product.discounted_price) / product.price) * 100
  );

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { serverCart, guestCart } = useSelector((state) => state.cart);

  const cart = isAuthenticated ? serverCart : guestCart;
  const cartItem = cart.find(item => item.product?.id === product.id);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        let { hours, minutes, seconds } = prev;
        if (seconds > 0) seconds--;
        else if (minutes > 0) {
          minutes--;
          seconds = 59;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
        } else {
          clearInterval(timer);
        }
        return { hours, minutes, seconds };
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);



  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        borderRadius: { xs: 2, sm: 4 },
        boxShadow: { xs: '0 4px 12px rgba(0,0,0,0.1)', sm: '0 8px 24px rgba(0,0,0,0.12)' },
        overflow: "hidden",
        maxWidth: { xs: '100%', sm: 600 },
        cursor: "pointer",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        p: { xs: 2, sm: 2.5 },
        gap: { xs: 2, sm: 3 },
        height: "100%",
        alignItems: "center",
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        backgroundColor: "#ffffff",
        position: 'relative',
        '&:hover': {
          transform: { xs: 'none', sm: "translateY(-4px)" },
          boxShadow: { xs: '0 4px 12px rgba(0,0,0,0.1)', sm: '0 12px 32px rgba(0,0,0,0.15)' },
        },
      }}
    >
      {/* Glowing Discount Badge */}
      <Chip
        label={`${discountPercentage || "0"}% OFF`}
        sx={{
          position: "absolute",
          top: { xs: 8, sm: 16 },
          left: { xs: 8, sm: 16 },
          borderRadius: 2,
          background: 'linear-gradient(135deg, #ff5f6d, #ffc371)',
          color: "#fff",
          fontSize: { xs: '12px', sm: '14px' },
          fontWeight: 800,
          px: { xs: 1, sm: 1.5 },
          py: { xs: 0.5, sm: 1 },
          zIndex: 2,
          boxShadow: '0 4px 12px rgba(255, 94, 108, 0.4)',
          '& .MuiChip-label': { padding: 0 }
        }}
      />

      {/* Product Image Container */}
      <Box
        sx={{
          position: "relative",
          flex: "1 1 45%",
          width: "100%",
          borderRadius: { xs: 2, sm: 3 },
          overflow: 'hidden',
          transformStyle: 'preserve-3d',
          '&:hover img': {
            transform: { xs: 'none', sm: 'scale(1.05)' },
          },
        }}
      >
        <Box
          component="img"
          src={product.image || "https://via.placeholder.com/150"}
          alt="Product"
          sx={{
            width: "100%",
            height: { xs: 150, sm: 180, md: 240 },
            objectFit: "contain",
            transition: 'transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            transformOrigin: 'center center',
          }}
        />
        {/* Image Overlay Gradient */}
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '40%',
          background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, transparent 100%)',
          opacity: isHovered ? 1 : 0,
          transition: 'opacity 0.3s ease',
          display: { xs: 'none', sm: 'block' },
        }} />
      </Box>

      {/* Product Details */}
      <CardContent
        sx={{
          flex: "1 1 55%",
          display: "flex",
          flexDirection: "column",
          gap: { xs: 1.5, sm: 2.5 },
          p: 0,
          position: 'relative',
          width: '100%',
        }}
      >
        {/* Pricing */}
        <Box>
          <Typography variant="h5" sx={{
            fontWeight: 800,
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            color: theme.palette.primary.main,
            lineHeight: 1.2,
            mb: 0.5
          }}>
            ₹{product.discounted_price}
            <Typography component="span" sx={{
              textDecoration: "line-through",
              fontSize: { xs: '0.75rem', sm: '0.9rem' },
              color: "#999",
              marginLeft: { xs: 1, sm: 1.5 },
              fontWeight: 500,
            }}>
              ₹{product.price}
            </Typography>
          </Typography>

          <Typography variant="h6" sx={{
            fontWeight: 700,
            fontSize: { xs: '1rem', sm: '1.25rem' },
            color: "#222",
            lineHeight: 1.3,
            mb: { xs: 0.5, sm: 1 }
          }}>
            {product.name}
          </Typography>

          <Chip
            label={`${product.item_quantity} ${product.product_unit}`}
            sx={{
              bgcolor: '#f5f5f5',
              color: '#666',
              fontWeight: 600,
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
              borderRadius: 1.5,
              px: { xs: 1, sm: 1.5 },
              height: { xs: 24, sm: 32 }
            }}
          />
        </Box>

        {/* Progress Bar with Label */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="caption" sx={{
              fontSize: { xs: '0.65rem', sm: '0.75rem' },
              color: '#4CAF50',
              fontWeight: 600
            }}>
              70% Sold
            </Typography>
            <Typography variant="caption" sx={{
              fontSize: { xs: '0.65rem', sm: '0.75rem' },
              color: '#666',
              fontWeight: 500
            }}>
              Limited Stock
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={70}
            sx={{
              height: { xs: 8, sm: 10 },
              borderRadius: 4,
              backgroundColor: '#EDEDED',
              '& .MuiLinearProgress-bar': {
                background: 'linear-gradient(90deg, #4CAF50 0%, #81C784 100%)',
                borderRadius: 4,
                boxShadow: '0 2px 4px rgba(76, 175, 80, 0.2)',
              },
            }}
          />
        </Box>

        {/* Countdown Timer */}
        <Box sx={{ mt: 1 }}>
          <Typography variant="caption" sx={{
            display: 'block',
            fontSize: { xs: '0.7rem', sm: '0.8rem' },
            color: '#666',
            fontWeight: 500,
            mb: { xs: 1, sm: 1.5 }
          }}>
            Offer ends in:
          </Typography>
          <Stack direction={"row"} spacing={{ xs: 1, sm: 2 }}>
            {Object.entries(timeLeft).map(([unit, value]) => (
              <Box key={unit} sx={{
                bgcolor: '#FFF3E0',
                borderRadius: 2,
                p: { xs: 1, sm: 1.5 },
                textAlign: 'center',
                flex: 1,
                boxShadow: '0 2px 8px rgba(255, 152, 0, 0.1)',
              }}>
                <Typography variant="h5" sx={{
                  fontWeight: 800,
                  fontSize: { xs: '1.1rem', sm: '1.5rem' },
                  color: '#EF6C00',
                  lineHeight: 1
                }}>
                  {String(value).padStart(2, "0")}
                </Typography>
                <Typography variant="caption" sx={{
                  fontSize: { xs: '0.6rem', sm: '0.75rem' },
                  textTransform: 'uppercase',
                  color: '#EF6C00',
                  fontWeight: 600,
                  letterSpacing: 0.5,
                }}>
                  {unit}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </CardContent>

      {/* Hover Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: 'rgba(255,255,255,0.95)',
          display: { xs: 'none', sm: "flex" },
          alignItems: "center",
          justifyContent: "center",
          opacity: isHovered ? 1 : 0,
          transition: 'all 0.3s ease',
          backdropFilter: 'blur(4px)',
          borderRadius: 4,
        }}
      >
        <Box sx={{
          width: '80%',
          transform: isHovered ? 'translateY(0)' : 'translateY(10px)',
          transition: 'transform 0.3s ease',
        }}>
          {cartItem ? (
            <QuantitySelector
              cartItem={cartItem}
              size={isSmallScreen?"medium":"large"}
            />
          ) : (
            <AddToCartButton
              product={product}
              size={isSmallScreen?"medium":"large"}

            />
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default DealsOfTheDayCard;