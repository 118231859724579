import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppRoutes from "./routes";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useThemeContext } from "./context/ThemeContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga";
import {getDeviceToken } from "./components/notifications/firebase";
import { Toaster } from "react-hot-toast";
import DownloadAppModal from "./components/core/DownloadAppModal";


const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const { theme } = useThemeContext();
  const { pathname } = useLocation();
  

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(pathname);
  }, [pathname]);

  useEffect(() => {
    const fetchDeviceToken = async () => {
      const token = await getDeviceToken();
      if (token) {
        localStorage.setItem("deviceToken", token); 
      }
    };
  
    fetchDeviceToken();
  }, []);
  

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
    >
      <MuiThemeProvider theme={theme}>
        <Toaster
          position="top-right"
          reverseOrder={true}
          gutter={-50}
          
        />
        <CssBaseline />
        <DownloadAppModal/>
        <AppRoutes />
      </MuiThemeProvider>
    </GoogleOAuthProvider>

  );
};

export default App;
