import { Box, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';

const AnnouncementBar = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                backgroundColor: 'secondary.main',
                color: 'white',
                py: 1,
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            {/* Marquee Section */}
            <Box sx={{ 
                flex: 1, 
                overflow: 'hidden',
                width: { xs: '100%', sm: 'auto' },
                borderBottom: { xs: '1px solid rgba(255, 255, 255, 0.5)', sm: 'none' },
                pb: { xs: 1, sm: 0 },
            }}>
                <Box
                    sx={{
                        animation: 'marquee 20s linear infinite',
                        whiteSpace: 'nowrap',
                        display: 'inline-block',
                        '@keyframes marquee': {
                            '0%': { transform: 'translateX(0%)' },
                            '100%': { transform: 'translateX(-100%)' },
                        },
                        fontSize: { xs: '0.75rem', sm: 'inherit' }
                    }}
                >
                    {Array(4).fill("🎉 Free Shipping on Orders Over ₹600 🎉 ")}
                </Box>
            </Box>

            {/* Order Timing Banner */}
            <Box
                sx={{
                    flexShrink: 0,
                    px: { xs: 1, sm: 2 },
                    borderLeft: { 
                        xs: 'none', 
                        sm: '1px solid rgba(255, 255, 255, 0.5)' 
                    },
                    marginLeft: { xs: 0, sm: 2 },
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    pt: { xs: 1, sm: 0 },
                }}
            >
                <CampaignIcon sx={{ fontSize: { xs: '16px', sm: '20px' } }} />
                <Box component="span">
                    Order Timing 6AM to 2PM. 
                    <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                        {" "}Orders placed after 2PM will be received next day.
                    </Box>
                    <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>
                        {" "}After 2PM = Next day
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AnnouncementBar;