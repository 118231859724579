import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useApiFetch from '../hooks/useApiFetch';
import Loading from '../components/core/Loading';
import {
    Container, Typography, Box, Button, Divider, IconButton,
    Grid, Card, CardContent, Menu, MenuItem, Avatar, Chip, useTheme
} from '@mui/material';
import {
    Share, Person, CalendarToday, Label, ContentCopy,
    WhatsApp, Twitter, Facebook, ChatBubble
} from '@mui/icons-material';
import CommentBox from '../components/blog/CommentBox';
import api from '../apiCollection';
import { toast } from 'react-toastify';
import Seo from '../components/Seo/Seo';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const BlogDetailPage = () => {
    const theme = useTheme();
    const location = useLocation();
    const blogName = decodeURIComponent(location.pathname.split('/')[2]);
    const { data: allBlogsData, loading: allBlogsLoading, error: allBlogsError } = useApiFetch(api.blog.getAll);
    const [blogId, setBlogId] = useState(null);
    const { data: blogData, loading: blogLoading, error: blogError } = useApiFetch(`${api.blog.getAll}/${blogId}`);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        if (allBlogsData) {
            const foundBlog = allBlogsData.data.find(blog => blog.title === blogName);
            if (foundBlog) setBlogId(foundBlog.id);
        }
    }, [allBlogsData, blogName]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleShare = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const shareActions = [
        {
            icon: <ContentCopy />, label: 'Copy Link', action: () => {
                navigator.clipboard.writeText(window.location.href);
                toast.success('Link copied!');
            }
        },
        {
            icon: <WhatsApp />, label: 'WhatsApp', action: () =>
                window.open(`https://wa.me/?text=${encodeURIComponent(window.location.href)}`, '_blank')
        },
        {
            icon: <Twitter />, label: 'Twitter', action: () =>
                window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, '_blank')
        },
        {
            icon: <Facebook />, label: 'Facebook', action: () =>
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, '_blank')
        },
    ];

    if (allBlogsLoading || blogLoading) return <Loading />;
    if (allBlogsError || blogError) return <p>Error fetching data</p>;
    if (!blogData) return <p>Blog not found</p>;

    const { title, author, updated_at, tags, content, image, blog_comments } = blogData.data;
    

    return (
        <Container maxWidth="lg" sx={{ py: { xs: 3, md: 6 } }}>
            <Seo title={title} />

            <Grid container spacing={{ xs: 2, md: 6 }} sx={{ animation: `${fadeIn} 0.6s ease` }}>
                {/* Main Content */}
                <Grid item xs={12} md={8}>
                    <Card sx={{
                        borderRadius: { xs: 2, md: 4 },
                        boxShadow: theme.shadows[4],
                        bgcolor: 'background.paper'
                    }}>
                        <CardContent sx={{ p: { xs: 2, md: 4 } }}>
                            <Typography variant="h2" sx={{
                                mb: { xs: 2, md: 3 },
                                fontSize: { xs: '1.8rem', md: '2.5rem' },
                                fontWeight: 800,
                                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}>
                                {title}
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'start', sm: 'center' },
                                gap: 1,
                                mb: { xs: 3, md: 4 },
                                '& .MuiChip-root': {
                                    borderRadius: 2,
                                    py: { xs: 0.5, md: 1 },
                                    px: { xs: 1, md: 2 },
                                    fontSize: { xs: '0.8rem', md: '1rem' },
                                    transition: 'all 0.3s ease',
                                    '&:hover': { transform: 'translateY(-2px)' }
                                }
                            }}>
                                <Chip
                                    icon={<Person fontSize="small" color="primary" />}
                                    label={author}
                                    variant="outlined"
                                    color="primary"
                                />
                                <Chip
                                    icon={<CalendarToday fontSize="small" color="secondary" />}
                                    label={formatDate(updated_at)}
                                    variant="outlined"
                                    color="secondary"
                                />
                                <Chip
                                    icon={<Label fontSize="small" color="success" />}
                                    label={tags}
                                    variant="outlined"
                                    color="success"
                                />
                            </Box>

                            <Box sx={{
                                '& p': {
                                    fontSize: { xs: '1rem', md: '1.1rem' },
                                    lineHeight: 1.8,
                                    mb: 3,
                                    position: 'relative',
                                    '&:first-of-type:first-letter': {
                                        fontSize: { xs: '3rem', md: '4rem' },
                                        float: 'left',
                                        lineHeight: 0.8,
                                        mr: 2,
                                        color: theme.palette.primary.main,
                                        fontWeight: 700
                                    },
                                    '&:not(:last-child):after': {
                                        content: '""',
                                        display: 'block',
                                        height: 1,
                                        background: `linear-gradient(90deg, transparent, ${theme.palette.divider}, transparent)`,
                                        my: 4
                                    }
                                }
                            }}>
                                <Typography paragraph>{content}</Typography>
                            </Box>

                            <Button
                                variant="contained"
                                startIcon={<Share fontSize="small" />}
                                onClick={handleShare}
                                sx={{
                                    px: { xs: 2, md: 4 },
                                    py: { xs: 1, md: 1.5 },
                                    borderRadius: 50,
                                    fontSize: { xs: '0.875rem', md: '1rem' },
                                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                    '&:hover': { transform: 'scale(1.05)' },
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                Share Article
                            </Button>

                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                sx={{ 
                                    '& .MuiPaper-root': { 
                                        borderRadius: 3, 
                                        p: 1,
                                        minWidth: 200 
                                    } 
                                }}
                            >
                                {shareActions.map((action, i) => (
                                    <MenuItem
                                        key={i}
                                        onClick={() => { action.action(); handleClose(); }}
                                        sx={{
                                            borderRadius: 2,
                                            '&:hover': { bgcolor: 'action.hover' },
                                            transition: 'all 0.2s ease'
                                        }}
                                    >
                                        <IconButton sx={{ color: 'text.primary', mr: 1 }}>
                                            {React.cloneElement(action.icon, { fontSize: 'small' })}
                                        </IconButton>
                                        <Typography variant="body2">{action.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Sidebar */}
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        position: { xs: 'static', md: 'sticky' },
                        top: { md: 100 },
                        borderRadius: 4,
                        overflow: 'hidden',
                        boxShadow: theme.shadows[4],
                        zIndex: 1,
                        transition: 'transform 0.3s ease',
                        '@media (hover: hover)': {
                            '&:hover': {
                                transform: 'translateY(-4px)',
                                '& img': { transform: 'scale(1.05)' }
                            }
                        }
                    }}>
                        <Box sx={{
                            position: 'relative',
                            paddingTop: '125%',
                            '& img': {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                transition: 'transform 0.4s ease',
                            }
                        }}>
                            <img
                                src={image}
                                alt="Blog cover"
                                loading="lazy"
                            />
                            <Box sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: '40%',
                                background: `linear-gradient(to top, ${theme.palette.mode === 'dark'
                                    ? 'rgba(0,0,0,0.8)'
                                    : 'rgba(0,0,0,0.6)'} 0%, transparent 100%)`
                            }} />
                        </Box>
                    </Box>

                    <Box sx={{ 
                        mt: { xs: 2, md: 4 },
                        p: { xs: 1, md: 3 },
                        bgcolor: 'transparent',
                        borderRadius: 4,
                        zIndex: 2,
                        position: "relative"
                    }}>
                        <CommentBox blogId={blogId} blogComments={blog_comments} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BlogDetailPage;