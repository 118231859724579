import axios from "axios";
import api from "../apiCollection";

export const fetchProducts = async ({ page, ...filters }) => {
    try {
      const params = {
        page,
        ...(filters.category && { category: filters.category }),
        ...(filters.tags?.length > 0 && { tags: filters.tags.join(",") }),
        ...(filters.sort && { sort: filters.sort }),
      };
  
      const response = await axios.get(api.products.getAll, {
        params
      });
      return response.data.data; // Contains products, current_page, last_page
    } catch (error) {
      if (axios.isCancel(error)) {
        
        return { products: [], current_page: 1, last_page: 1 };
      }
      console.error("Error fetching products:", error.message);
      return { products: [], current_page: 1, last_page: 1 };
    }
  };


export const fetchProductById = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.getById}/${id}`);
        return data.data;
    } catch(error){
        
    }
}

export const fetchProductBySlug = async(slug)=>{
    try{
        const {data} = await axios.get(`${api.products.getBySlug}/${slug}`);
        return data.data;
    } catch(error){
        
    }
}


export const fetchBestSellers = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.bestSellers}`);
        return data.data;
    } catch(error){
        
    }
}



export const fetchDealOfTheDay = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.dealOfTheDay}`);
        return data.data;
    } catch(error){
        
    }
}

export const fetchNewProducts = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.newProducts}`);
        return data.data;
    } catch(error){
        
    }
}



