import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  TextField,
  Typography,
  Box,
  InputAdornment,
  CircularProgress,
  useTheme,
  IconButton,
  Grid
} from '@mui/material';
import { toast } from 'react-hot-toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { verifyOTP } from '../redux/slices/authSlice';
import { useDispatch } from 'react-redux';

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state?.mobile || !state?.otp) {
      toast.error('Invalid registration session');
      navigate('/');
    }
  }, [state, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await dispatch(
        verifyOTP({
          phone_number: state.mobile,
          otp: state.otp,
          name: formData.name,
          email: formData.email
        })
      ).unwrap();

      toast.success('Registration successful!');
      navigate('/');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <Container maxWidth="lg" sx={{
      minHeight: '80vh',
      display: 'flex',
      alignItems: 'center',
      position: 'relative'
    }}>

      <Grid container>

        <Grid item sx={12} sm={6}>  <Box sx={{
          width: '100%',
          p: { xs: 0, md: 4 },
          mt: { xs: 2, md: 0 },
          backgroundColor: 'background.paper',
          position: "relative"
        }}>

          <Typography color={"primary"} variant="h4" component="h1" align="center" gutterBottom sx={{ fontWeight: 700 }}>
            Complete Your Profile
          </Typography>
          <Typography variant="body1" align="center" color="text.secondary" mb={4}>
            Almost there! Just a few more details
          </Typography>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
            p: 2,
            borderRadius: 2,
            backgroundColor: theme.palette.grey[100]
          }}>
            <SmartphoneIcon color="action" sx={{ mr: 1 }} />
            <Typography variant="body1">
              {state?.mobile || 'No mobile number provided'}
            </Typography>
          </Box>

          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="action" />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: 50,
                  style: { fontSize: '1.1rem' }
                }
              }}
              sx={{ mb: 3 }}
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="action" />
                  </InputAdornment>
                ),
                inputProps: {
                  pattern: '[^@\\s]+@[^@\\s]+\\.[^@\\s]+',
                  style: { fontSize: '1.1rem' }
                }
              }}
              sx={{ mb: 3 }}
            />

            <Button
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              disabled={!formData.name || !formData.email || isLoading}
              sx={{
                py: 1.5,
                borderRadius: 2,
                textTransform: 'none',
                bgcolor: theme.palette.primary.main,
                '&:hover': {
                  bgcolor: theme.palette.primary.dark
                }
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Complete Registration'
              )}
            </Button>

            <Typography variant="body2" align="center" sx={{ mt: 3, color: 'text.secondary' }}>
              By continuing, you agree to our Terms of Service and Privacy Policy
            </Typography>
          </Box>
        </Box></Grid>

        <Grid item sx={12} sm={6}>
          <Box sx={{ width: "100%", height: "100%", maxHeight: "550px" }}>
            <img src="/signup_illustration.svg" style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="" />
          </Box>
        </Grid>
      </Grid>

    </Container>
  );
};

export default RegistrationPage;