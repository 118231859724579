import React, { useState } from 'react';
import {
    Container, TextField, Button, Card, Typography,
    IconButton, InputAdornment, Box, Grid, Divider, useMediaQuery,
    useTheme, alpha, styled
} from '@mui/material';
import { Lock, Mail, VisibilityOff, Visibility } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/authSlice';
import GoogleLoginButton from '../components/google-auth/GoogleLoginButton';
import Seo from '../components/Seo/Seo';
import { useNavigate } from 'react-router-dom';
import fcmTokenService from '../services/fcmTokenService';
import { Link } from 'react-router-dom';

const AuthCard = styled(Card)(({ theme }) => ({
    borderRadius: 16,
    background: alpha(theme.palette.background.paper, 0.8),
    boxShadow: theme.shadows[2],
    padding: theme.spacing(3),
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    maxWidth: 400,
    margin: '0 auto',
    width: '100%'
}));



const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userData = await dispatch(
                login({ email, password })
            ).unwrap();
            
            const deviceToken = localStorage.getItem('deviceToken');
            if (deviceToken) {
                try {
                    await fcmTokenService.saveFcmToken(deviceToken);
                } catch (fcmError) {
                    console.error('FCM token registration failed:', fcmError);
                }
            }

            navigate(-1);
        } catch (err) {
            console.error('Login error:', err);
            setError(err.message || 'Invalid email or password');
        }
    };



    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            py: 2
        }}>
            <Seo title="Login - Vegingo" />
            <Container maxWidth="lg">
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <AuthCard>
                            <Box textAlign="center" mb={3}>
                                <Typography variant="h5" gutterBottom sx={{
                                    fontWeight: 700,
                                    color:"primary.main"
                                }}>
                                    Welcome Back
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Sign in to continue your fresh journey
                                </Typography>
                            </Box>

                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Mail fontSize="small" color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 1,
                                            '& fieldset': { borderColor: 'divider' },
                                            '&:hover fieldset': { borderColor: 'primary.main' }
                                        }
                                    }}
                                    required
                                />

                                <TextField
                                    fullWidth
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock fontSize="small" color="primary" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                    size="small"
                                                    sx={{ color: 'text.secondary' }}
                                                >
                                                    {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 1,
                                            '& fieldset': { borderColor: 'divider' },
                                            '&:hover fieldset': { borderColor: 'primary.main' }
                                        }
                                    }}
                                    required
                                />


                                {error && (
                                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                        ⚠️ {error}
                                    </Typography>
                                )}

                                <Button
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    disabled={isSubmitting}
                                    sx={{
                                        mt: 2,
                                        py: 1,
                                        borderRadius: 1,
                                        fontSize: '0.875rem',
                                        background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: theme.shadows[2]
                                        },
                                        transition: 'all 0.2s ease',
                                        color: "white"
                                    }}
                                >
                                    {isSubmitting ? 'Signing In...' : 'Sign In'}
                                </Button>

                                <Box my={2}>
                                    <Divider sx={{ fontSize: '0.75rem' }}>OR CONTINUE WITH</Divider>
                                </Box>

                                <GoogleLoginButton />

                                <Button fullWidth onClick={()=>navigate("/send-otp")} variant='contained' color='secondary' sx={{mt:2}}>Mobile Number (test)</Button>

                                <Box mt={2} textAlign="center">
                                    <Typography variant="body2">
                                        Don't have an account?{' '}
                                        <Link
                                            to="/account/signup"
                                            style={{
                                                fontSize: '0.875rem',
                                                fontWeight: 500,
                                                color: theme.palette.primary.main,
                                                textDecoration: 'none'
                                            }}
                                        >
                                            Sign up
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" mt={1} sx={{ fontSize: '0.875rem' }}>
                                        <Link
                                            to="/forgot-password"
                                            style={{ color: theme.palette.error.main }}
                                        >
                                            Forgot password?
                                        </Link>
                                    </Typography>
                                </Box>
                            </form>
                        </AuthCard>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box style={{widht:"100%", height:"450px"}}>
                        <img style={{widht:"100%", height:"100%", objectFit:"contain"}} src="/login_illustration.jpg" alt="" />

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default LoginPage;