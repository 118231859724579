import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Stack,
  Box,
  Grid,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { Replay, ShoppingCartCheckout } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { addToCart } from '../../redux/slices/cartSlice';

const reorderService = {
  createReorder: (orderItems) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (Math.random() > 0.1) {
          resolve({
            success: true,
            data: orderItems.map(item => ({
              productId: item.product.id,
              quantity: item.quantity
            }))
          });
        } else {
          reject(new Error('Failed to add items to cart. Please try again.'));
        }
      }, 1000);
    });
  }
};

const Reorder = () => {
  const { orderHistory } = useSelector((state) => state.order);
  const [processingOrders, setProcessingOrders] = useState([]);
  const dispatch = useDispatch();

  const handleReorder = async (orderId, items) => {
    setProcessingOrders(prev => [...prev, orderId]);

    try {
      // Dispatch addToCart for each item in the order
      for (const item of items) {
        await dispatch(addToCart({
          id: item.product.id,
          product_unit: item.quantity
        }));
      }

      toast.success(`Added ${items.length} items from Order #${orderId} to cart!`, {
        icon: <ShoppingCartCheckout />,
        position: 'bottom-center'
      });
    } catch (error) {
      toast.error(error.message, { position: 'bottom-center' });
    } finally {
      setProcessingOrders(prev => prev.filter(id => id !== orderId));
    }
  };

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleDateString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });

  return (
    <Box sx={{ p: 3, maxWidth: '1200px', mx: 'auto' }}>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight: 600,
        mb: 4,
        display: 'flex',
        alignItems: 'center',
        gap: 1.5
      }}>
        <Replay fontSize="medium" /> Reorder Previous Purchases
      </Typography>

      {orderHistory?.length === 0 ? (
        <Box sx={{
          textAlign: 'center',
          p: 4,
          borderRadius: 2,
          bgcolor: 'background.paper'
        }}>
          <Typography variant="body1" color="text.secondary">
            No previous orders available for reorder
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {orderHistory?.map((order) => (
            <Grid item xs={12} key={order.id}>
              <Card sx={{
                boxShadow: 1,
                borderLeft: '3px solid',
                borderColor: 'primary.main',
                position: 'relative'
              }}>
                <CardContent>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                      <Typography variant="subtitle1" fontWeight="600">
                        Order #{order.id}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {formatDate(order.created_at)}
                      </Typography>
                    </div>

                    <Chip
                      label={`${order.order_item.length} items`}
                      size="small"
                      variant="outlined"
                    />
                  </Stack>

                  <Divider sx={{ my: 2 }} />

                  <Box sx={{ mb: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                      INCLUDED ITEMS:
                    </Typography>
                    <List dense sx={{ maxHeight: 150, overflow: 'auto' }}>
                      {order.order_item.map((item) => (
                        <ListItem key={item.id} sx={{ px: 0 }}>
                          <Avatar
                            src={item.product.image}
                            variant="rounded"
                            sx={{ width: 40, height: 40, mr: 2 }}
                          />
                          <ListItemText
                            primary={item.product.name}
                            secondary={`${item.quantity} × ₹${item.product.discounted_price}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={
                        processingOrders.includes(order.id) ? (
                          <CircularProgress size={18} color="inherit" />
                        ) : (
                          <Replay fontSize="small" />
                        )
                      }
                      disabled={processingOrders.includes(order.id)}
                      onClick={() => handleReorder(order.id, order.order_item)}
                      sx={{ minWidth: 180 }}
                    >
                      {processingOrders.includes(order.id)
                        ? 'Adding to Cart...'
                        : 'Reorder This Order'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Reorder;