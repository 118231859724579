import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    CircularProgress,
    IconButton,
    useMediaQuery,
    useTheme,
    keyframes
} from "@mui/material";
import { LocationOn, Close } from "@mui/icons-material";

const scrollText = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const LocationSelector = () => {
    const [open, setOpen] = useState(false);
    const [location, setLocation] = useState("Select Location");
    const [pincode, setPincode] = useState("");
    const [loading, setLoading] = useState(false);
    const [geoError, setGeoError] = useState("");
    const [pincodeError, setPincodeError] = useState("");

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const fetchCurrentLocation = useCallback(() => {
        setLoading(true);
        setGeoError("");

        if (!navigator.geolocation) {
            setGeoError("Geolocation is not supported by your browser.");
            setLoading(false);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;
                try {
                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                    );
                    const data = await response.json();
                    if (data.address) {
                        setLocation(`${data.address.city || data.address.town}, ${data.address.state}`);
                        setOpen(false);
                    } else {
                        setGeoError("Could not fetch location details.");
                    }
                } catch (err) {
                    setGeoError("Error fetching location.");
                }
                setLoading(false);
            },
            (error) => {
                setGeoError("Permission denied. Please enable location access.");
                setLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        fetchCurrentLocation();
    }, [fetchCurrentLocation]);

    const handlePincodeSubmit = async () => {
        if (!/^\d{6}$/.test(pincode)) {
            setPincodeError("Please enter a valid 6-digit pincode.");
            return;
        }

        setLoading(true);
        setPincodeError("");

        try {
            const response = await fetch(
                `https://api.postalpincode.in/pincode/${pincode}`
            );
            const data = await response.json();
            if (data[0]?.Status === "Success" && data[0]?.PostOffice?.[0]) {
                setLocation(`${data[0].PostOffice[0].District}, ${data[0].PostOffice[0].State}`);
                setOpen(false);
            } else {
                setPincodeError("Invalid Pincode. Try again.");
            }
        } catch (err) {
            setPincodeError("Error fetching location from pincode.");
        }
        setLoading(false);
    };

    return (
        <>
            <Button
                size="small"
                startIcon={loading ? (
                    <CircularProgress size={14} color="inherit" />
                ) : (
                    <LocationOn fontSize="small" />
                )}
                color="primary"
                variant="text"
                onClick={() => {
                    setOpen(true);
                    setGeoError("");
                    setPincodeError("");
                }}
                sx={{
                    fontSize: isSmallScreen ? "10px" : "11px",
                    padding: "4px 8px",
                    minWidth: 120,
                    maxWidth: 200,
                    overflow: 'hidden',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'underline'
                    },
                    "& .MuiButton-startIcon": {
                        marginRight: "4px",
                    },
                }}
            >
                <Box sx={{
                    display: 'inline-block',
                    width: '100%',
                    overflow: 'hidden',
                    verticalAlign: 'bottom',
                    textAlign: 'left'
                }}>
                    <Box sx={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        animation: location.length > 20 ? `${scrollText} 8s linear infinite` : 'none',
                        '@media (hover: hover)': {
                            '&:hover': {
                                animationPlayState: 'paused'
                            }
                        }
                    }}>
                        {loading ? "Detecting..." : location}
                    </Box>
                </Box>
            </Button>

            <Modal open={open} onClose={() => setOpen(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: isSmallScreen ? "95%" : 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: isSmallScreen ? 2 : 3,
                        borderRadius: 3,
                        outline: "none",
                        border: `1px solid ${theme.palette.divider}`
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                        <Typography variant="h6" sx={{
                            fontSize: isSmallScreen ? "1.1rem" : "1.25rem",
                            fontWeight: 600,
                            color: theme.palette.text.primary
                        }}>
                            Select Your Location
                        </Typography>
                        <IconButton onClick={() => setOpen(false)} size="small">
                            <Close sx={{ fontSize: isSmallScreen ? 20 : 24 }} />
                        </IconButton>
                    </Box>

                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={fetchCurrentLocation}
                        disabled={loading}
                        sx={{
                            py: 1.5,
                            fontSize: isSmallScreen ? "0.875rem" : "1rem",
                            fontWeight: 500,
                            borderColor: theme.palette.divider,
                            '&:hover': {
                                borderColor: theme.palette.primary.main,
                                backgroundColor: theme.palette.action.hover
                            }
                        }}
                    >
                        {loading ? (
                            <CircularProgress size={22} color="inherit" />
                        ) : (
                            "Use Current Location"
                        )}
                    </Button>

                    {geoError && (
                        <Typography color="error" variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                            {geoError}
                        </Typography>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                        <Box sx={{ flex: 1, height: '1px', bgcolor: 'divider' }} />
                        <Typography variant="body2" sx={{
                            mx: 2,
                            color: 'text.secondary',
                            fontWeight: 500
                        }}>OR</Typography>
                        <Box sx={{ flex: 1, height: '1px', bgcolor: 'divider' }} />
                    </Box>

                    <TextField
                        label="Enter 6-digit Pincode"
                        fullWidth
                        variant="outlined"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value.replace(/\D/g, '').slice(0, 6))}
                        error={Boolean(pincodeError)}
                        helperText={pincodeError}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: isSmallScreen ? "0.875rem" : "1rem",
                            },
                            mb: 2
                        }}
                        InputProps={{
                            endAdornment: loading && (
                                <CircularProgress size={20} color="inherit" />
                            )
                        }}
                    />

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            py: 1.5,
                            fontSize: isSmallScreen ? "0.875rem" : "1rem",
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark
                            }
                        }}
                        onClick={handlePincodeSubmit}
                        disabled={loading || pincode.length !== 6}
                    >
                        Submit Pincode
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default LocationSelector;