import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    typography: {
        fontFamily: 'Quicksand, serif'
    },
    palette: {
        mode: 'light',
        primary: {
            main: 'rgb(76, 167, 96)',
        },
        secondary: {
            main: '#2e7d32',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1400,  
            xl: 1536
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    maxWidth: '1400px',
                },
            },
        },
    },
});

export const darkTheme = createTheme({
    typography: {
        fontFamily: '"Roboto Condensed", sans-serif'
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#778899'
        },
        secondary: {
            main: '#232323',
        },
        background: {
            default: '#121212', 
            paper: '#1e1e1e',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1400,  
            xl: 1536
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    maxWidth: '1400px',
                },
            },
        },
    },
});