import { Person, Home, ShoppingCart, Info, ContactMail, Category } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from '@mui/material/styles';
import Drawer from "@mui/material/Drawer";
import ListItemButton from "@mui/material/ListItemButton";
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import { persistor } from '../../redux/store';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { TransitionGroup } from 'react-transition-group';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GroupsIcon from '@mui/icons-material/Groups';
import CallIcon from '@mui/icons-material/Call';
import fcmTokenService from '../../services/fcmTokenService';

const menuItems = [
    { text: "Home", path: "/", icon: <Home sx={{fontSize:"1rem"}} /> },
    { text: "Shop", path: "/shop", icon: <ShoppingCart sx={{fontSize:"1rem"}} /> },    
    { text: "Blogs", path: "/blog", icon: <NewspaperIcon sx={{fontSize:"1rem"}} /> },    
    { text: "About Us", path: "/about-us", icon: <GroupsIcon sx={{fontSize:"1rem"}}/> },
    { text: "Contact", path: "/contact-us", icon: <CallIcon sx={{fontSize:"1rem"}}/> },
];

export default function SideDrawer({ onDrawerClose, navDrawerOpen }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        const deviceToken = localStorage.getItem("deviceToken");
       
               if (deviceToken) {
                   try {
                       await fcmTokenService.removeFcmToken(deviceToken);
                       
                   } catch (error) {
                       console.error("Failed to remove device token:", error);
                   }
               }
       
               await dispatch(logout());
               persistor.purge();
               navigate('/account/login');
    };

    const { isAuthenticated } = useSelector(state => state.auth);

    return (
        <Drawer anchor="left" open={navDrawerOpen} onClose={onDrawerClose}>
            <Box
                sx={{
                    width: 250,
                    height: "100vh",
                    bgcolor: theme.palette.background.default,
                    display: "flex",
                    flexDirection: "column",
                }}
                role="presentation"
                onClick={onDrawerClose}
                onKeyDown={onDrawerClose}
            >
                <img src="/Vegingo-logo-text.png" width={150} style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }} />
                <List sx={{ height: "100%" }} disablePadding>
                    <TransitionGroup>
                        {menuItems.map(({ text, path, icon }, index) => (
                            <Slide key={text} in={navDrawerOpen} direction="right" timeout={{ enter: 300 + index * 100, exit: 0 }}>
                                <Box>
                                    <NavLink to={path} end style={({ isActive }) => ({
                                        color: isActive ? theme.palette.primary.main : theme.palette.text.primary
                                    })}>
                                        <ListItemButton>
                                            <ListItem disablePadding>
                                                {icon}
                                                <ListItemText primaryTypographyProps={{ style: { fontWeight: 500, marginLeft: 10 } }} primary={text} />
                                            </ListItem>
                                        </ListItemButton>
                                    </NavLink>
                                    <Divider />
                                </Box>
                            </Slide>
                        ))}
                        {isAuthenticated ? (
                            <>
                                <Slide key="profile" in={navDrawerOpen} direction="right" timeout={{ enter: 300 + menuItems.length * 100, exit: 0 }}>
                                    <Box>
                                        <NavLink to="/account/profile" end style={({ isActive }) => ({
                                            color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
                                        })}>
                                            <ListItemButton>
                                                <ListItem disablePadding>
                                                    <Person sx={{fontSize:"1rem"}} />
                                                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 500, marginLeft: 10, } }} primary="My Profile" />
                                                </ListItem>
                                            </ListItemButton>
                                        </NavLink>
                                        <Divider />
                                    </Box>
                                </Slide>
                                <Slide key="logout" in={navDrawerOpen} direction="right" timeout={{ enter: 300 + menuItems.length * 100, exit: 0 }}>
                                    <Box>
                                        <ListItemButton onClick={handleLogout}>
                                            <ListItem disablePadding>
                                                <LogoutOutlinedIcon sx={{ color: "red", fontSize:"1rem" }} />
                                                <ListItemText primaryTypographyProps={{ style: { fontWeight: 500, color: "red", marginLeft: 10 } }} primary="Logout" />
                                            </ListItem>
                                        </ListItemButton>
                                        <Divider />
                                    </Box>
                                </Slide>
                              
                            </>
                        )  :
                        (
                            <Slide key="login" in={navDrawerOpen} direction="right" timeout={{ enter: 300 + menuItems.length * 100, exit: 0 }}>
                            <Box>
                                <ListItemButton onClick={()=>navigate("/account/login")}>
                                    <ListItem disablePadding>
                                        <LoginOutlinedIcon sx={{ color: "green", fontSize:"1rem" }} />
                                        <ListItemText primaryTypographyProps={{ style: { fontWeight: 500, color: "green", marginLeft: 10 } }} primary="Login" />
                                    </ListItem>
                                </ListItemButton>
                                <Divider />
                            </Box>
                        </Slide>
                            
                        )}
                    </TransitionGroup>
                </List>
                <Box sx={{ bgcolor: theme.palette.primary.main, py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: "center", mt: 1, color: "white" }}>
                        <IconButton color="inherit"><TwitterIcon sx={{ fontSize: '1.2rem' }} /></IconButton>
                        <IconButton color="inherit"><FacebookIcon sx={{ fontSize: '1.2rem' }} /></IconButton>
                        <IconButton color="inherit"><PinterestIcon sx={{ fontSize: '1.2rem' }} /></IconButton>
                        <IconButton color="inherit"><InstagramIcon sx={{ fontSize: '1.2rem' }} /></IconButton>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
}
