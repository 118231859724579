import React from 'react';
import { useMediaQuery, IconButton, Chip, Stack } from '@mui/material';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import OrderDetails from './OrderDetails';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function OrderItem({ order, onViewDetails, onClose, showDetails }) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

    // Date formatting remains the same
    const dateString = order.address.created_at;
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true, hourCycle: 'h12' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;

    const itemsToShow = isSmallScreen ? 2 : 3;
    const remainingItems = order.order_item.length - itemsToShow;

    // Status color mapping
    const statusColors = {
        pending: 'warning',
        completed: 'success',
        processing: 'info',
        cancelled: 'error'
    };

    return (
        <Box
            sx={{
                borderRadius: 2,
                width: "100%",
                p: 2,
                mb: 2,
                backgroundColor: 'background.paper',
                boxShadow: 1,
                transition: 'box-shadow 0.3s ease',
                '&:hover': { boxShadow: 3 }
            }}
        >
            {showDetails ? (
                <OrderDetails order={order} onClose={onClose} dateTime={formattedDateTime} />
            ) : (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {/* Left Section */}
                    <Box sx={{ flex: 1, mr: 2 }}>
                        {/* Product Images */}
                        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                            {order.order_item.slice(0, itemsToShow).map(item => (
                                <Box key={item.id} sx={{
                                    width: 56,
                                    height: 56,
                                    borderRadius: 1.5,
                                    overflow: "hidden",
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    position: 'relative',
                                    '&:hover': { transform: 'scale(1.05)' },
                                    transition: 'transform 0.2s ease'
                                }}>
                                    <img
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        src={item.product.image}
                                        alt={item.product.name}
                                    />
                                </Box>
                            ))}
                            {remainingItems > 0 && (
                                <Chip
                                    label={`+${remainingItems}`}
                                    sx={{
                                        height: 56,
                                        borderRadius: 1.5,
                                        bgcolor: 'action.hover',
                                        fontSize: '0.875rem'
                                    }}
                                />
                            )}
                        </Stack>

                        {/* Order Info */}
                        <Stack spacing={0.5}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, letterSpacing: 0.2 }}>
                                ORDER #ORD{order.id}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Chip
                                    label={order.status}
                                    size="small"
                                    color={statusColors[order.status.toLowerCase()] || 'default'}
                                    sx={{ textTransform: 'uppercase', fontWeight: 600, letterSpacing: 0.5, fontSize: "0.65rem" }}
                                />
                                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {formattedDateTime}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>

                    {/* Right Section */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Typography variant="h6" sx={{
                            fontWeight: 700,
                            mb: 1,
                            color: 'primary.main',
                            fontSize: isSmallScreen ? '1.1rem' : '1.25rem'
                        }}>
                            ₹{order.grand_total}
                        </Typography>
                        <IconButton
                            onClick={onViewDetails}
                            sx={{
                                p: 1,
                                bgcolor: 'action.hover',
                                '&:hover': { bgcolor: 'action.selected' }
                            }}
                        >
                            <ArrowForwardIosIcon sx={{
                                fontSize: isSmallScreen ? '0.75rem' : '0.85rem',
                                color: 'text.secondary'
                            }} />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </Box>
    );
}