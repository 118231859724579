import React, { useState, useEffect } from 'react';
import { 
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  Chip,
  Button,
  Drawer,
  IconButton,
  useMediaQuery,
  Typography,
  Fab,
  Checkbox,
  ListItemText
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const categories = ['Vegetables', 'Seasonal Vegetables', 'Fruits', 'Groceries', 'Others']; 
const tagsOptions = ['indian', 'chinese', 'soup-veggies','stir-fry-veggies','salad-veggies','tropical-veggies','crisp-veggies']; 

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2)
  }
}));

const FiltersNew = ({ filters, onChange }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters, mobileOpen]);

  const handleMobileOpen = () => setMobileOpen(true);
  const handleMobileClose = () => setMobileOpen(false);

  const handleLocalChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleTagsChange = (event) => {
    const value = event.target.value;
    setLocalFilters(prev => ({
      ...prev,
      tags: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleReset = () => {
    setLocalFilters({ category: '', tags: [], sort: '' });
  };

  const handleApply = () => {
    onChange(localFilters);
    handleMobileClose();
  };

  // Desktop Filters
  const desktopFilters = (
    <Box sx={{
      position:"sticky",
      top:0,
      zIndex:100,
      display: 'flex',
      gap: 3,
      alignItems: 'flex-end',
      p: 2,
      backgroundColor: 'background.paper',
      borderRadius: 2,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
      mb: 4,
      flexWrap: 'wrap'
    }}>
      <StyledFormControl variant="outlined">
        <InputLabel>Category</InputLabel>
        <Select
          name="category"
          value={filters.category}
          onChange={(e) => onChange({ ...filters, category: e.target.value })}
          label="Category"
          sx={{ '& .MuiOutlinedInput-input': { py: 1.5 } }}
        >
          <MenuItem value="">All Categories</MenuItem>
          {categories.map((category) => (
            <MenuItem key={category} value={category} sx={{ py: 1 }}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <Box sx={{ flexGrow: 1, minWidth: 300 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {tagsOptions.map((tag) => (
            <Chip
              key={tag}
              label={tag.replace(/-/g, ' ')}
              clickable
              variant={filters.tags.includes(tag) ? 'filled' : 'outlined'}
              color="primary"
              onClick={() => {
                const newTags = filters.tags.includes(tag)
                  ? filters.tags.filter((t) => t !== tag)
                  : [...filters.tags, tag];
                onChange({ ...filters, tags: newTags });
              }}
              sx={{
                borderRadius: 1,
                transition: 'all 0.2s',
                '&.MuiChip-filled': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  boxShadow: 1
                },
                '&:hover': { transform: 'scale(1.05)' }
              }}
            />
          ))}
        </Box>
      </Box>

      <StyledFormControl>

        <RadioGroup
          row
          name="sort"
          value={filters.sort}
          onChange={(e) => onChange({ ...filters, sort: e.target.value })}
          sx={{ gap: 2 }}
        >
          <FormControlLabel
            value="low_to_high"
            control={<Radio size="small" color="primary" />}
            label="Price: Low to High"
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
          />
          <FormControlLabel
            value="high_to_low"
            control={<Radio size="small" color="primary" />}
            label="Price: High to Low"
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
          />
        </RadioGroup>
      </StyledFormControl>
    </Box>
  );

  // Mobile Filters
  const mobileFilters = (
    <>
      <Fab
        onClick={handleMobileOpen}
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 16,
          zIndex: 1200,
          display: { xs: 'flex', sm: 'none' },
          bgcolor: 'primary.main',
          color: 'white',
          '&:hover': {
            bgcolor: 'primary.dark',
            transform: 'scale(1.1)'
          },
          transition: 'all 0.3s ease'
        }}
      >
        <FilterListIcon />
      </Fab>
      
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleMobileClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: '90%',
            maxWidth: 350,
            p: 3,
            boxSizing: 'border-box'
          },
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3,
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 2
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>Filters</Typography>
          <IconButton onClick={handleMobileClose} sx={{ color: 'text.primary' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ overflow: 'auto', mb: 2 }}>
          <StyledFormControl fullWidth variant="outlined">
            <InputLabel>Category</InputLabel>
            <Select
              name="category"
              value={localFilters.category}
              onChange={handleLocalChange}
              label="Category"
              sx={{ mb: 3 }}
            >
              <MenuItem value="">All Categories</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category} value={category} sx={{ py: 1 }}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <StyledFormControl fullWidth variant="outlined">
            <InputLabel>Tags</InputLabel>
            <Select
              multiple
              name="tags"
              value={localFilters.tags}
              onChange={handleTagsChange}
              input={<OutlinedInput label="Tags" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip 
                      key={value} 
                      label={value.replace(/-/g, ' ')}
                      size="small" 
                      sx={{ 
                        borderRadius: 1,
                        bgcolor: 'primary.light',
                        color: 'primary.contrastText'
                      }}
                    />
                  ))}
                </Box>
              )}
              sx={{ mb: 3 }}
            >
              {tagsOptions.map((tag) => (
                <MenuItem key={tag} value={tag} sx={{ py: 0.5 }}>
                  <Checkbox
                    size="small"
                    checked={localFilters.tags.includes(tag)}
                    sx={{ py: 0 }}
                  />
                  <ListItemText 
                    primary={tag.replace(/-/g, ' ')} 
                    sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
                  />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <StyledFormControl fullWidth>
            {/* <InputLabel sx={{ mb: 1.5, fontSize: '0.875rem' }}>
              Sort By
            </InputLabel> */}
            <RadioGroup
              name="sort"
              value={localFilters.sort}
              onChange={handleLocalChange}
              sx={{ gap: 1 }}
            >
              <FormControlLabel
                value="low_to_high"
                control={<Radio size="small" color="primary" />}
                label="Price: Low to High"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
              />
              <FormControlLabel
                value="high_to_low"
                control={<Radio size="small" color="primary" />}
                label="Price: High to Low"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
              />
            </RadioGroup>
          </StyledFormControl>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          mt: 'auto',
          pt: 2,
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper'
        }}>
          <Button
            variant="outlined"
            onClick={handleReset}
            fullWidth
            sx={{ 
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '0.875rem'
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={handleApply}
            fullWidth
            sx={{ 
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '0.875rem',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                bgcolor: 'primary.dark'
              }
            }}
          >
            Apply Filters
          </Button>
        </Box>
      </Drawer>
    </>
  );

  return isMobile ? mobileFilters : desktopFilters;
};

export default FiltersNew;