import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Divider,
  ListItemButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedAddress } from "../../redux/slices/orderSlice";
import AddressFormFields from "../account/AddressFormFields";

const AddressModal = ({ selectedAddressId, onChange, open, onClose }) => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const { addresses } = useSelector((state) => state.address);

  const handleClose = () => {
    onClose(false);
    setShowForm(false);
  };

  const handleSelectAddress = (addressId) => {
    dispatch(setSelectedAddress(addressId));
    onChange(addressId);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="address-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 350, md: 400 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          outline: "none",
        }}
      >
        {/* Header */}
        <Box sx={{ position: "relative", mb: 2 }}>
          <Typography variant="h6" fontWeight="bold" sx={{ textAlign: "center" }}>
            {showForm ? "Add New Address" : "Select an Address"}
          </Typography>
          <IconButton
            onClick={() => onClose(false)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "grey.600",
              "&:hover": { color: "grey.800" },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />

        {showForm ? (
          <AddressFormFields onClose={() => setShowForm(false)} />
        ) : (
          <Box>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mb: 2, textTransform: "none" }}
              onClick={() => setShowForm(true)}
            >
              Add Address
            </Button>
            {addresses && addresses.length > 0 ? (
              <>
                <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
                  SAVED ADDRESSES
                </Typography>
                <Typography
                  color="success.main"
                  sx={{ fontSize: "0.85rem", mb: 1 }}
                >
                  Nearby address(es)
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <List sx={{ maxHeight: 250, overflowY: "auto" }} disablePadding>
                  {addresses.map((address) => (
                    <ListItem key={address.id} disablePadding>
                      <ListItemButton
                        onClick={() => handleSelectAddress(address.id)}
                        sx={{
                          backgroundColor:
                            selectedAddressId === address.id
                              ? "action.selected"
                              : "transparent",
                          borderRadius: 1,
                          mb: 1,
                          "&:hover": {
                            backgroundColor:
                              selectedAddressId === address.id
                                ? "action.hover"
                                : "grey.100",
                          },
                        }}
                      >
                        <LocationOnIcon sx={{ mr: 1, color: "primary.main" }} />
                        <ListItemText
                          primary={
                            <Typography variant="body2" fontWeight="bold">
                              {address.buildingNameArea}
                              <br />
                              <Typography
                                variant="subtitle2"
                                component="span"
                                color="text.secondary"
                              >
                                near {address.landmark}
                              </Typography>
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography variant="subtitle2" display="block">
                                {address.city}, {address.pincode}, {address.state}
                              </Typography>
                              <Typography variant="subtitle2" display="block">
                                {address.user_name} <br />
                                Phone: {address.phone_number}
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <Typography sx={{ my: 2, textAlign: "center" }}>
                No saved address
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddressModal;
