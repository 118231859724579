import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, Divider } from '@mui/material';
import LeafIcon from '@mui/icons-material/LocalFlorist'; 

export default function SectionTitle({ t1, t2, subheading }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Container maxWidth="lg" sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: "center", mb: 1 }}>
                <Typography color="primary" variant={isSmallScreen? "body2" : "h6"} sx={{fontWeight:"bold"}}>
                    {t2}
                </Typography>
                <Typography variant={isSmallScreen?"h5":"h4"} sx={{fontWeight:"bold"}}  >
                    {t1}
                </Typography>

                <Divider
                    flexItem
                    sx={{
                        "&::before, &::after": {
                            borderWidth: 1,
                            borderColor: theme.palette.primary.main
                        },
                    }}
                >
                    <LeafIcon
                        color="primary"
                        sx={{
                            fontSize: 28,
                            color: theme.palette.primary.main,
                        }}
                    />
                </Divider>
            </Box>

        </Container>
    );
}
