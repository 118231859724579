import { ShoppingCartOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/slices/cartSlice";

const AddToCartButton = ({ product, size = "medium" }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleAddToCart = async () => {
    setLoading(true);
    try {
      await dispatch(addToCart(product)).unwrap();
      toast.success(`${product.name} added to cart`);
    } catch (error) {
      toast.error("Failed to add to cart");
      console.error("Error adding to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  // Define styles based on size
  const sizeStyles = {
    small: {
      buttonFontSize: "0.75rem",
      buttonPadding: "2px 4px",
      iconSize: 20,
      progressSize: 18,
    },
    medium: {
      buttonFontSize: "0.875rem",
      buttonPadding: "4px 16px",
      iconSize: 22,
      progressSize: 20,
    },
    large: {
      buttonFontSize: "0.875rem",
      buttonPadding: "8px 22px",
      iconSize: 25,
      progressSize: 22,
    },
  };

  const styles = sizeStyles[size];

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      startIcon={
        loading ? (
          <CircularProgress size={styles.progressSize} sx={{ color: "white" }} />
        ) : (
          <ShoppingCartOutlined fontSize="small" sx={{ fontSize: styles.iconSize }} />
        )
      }
      onClick={handleAddToCart}
      sx={{
        color: "white",
        textTransform: "none",
        fontSize: styles.buttonFontSize,
        padding: styles.buttonPadding,
        minWidth: "fit-content",
      }}
    >
      {!loading && "Add to cart"}
    </Button>
  );
};


export default AddToCartButton;