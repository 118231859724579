import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({
    title = 'VeginGo - Fresh Vegetables and Fruits Online',
    desc = 'Order fresh vegetables and fruits online in Lucknow with VeginGo. Quick, reliable delivery of organic produce.',
    image = '%PUBLIC_URL%/logo192.png',
    url = 'https://www.vegingo.com',
    keywords = 'vegetables, fruits, buy vegetables online, Lucknow vegetables delivery, fresh fruits, organic produce',
    googleVerification = 'sNrtQd7_YWK061Yg0wnKCRBk5nLERhlAPPnjuc9mQzg',
    gtagId = 'G-62GD76QCKE',
    canonicalUrl = 'https://www.vegingo.com',
}) => {
    return (
        <Helmet>
            {/* Basic Meta Tags */}
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={desc} />
            <meta name="keywords" content={keywords} />
            <meta name="theme-color" content="#000000" />
            <meta
                name="google-site-verification"
                content={googleVerification}
            />
            <meta name="robots" content="index,follow" />

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={desc} />
            <meta name="twitter:image" content={image} />

            {/* Canonical URL */}
            <link rel="canonical" href={canonicalUrl} />

            {/* Google Analytics Script */}
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`}
            ></script>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', '${gtagId}');
                `}
            </script>
        </Helmet>
    );
};

export default Seo;
