import React from "react";
import { Box, Grid, Typography, Button, Container, keyframes, useMediaQuery } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { Link } from "react-router-dom";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import DownloadIcon from '@mui/icons-material/Download';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

export default function DownloadApp() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  
  return (
    <Box
      sx={{
        backgroundColor: '#f9fbfa',
        py: { xs: 6, sm: 10 },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Grid container alignItems="center" spacing={6} direction={{ xs: "column-reverse", md: "row" }}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Typography color={"primary.dark"} variant={isSmallScreen ? "h5" : "h4"} sx={{
              fontWeight: 700,
              mb: 3,
              lineHeight: 1.2,
              letterSpacing: '-0.5px'
            }}>
              Elevate Your Experience with Our App
            </Typography>
            
            <Typography variant={isSmallScreen ? "body1" : "h6"} sx={{ 
              mb: 4,
              color: 'text.secondary',
              fontWeight: 400,
              fontSize: isSmallScreen ? '1rem' : '1.2rem',
              lineHeight: 1.6
            }}>
              Access exclusive features, personalized recommendations, and seamless shopping directly from your mobile device.
            </Typography>

            {/* App Store Buttons */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6}>
                <Button 
                  fullWidth 
                  variant="contained"
                  disabled
                  sx={{
                    py: 1.5,
                    borderRadius: 2,
                    bgcolor: 'text.primary',
                    color: '#fff',
                    '&:hover': { bgcolor: '#424242' },
                    transition: 'all 0.2s ease',
                    boxShadow: 1
                  }}
                >
                  <AppleIcon sx={{ mr: 1.5, fontSize: '1.5rem' }} />
                  <Box textAlign="left">
                    <Typography variant="caption" display="block">Download on the</Typography>
                    <Typography variant="subtitle1">App Store</Typography>
                  </Box>
                </Button>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Button 
                  fullWidth 
                  variant="contained"
                  disabled
                  sx={{
                    py: 1.5,
                    borderRadius: 2,
                    bgcolor: '#4285f4',
                    color: '#fff',
                    '&:hover': { bgcolor: '#357abd' },
                    transition: 'all 0.2s ease',
                    boxShadow: 1
                  }}
                >
                  <AndroidIcon sx={{ mr: 1.5, fontSize: '1.5rem' }} />
                  <Box textAlign="left">
                    <Typography variant="caption" display="block">Get it on</Typography>
                    <Typography variant="subtitle1">Google Play</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Link to="/install" style={{ textDecoration: 'none' }}>
                  <Button 
                    fullWidth 
                    variant="outlined"
                    sx={{
                      py: 1.5,
                      borderRadius: 2,
                      borderColor: '#2e7d32',
                      color: '#2e7d32',
                      '&:hover': {
                        backgroundColor: '#2e7d3210',
                        borderColor: '#1b5e20'
                      },
                      transition: 'all 0.2s ease'
                    }}
                  >
                    <DownloadIcon sx={{ mr: 1.5 }} />
                    Download APK Directly
                  </Button>
                </Link>
              </Grid>
            </Grid>

            {/* QR Code */}
            <Box sx={{ 
              textAlign: 'center',
              mt: 4,
              '& .qr-code': {
                border: '8px solid #fff',
                borderRadius: '16px',
                boxShadow: 2
              }
            }}>
              <QRCodeSVG 
                value="/install" 
                size={isSmallScreen ? 120 : 160}
                bgColor="#ffffff"
                fgColor="#2e7d32"
                className="qr-code"
              />
              <Typography variant="caption" display="block" sx={{ mt: 2, color: 'text.secondary' }}>
                Scan to download directly to your device
              </Typography>
            </Box>
          </Grid>

          {/* Right Section (Phone Image) */}
          <Grid item xs={12} md={6} sx={{ 
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Box sx={{
              width: { xs: 220, sm: 380 },
              height: { xs: 320, sm: 580 },
              backgroundImage: 'url(/mobile-app.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              position: 'relative',
              zIndex: 1,
              animation: `${floatAnimation} 4s ease-in-out infinite`,
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -20,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '50%',
                height: 12,
                background: 'rgba(0,0,0,0.05)',
                borderRadius: '50%',
                filter: 'blur(6px)'
              }
            }} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}