import { 
    Box, 
    Typography, 
    Grid, 
    Card, 
    CardContent, 
    Container, 
    keyframes,
    useTheme 
} from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VerifiedIcon from '@mui/icons-material/Verified';
import Seo from '../components/Seo/Seo';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

const gradientBackground = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

export default function AboutPage() {
    const theme = useTheme();

    return (
        <>
            {/* Hero Section */}
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <Seo title="About - Vegingo" />
                <Box
                    sx={{
                        position: 'relative',
                        height: { xs: '250px', sm: '300px', md: '500px' },
                        background: `
                            linear-gradient(135deg, rgba(11,61,32,0.9) 20%, rgba(27,67,50,0.9) 80%),
                            url('https://res.cloudinary.com/dbi8wjrcl/image/upload/w_1920/q_auto:low/v1737294412/vegingo/top-view-assortment-vegetables_wcppuz.jpg')
                        `,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        px: 2,
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, transparent 100%)',
                            zIndex: 1,
                        },
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            color: 'white',
                            zIndex: 2,
                            fontWeight: 800,
                            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                            maxWidth: 800,
                            animation: `${floatAnimation} 3s ease-in-out infinite`,
                        }}
                    >
                        Freshness Revolution Starts Here
                    </Typography>
                </Box>

                {/* Info Cards Section */}
                <Container maxWidth="lg" sx={{ mt: { xs: 2, md: -10 }, position: 'relative', zIndex: 2 }}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        {[
                            {
                                title: 'Our Vision',
                                icon: <ShoppingBasketIcon sx={{ fontSize: { xs: 30, md: 40 } }} />,
                                desc: 'Make fresh, healthy produce accessible to everyone at affordable prices',
                                color: 'linear-gradient(135deg, #6fbf73 0%, #1e824c 100%)'
                            },
                            {
                                title: 'Our Mission',
                                icon: <VerifiedIcon sx={{ fontSize: { xs: 30, md: 40 } }} />,
                                desc: 'Deliver farm-fresh produce while supporting local farmers',
                                color: 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)'
                            },
                            {
                                title: 'Our Promise',
                                icon: <LocalShippingIcon sx={{ fontSize: { xs: 30, md: 40 } }} />,
                                desc: 'Timely delivery & exceptional service every time',
                                color: 'linear-gradient(135deg, #2196F3 0%, #1976D2 100%)'
                            },
                        ].map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        textAlign: 'center',
                                        p: { xs: 2, md: 3 },
                                        background: item.color,
                                        color: 'white',
                                        borderRadius: 3,
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: { md: 'translateY(-10px)', xs: 'none' },
                                            boxShadow: { md: '0 16px 32px rgba(0,0,0,0.2)' }
                                        }
                                    }}
                                >
                                    <CardContent>
                                        <Box
                                            sx={{
                                                width: { xs: 60, md: 80 },
                                                height: { xs: 60, md: 80 },
                                                bgcolor: 'rgba(255,255,255,0.2)',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mx: 'auto',
                                                mb: { xs: 2, md: 3 }
                                            }}
                                        >
                                            {item.icon}
                                        </Box>
                                        <Typography
                                            variant="h5"
                                            fontWeight={700}
                                            sx={{ 
                                                mb: 2,
                                                fontSize: { xs: '1.25rem', md: '1.5rem' }
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
                                            {item.desc}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            {/* Why Choose Section */}
            <Container sx={{ py: { xs: 6, md: 10 } }}>
                <Grid container spacing={{ xs: 4, md: 6 }} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                p: { md: 4 },
                                position: 'relative',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: -20,
                                    left: -20,
                                    right: 20,
                                    bottom: 20,
                                    background: theme.palette.primary.main,
                                    zIndex: -1,
                                    borderRadius: 3,
                                    display: { xs: 'none', md: 'block' }
                                }
                            }}
                        >
                            <Box
                                component="img"
                                src="https://res.cloudinary.com/dbi8wjrcl/image/upload/v1739640562/vegingo/indian_farmer.jpg"
                                alt="Local Farmer"
                                sx={{
                                    width: '100%',
                                    borderRadius: 3,
                                    boxShadow: '0 16px 32px rgba(0,0,0,0.2)',
                                    transform: { md: 'rotate(3deg)', xs: 'none' },
                                    transition: 'transform 0.3s ease',
                                    '&:hover': {
                                        transform: { md: 'rotate(0deg)' }
                                    }
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" fontWeight={800} sx={{ 
                            mb: 3,
                            fontSize: { xs: '2rem', md: '3rem' }
                        }}>
                            Why Vegi<span style={{ color: theme.palette.primary.main }}>n</span>Go?
                        </Typography>
                        
                        {[
                            'Directly sourced from local farms',
                            '100% organic & chemical-free produce',
                            'Same-day delivery guarantee',
                            'Sustainable farming practices'
                        ].map((text, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Box
                                    sx={{
                                        width: { xs: 28, md: 32 },
                                        height: { xs: 28, md: 32 },
                                        bgcolor: theme.palette.primary.main,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 2,
                                        flexShrink: 0
                                    }}
                                >
                                    <VerifiedIcon sx={{ 
                                        color: 'white', 
                                        fontSize: { xs: 14, md: 16 } 
                                    }} />
                                </Box>
                                <Typography variant="body1" sx={{ 
                                    fontWeight: 600,
                                    fontSize: { xs: '1rem', md: '1.25rem' }
                                }}>
                                    {text}
                                </Typography>
                            </Box>
                        ))}

                        <Typography variant="body1" sx={{ 
                            mt: 3, 
                            fontSize: { xs: '1rem', md: '1.1rem' } 
                        }}>
                            We bridge the gap between farmers and consumers, ensuring fair prices 
                            for growers and fresh produce for you. Our eco-friendly packaging and 
                            electric delivery vehicles make us the sustainable choice.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}