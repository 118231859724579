import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import { baseurl } from '../../apiCollection';
import authService from '../../services/authService';

export default function MyProfile() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { user } = useSelector((state) => state.auth);
    const address = useSelector((state) => state.address);
    const defaultAddress = address?.addresses?.find(
        (address) => address.is_default === 1
    );

    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState({
        name: user?.name || '',
        email: user?.email || '',
        phone_number: user?.phone_number || '',
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({ ...prevData, [name]: value }));
    };

    const getAuthToken = () => {
        const user = authService.getCurrentUser();
        const token = user ? user.token : null;
        
        return token;
    };

    const handleSave = async () => {
        const token = getAuthToken();

        const payload = {
            ...editData,
        };

        // If phone number is not changed, remove it from the payload
        if (editData.phone_number === user.phone_number) {
            delete payload.phone_number;
        }

        try {
            const id = user.id;
            const response = await fetch(
                `${baseurl}/auth/client/update_user/${id}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            );

            const result = await response.json();

            if (response.ok) {
                toast.success('Profile updated successfully!');
                handleClose();
            } else {
                toast.error(result.message || 'Failed to update profile.');
            }
        } catch (error) {
            toast.error('Error updating profile.');
        }
    };

    if (!user) return null;

    return (
        <Box>
            {/* Profile Section */}
            <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar
                            sx={{ width: 80, height: 80 }}
                            alt="profile"
                            src={`https://api.dicebear.com/9.x/initials/svg?seed=${user.name}`}
                        />
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                {user.name}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                {user.email}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                            borderRadius: '1.2rem',
                            px: 2,
                            color: 'white',
                            mt: isSmallScreen ? 2 : 0,
                        }}
                        startIcon={<EditIcon />}
                        onClick={handleOpen}
                    >
                        Edit
                    </Button>
                </Box>
            </Paper>

            {/* Personal Information Section */}
            <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                    Personal Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                            First Name
                        </Typography>
                        <Typography variant="body1">
                            {user.name.split(' ')[0]}
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Last Name
                        </Typography>
                        <Typography variant="body1">
                            {user.name.split(' ')[1] || 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Email Address
                        </Typography>
                        <Typography variant="body1">{user.email || 'N/A'}</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Phone Number
                        </Typography>
                        <Typography variant="body1">
                            {user.phone_number || 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>

            {/* Address Section */}
            <Paper elevation={2} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Address
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {defaultAddress ? (
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                Area/Sector
                            </Typography>
                            <Typography variant="body1">
                                {defaultAddress.building_name_area}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                City
                            </Typography>
                            <Typography variant="body1">
                                {defaultAddress.city}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                Postal Code
                            </Typography>
                            <Typography variant="body1">
                                {defaultAddress.pincode}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                Country
                            </Typography>
                            <Typography variant="body1">
                                {defaultAddress.country}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant="body1">
                        No default address found.
                    </Typography>
                )}
            </Paper>

            {/* Edit Dialog */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Profile</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                            size='small'
                                fullWidth
                                label="Name"
                                name="name"
                                value={editData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            size='small'
                                fullWidth
                                label="Email"
                                name="email"
                                value={editData.email}
                                readOnly
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            size='small'
                                fullWidth
                                label="Phone Number"
                                name="phone_number"
                                value={editData.phone_number}
                                onChange={handleChange}
                                type="tel"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        sx={{color:"white"}}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
