import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell, TableRow, IconButton, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import QuantitySelector from '../product/QuantitySelector';
import { removeFromCart } from '../../redux/slices/cartSlice';
import toast from 'react-hot-toast';

export default function CartTableItem({ item }) {
    const dispatch = useDispatch();

    const handleRemove = async () => {
        try {
            await dispatch(removeFromCart(item.product.id)).unwrap();
            toast.success(`${item.product.name} removed from cart`);
        } catch (error) {
            toast.error("Failed to remove from cart");
            console.error("Error removing from cart:", error);
        }
    };

    return (
        <>
            <TableRow>
                {/* Product Information */}
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <img
                            src={item.product.image}
                            alt={item.product.name}
                            width={50}
                            height={50}
                            style={{ borderRadius: 4, objectFit: "cover" }}
                        />
                        <Box>
                            <Typography variant="body1" fontWeight={600}>
                                {item.product.name}
                            </Typography>
                            {/* <Typography variant="body2" fontWeight={600}>
                               {item.quantity} {item.product_unit}
                            </Typography> */}
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>

                                <Typography variant="body1" fontWeight={"bold"} color={"green"}>₹{item.product.discounted_price}</Typography>

                                <Typography
                                    variant='body2'
                                    sx={{
                                        textDecoration: 'line-through',
                                        color: 'gray',
                                    }}
                                >
                                    ₹{item.product.price}
                                </Typography>
                            </Box>

                        </Box>
                    </Box>
                </TableCell>

                {/* Quantity Selector */}
                <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <QuantitySelector cartItem={item} size='small' />
                    </Box>
                </TableCell>

                {/* Total Price */}
                <TableCell align="center">
                    <Typography variant="body2">
                        ₹{item.product.discounted_price * item.quantity}
                    </Typography>
                </TableCell>

                {/* Remove Button */}
                <TableCell align="center">
                    <IconButton onClick={handleRemove} aria-label="delete">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
}
