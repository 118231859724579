// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import authReducer, { logout } from './slices/authSlice';
import addressReducer from './slices/addressSlice';
import orderReducer from './slices/orderSlice'
import productReducer from './slices/productSlice'
import cartReducer from './slices/cartSlice'

const appReducer = combineReducers({
  auth: authReducer,
  address: addressReducer,
  order: orderReducer,
  products:productReducer,
  cart:cartReducer
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
