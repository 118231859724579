import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import Seo from '../components/Seo/Seo';

export default function PrivacyPolicyPage() {
    return (
        <Container
            maxWidth="md"
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
            <Seo title="Privacy Policy - Vegingo" />
            <Box>
                <Typography variant="h4" gutterBottom align="center">
                    Privacy Policy
                </Typography>
                <Divider style={{ marginBottom: '2rem' }} />
                <Typography variant="h6" paragraph>
                    <strong>Last Updated:</strong> 07/11/2024
                </Typography>
                <Typography variant="body1" paragraph>
                    Welcome to VeginGo! Your privacy is important to us. This
                    Privacy Policy explains how we collect, use, disclose, and
                    safeguard your information when you visit our website, , or
                    use our services. Please read this policy carefully to
                    understand our practices regarding your personal data.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    1. Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    We collect several types of information to provide and
                    improve our services, including:
                </Typography>
                <Typography variant="h6" gutterBottom>
                    a. Personal Information
                </Typography>
                <Typography variant="body1" paragraph>
                    When you register on our website, place an order, subscribe
                    to our newsletter, or contact us, we may collect personal
                    information, such as:
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Shipping address</li>
                        <li>
                            Payment information (processed by secure third-party
                            providers)
                        </li>
                    </ul>
                </Typography>
                <Typography variant="h6" gutterBottom>
                    b. Automatically Collected Information
                </Typography>
                <Typography variant="body1" paragraph>
                    When you access our website, certain information is
                    collected automatically, including:
                    <ul>
                        <li>Browser type and version</li>
                        <li>Pages visited and time spent</li>
                        <li>Cookies and other tracking technologies</li>
                    </ul>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We use your personal information to:
                    <ul>
                        <li>Process orders and payments</li>
                        <li>Provide customer support</li>
                        <li>Send you promotional emails (if opted-in)</li>
                        <li>Improve our website and services</li>
                        <li>Comply with legal obligations</li>
                    </ul>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    3. How We Protect Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We implement appropriate technical and organizational
                    measures to protect your personal information. These
                    measures include encryption, secure servers, and firewalls.
                    However, no method of transmission over the internet or
                    electronic storage is completely secure, so we cannot
                    guarantee its absolute security.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    4. Sharing Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not sell or rent your personal information to third
                    parties. However, we may share your information with trusted
                    third-party service providers who assist in operating our
                    website and fulfilling orders, such as payment processors
                    and delivery companies.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    5. Your Rights
                </Typography>
                <Typography variant="body1" paragraph>
                    You have the right to:
                    <ul>
                        <li>
                            Access, correct, or delete your personal information
                        </li>
                        <li>Opt out of promotional communications</li>
                        <li>Request the transfer of your data</li>
                    </ul>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Cookies and Tracking Technologies
                </Typography>
                <Typography variant="body1" paragraph>
                    We use cookies and similar technologies to enhance your
                    experience on our website. You can control the use of
                    cookies through your browser settings. However, disabling
                    cookies may affect the functionality of the website.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to update this Privacy Policy at any
                    time. Any changes will be posted on this page with an
                    updated date. Please review this policy periodically for any
                    updates.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about this Privacy
                    Policy or our data practices, please contact us at
                    vegingo.team@gmail.com or 8765126842.
                </Typography>
            </Box>
        </Container>
    );
}
