import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { googleLogin } from '../../redux/slices/authSlice';
import Loading from '../core/Loading';
import fcmTokenService from '../../services/fcmTokenService';



const GoogleCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);

      if (params.has("code")) {
        const code = params.get("code");
        

        try {
          const result = await dispatch(googleLogin(code)).unwrap();
          
          const deviceToken = localStorage.getItem("deviceToken");
          if (deviceToken) {
            try {
              await fcmTokenService.saveFcmToken(deviceToken);
              
            } catch (fcmError) {
              console.error("FCM token registration failed:", fcmError);
            }
          } else {
            console.warn("Device token not available!");
          }

          navigate("/");
        } catch (error) {
          console.error("Google login failed:", error);
        }
      }
    };


    handleCallback();
  }, [location, navigate]);

  return <Loading />;
};

export default GoogleCallback;

