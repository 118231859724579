import React from 'react'
import Box from "@mui/material/Box"
import { useSelector } from 'react-redux';
import CartListItem from './CartListItem';
import { Divider } from '@mui/material';


export default function CartList() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
   
     const { guestCart, serverCart} = useSelector((state) => state.cart);

     const cartItems = isAuthenticated ? serverCart : guestCart;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {
                cartItems.map((item) => (
                    <React.Fragment key={item.product.id} >
                    <CartListItem item={item} />
                    <Divider flexItem/>
                    </React.Fragment>


                ))
            }

        </Box>
    )
}
