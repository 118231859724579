import React, { useState } from 'react';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Person from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import CartDrawer from '../cart/cart-drawer/CartDrawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Badge from "@mui/material/Badge";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link, NavLink } from 'react-router-dom';
import SideDrawer from './SideDrawer';
import { useTranslation } from 'react-i18next';
import SearchBar from "./SearchBar";
import LocationSelector from './LocationSelector';
import { Grid, Slide, Fade } from '@mui/material';

const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'Shop', path: '/shop' },
    { text: "About Us", path: '/about-us' },
    { text: "Blog", path: '/blog' },
    { text: 'Contact Us', path: '/contact-us' },
];

export default function MenuBar() {
    const theme = useTheme();
    const { t } = useTranslation('navbar');
    const [navDrawerOpen, setNavDrawerOpen] = useState(false);
    const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.auth);
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { guestCart, serverCart } = useSelector((state) => state.cart);
    const cartItems = isAuthenticated ? serverCart : guestCart;

    const toggleNavDrawer = () => setNavDrawerOpen(prev => !prev);
    const toggleCartDrawer = () => setCartDrawerOpen(prev => !prev);
    const toggleSearch = () => setShowSearch(prev => !prev);

    return (
        <Box width="100%" sx={{
            bgcolor: 'background.paper',
            position: 'sticky',
            top: 0,
            zIndex: 1100
        }}>
            <Container sx={{ py: 1 }} maxWidth="xl">
                {/* Mobile Search Bar */}
                {!isMediumScreen ? null : (
                    <Slide direction="down" in={showSearch} mountOnEnter unmountOnExit>
                        <Box sx={{ mb: 2 }}>
                            <SearchBar onClose={() => setShowSearch(false)} />
                        </Box>
                    </Slide>
                )}

                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    {/* Logo & Location */}
                    <Grid item xs={8} md={4}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: { xs: 1, md: 4 },
                            width: '100%'
                        }}>
                            <Link to="/">
                                <img
                                    src='/Vegingo-logo-text.png'
                                    alt="Vegingo Logo"
                                    style={{
                                        width: 120,
                                        transition: 'transform 0.3s ease',
                                        ':hover': { transform: 'scale(1.05)' }
                                    }}
                                />
                            </Link>
                            <LocationSelector />
                        </Box>
                    </Grid>

                    {/* Desktop Menu */}
                    {!isMediumScreen && (
                        <Grid item md={4}>
                            <Box sx={{
                                display: 'flex',
                                gap: '2rem',
                                justifyContent: 'center',
                                position: 'relative'
                            }}>

                                {menuItems.map(({ text, path }) => (
                                    <NavLink
                                        key={text}
                                        to={path}
                                        end
                                        style={{ textDecoration: 'none', position: 'relative' }}
                                    >
                                        {({ isActive }) => (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 600,
                                                    color: isActive ? 'primary.main' : 'text.primary',
                                                    transition: 'all 0.2s ease',
                                                    '&:hover': {
                                                        color: 'primary.main',
                                                        transform: 'translateY(-2px)'
                                                    },
                                                    '&::after': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        bottom: -4,
                                                        left: 0,
                                                        width: isActive ? '100%' : '0',
                                                        height: 2,
                                                        bgcolor: 'primary.main',
                                                        transition: 'width 0.3s ease'
                                                    },
                                                    '&:hover::after': {
                                                        width: '100%'
                                                    }
                                                }}
                                            >
                                                {t(text)}
                                            </Typography>
                                        )}
                                    </NavLink>
                                ))}
                            </Box>
                        </Grid>
                    )}

                    {/* Right Section */}
                    <Grid item xs={4} md={4}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: { xs: 1, md: 2 }
                        }}>
                            {!isMediumScreen && <SearchBar />}

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                {isMediumScreen && (
                                    <IconButton onClick={toggleSearch}>
                                        <SearchIcon sx={{ color: 'text.primary' }} />
                                    </IconButton>
                                )}

                                {isMediumScreen ? (
                                    <IconButton onClick={toggleNavDrawer}>
                                        <MenuIcon sx={{ color: 'text.primary' }} />
                                    </IconButton>
                                ) : (
                                    <>
                                        <NavLink to={isAuthenticated ? "/account" : "/account/login"}>
                                            <IconButton sx={{
                                                '&:hover': {
                                                    bgcolor: 'action.hover',
                                                    transform: 'scale(1.1)'
                                                },
                                                transition: 'all 0.2s ease'
                                            }}>
                                                <Person sx={{ color: 'text.primary' }} />
                                            </IconButton>
                                        </NavLink>

                                        <IconButton
                                            onClick={toggleCartDrawer}
                                            sx={{
                                                '&:hover': {
                                                    bgcolor: 'action.hover',
                                                    transform: 'scale(1.1)'
                                                },
                                                transition: 'all 0.2s ease'
                                            }}
                                        >
                                            <Badge
                                                badgeContent={cartItems.length}
                                                color="primary"
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        right: 4,
                                                        top: 4,
                                                        fontWeight: 600
                                                    }
                                                }}
                                            >
                                                <ShoppingCart sx={{ color: 'text.primary' }} />
                                            </Badge>
                                        </IconButton>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <SideDrawer
                onDrawerClose={toggleNavDrawer}
                menuItems={menuItems}
                navDrawerOpen={navDrawerOpen}
            />

            <CartDrawer
                open={cartDrawerOpen}
                onClose={() => setCartDrawerOpen(false)}
            />
        </Box>
    );
}