import axios from "axios";
import authService from "./authService";
import api from "../apiCollection";

const getAuthToken = () => {
  const user = authService.getCurrentUser();
  const token = user ? user.token : null;
  
  return token;
};

const getLatestOrder = async () => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${api.order.latestOrder}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error("Error getting recent orders:", error);
    throw error;
  }
};

const getOrderHistory = async () => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${api.order.orderHistory}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error("Error getting order history:", error);
    throw error;
  }
};

const createOrderFromCart = async (
  addressId,
  orderNote,
  coupon,
  deliveryCharge,
  provider
) => {
  
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("address_id", addressId);
    formData.append("notes", orderNote);
    formData.append("coupon_code", coupon || "");
    formData.append("shipping_charge", deliveryCharge);
    formData.append("provider", provider);
    const response = await axios.post(
      `${api.order.createOrderFromCart}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    return response.data;
  } catch (error) {
    console.error("Error creating order from cart:", error);
    throw error;
  }
};

const createOrderFromProduct = async (productId, quantity) => {
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("product_id", productId);
    formData.append("quantity", quantity);

    const response = await axios.post(
      `${api.order.createOrderFromProduct}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    
    return response.data;
  } catch (error) {
    console.error("Error creating order from product:", error);
    throw error;
  }
};

const downloadInvoice = async (orderId) => {
  
  try {
    // Get the auth token (replace with your token fetching logic)
    const token = getAuthToken();

    // Perform the GET request to the API
    const response = await axios.get(
      `${api.order.downloadInvoice}/${orderId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Important: Treat the response as a binary file
      }
    );

    // Create a Blob from the binary response
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a link to download the file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `INV_${orderId}_${Date.now()}.pdf`; // Set the filename
    link.click();

    // Clean up the object URL to free memory
    window.URL.revokeObjectURL(link.href);

    
  } catch (error) {
    console.error("Error downloading invoice:", error);
    throw error; // Throw error for Redux or other handling
  }
};

export default {
  getLatestOrder,
  getOrderHistory,
  createOrderFromCart,
  createOrderFromProduct,
  downloadInvoice,
};
