import axios from "axios";
import { api } from "../apiCollection";
import authService from "./authService";

const getAuthHeaders = () => {
  const user = authService.getCurrentUser();
  const token = user ? user.token : null;
  
  if (!token) {
    throw new Error("User is not authenticated.");
  }

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const saveFcmToken = async (token) => {
  try {
    const response = await axios.post(
      api.fcm.storeToken,
      { token },
      { headers: getAuthHeaders() }
    );
    return response.data;
  } catch (error) {
    handleFcmError("saving", error);
  }
};

const removeFcmToken = async (token) => {
  try {
    const response = await axios.post(
      api.fcm.removeToken,
      { token },
      { headers: getAuthHeaders() }
    );
    return response.data;
  } catch (error) {
    handleFcmError("removing", error);
  }
};

const handleFcmError = (action, error) => {
  if (error.response) {
    console.error(`Error ${action} FCM token:`, error.response.data);
    throw new Error(
      `Failed to ${action} FCM token: ${
        error.response.data.message || error.response.statusText
      }`
    );
  } else if (error.request) {
    console.error(
      `Error ${action} FCM token: No response received`,
      error.request
    );
    throw new Error(
      `Failed to ${action} FCM token: No response from the server. Try again later.`
    );
  } else {
    console.error(`Error ${action} FCM token: Unexpected error`, error.message);
    throw new Error(`Failed to ${action} FCM token: ${error.message}`);
  }
};

export default {
  saveFcmToken,
  removeFcmToken,
};
