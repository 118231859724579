import React, { useState, useEffect } from 'react';
import {
    Container, TextField, Button, Card, Typography,
    IconButton, InputAdornment, Box, Grid, Divider,
    useMediaQuery, useTheme, alpha, styled
} from '@mui/material';
import { Lock, Person, Email, Phone, VisibilityOff, Visibility } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { signup } from '../redux/slices/authSlice';
import GoogleLoginButton from '../components/google-auth/GoogleLoginButton';
import { keyframes } from '@mui/system';
import { debounce } from 'lodash';
import Seo from '../components/Seo/Seo';
import { useNavigate, Link } from 'react-router-dom';


const AuthCard = styled(Card)(({ theme }) => ({
    borderRadius: 16,
    background: alpha(theme.palette.background.paper, 0.8),
    boxShadow: theme.shadows[2],
    padding: theme.spacing(3),
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    maxWidth: 450,
    margin: '0 auto'
}));


const SignupPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        email: '',
        mobileNumber: '',
        password: '',
    });

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validateFields = () => {
        let errors = {};
        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors.email = 'Please enter a valid email address';
        }
        if (mobileNumber && !/^\d{10}$/.test(mobileNumber)) {
            errors.mobileNumber = 'Phone number format is invalid';
        }
        if (password && password.length < 7) {
            errors.password = 'Password must be at least 7 characters';
        }
        setValidationErrors(errors);
    };

    const debouncedValidateFields = debounce(validateFields, 500);

    useEffect(() => {
        debouncedValidateFields();
        return () => debouncedValidateFields.cancel();
    }, [email, mobileNumber, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Object.keys(validationErrors).length > 0) return;
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await dispatch(signup({ name, email, mobileNumber, password, confirmPassword })).unwrap();
            navigate('/');
        } catch (err) {
            setError(err.message || 'Failed to sign up');
        }
    };

    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNumber(value);
        }
    };
    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            py: 4,
            position: 'relative',
            overflow: 'hidden',
        }}>
            <Seo title="Sign Up - Vegingo" />

            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                    {/* Illustration Section */}                    
                        <Grid item md={6} xs={12}>
                            <Box sx={{
                                '& img': {
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: 4
                                }
                            }}>
                                <img src="/signup_illustration.jpg" alt="Secure login" />
                            </Box>
                        </Grid>

                    {/* Compact Auth Form Section */}
                    <Grid item xs={12} md={6}>
                        <AuthCard>
                            <Box textAlign="center" mb={3}>
                                <Typography variant="h5" gutterBottom sx={{
                                    fontWeight: 700,
                                    background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    letterSpacing: '-0.5px'
                                }}>
                                    Create Account
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Start your fresh journey with us
                                </Typography>
                            </Box>

                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Person fontSize="small" color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ borderRadius: 1 }}
                                    required
                                />

                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!!validationErrors.email}
                                    helperText={validationErrors.email}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Email fontSize="small" color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ borderRadius: 1 }}
                                    required
                                />

                                <TextField
                                    fullWidth
                                    label="Mobile Number"
                                    type="tel"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={mobileNumber}
                                    onChange={handleMobileNumberChange}
                                    error={!!validationErrors.mobileNumber}
                                    helperText={validationErrors.mobileNumber}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Phone fontSize="small" color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ borderRadius: 1 }}
                                    required
                                />

                                <TextField
                                    fullWidth
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={!!validationErrors.password}
                                    helperText={validationErrors.password}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock fontSize="small" color="primary" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ borderRadius: 1 }}
                                    required
                                />

                                <TextField
                                    fullWidth
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock fontSize="small" color="primary" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ borderRadius: 1 }}
                                    required
                                />

                                {error && (
                                    <Typography
                                        color="error"
                                        variant="body2"
                                        sx={{ mt: 1, fontSize: '0.875rem' }}
                                    >
                                        ⚠️ {error}
                                    </Typography>
                                )}

                                <Button
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    sx={{
                                        mt: 2,
                                        py: 1,
                                        borderRadius: 1,
                                        fontSize: '0.875rem',
                                        background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                                        color: 'white',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: theme.shadows[2]
                                        },
                                        transition: 'all 0.2s ease'
                                    }}
                                >
                                    Create Account
                                </Button>

                                <Box my={2}>
                                    <Divider sx={{ fontSize: '0.75rem' }}>OR CONTINUE WITH</Divider>
                                </Box>

                                <GoogleLoginButton fullWidth />

                                <Box mt={2} textAlign="center">
                                    <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                        Already have an account?{' '}
                                        <Link
                                            to="/account/signin"
                                            style={{
                                                color: theme.palette.primary.main,
                                                textDecoration: 'none',
                                                fontWeight: 500
                                            }}
                                        >
                                            Login here
                                        </Link>
                                    </Typography>
                                </Box>
                            </form>
                        </AuthCard>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default SignupPage;
