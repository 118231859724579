import React from 'react';
import { Box, Typography, Avatar, Paper, Container, useTheme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import StarIcon from '@mui/icons-material/Star';
import SectionTitle from '../core/SectionTitle';
import 'swiper/css';
import 'swiper/css/pagination';

const testimonials = [
  {
    id: 1,
    name: 'Lopstop',
    image: 'https://source.unsplash.com/400x300/?icecream,shop',
    testimonial:
      'VeginGo has made sourcing fresh fruits incredibly easy for us. Our desserts taste even better with their premium quality produce!',
    rating: 5,
  },
  {
    id: 2,
    name: 'Kulhad Chai India',
    image: 'https://source.unsplash.com/400x300/?tea,shop',
    testimonial:
      'The freshness of the ginger, cardamom, and lemons we get from VeginGo enhances the taste of our tea. Our customers love it!',
    rating: 5,
  },
  {
    id: 3,
    name: 'Raj Cool Corner',
    image: 'https://source.unsplash.com/400x300/?juice,shop',
    testimonial:
      'Our juices and shakes require only the best fruits, and VeginGo ensures we get them fresh and on time. Highly reliable service!',
    rating: 5,
  },
  {
    id: 4,
    name: 'The Lucky Eating Point',
    image: 'https://source.unsplash.com/400x300/?restaurant,food',
    testimonial:
      'Our curries and salads are now richer in flavor thanks to the fresh veggies we get from VeginGo. Their service is top-notch!',
    rating: 5,
  },
];


const Testimonials = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        py: 10,
        position: 'relative',
        background: `
          url(https://res.cloudinary.com/dbi8wjrcl/image/upload/v1732275803/vegingo/Untitled_design_25_pb2qp5.png)
        `,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        mt: 10
      }}
    >
      <Container maxWidth="lg">
        <SectionTitle
          t1="What Our Clients Say"
          sx={{ mb: 8, textAlign: 'center' }}
        />

        <Swiper
          modules={[Pagination]}
          spaceBetween={40}
          breakpoints={{
            640: { slidesPerView: 1 },
            960: { slidesPerView: 2 },
            1280: { slidesPerView: 3 }
          }}
          pagination={{
            clickable: true,
            bulletClass: 'swiper-pagination-bullet',
            bulletActiveClass: 'swiper-pagination-bullet-active',
            renderBullet: (index, className) => {
              return `<span class="${className}" style="
                background: ${theme.palette.primary.main};
                width: 12px;
                height: 12px;
                opacity: 0.3;
                transition: all 0.3s ease;
              "></span>`;
            },
          }}
          style={{ padding: '40px 0 60px' }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  position: 'relative',
                  borderRadius: 4,
                  minHeight: 400,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: 'white',
                  boxShadow: '0 12px 32px rgba(0,0,0,0.08)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 16px 48px rgba(0,0,0,0.12)'
                  }
                }}
              >
                {/* Avatar */}
                <Avatar
                  src={testimonial.image}
                  alt={testimonial.name}
                  sx={{
                    width: 100,
                    height: 100,
                    mb: 4,
                    border: `3px solid ${theme.palette.background.paper}`,
                    boxShadow: 3,
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 4
                    },
                    transition: 'all 0.3s ease'
                  }}
                />

                {/* Star Rating */}
                <Box sx={{
                  display: 'flex',
                  mb: 3,
                  '& .MuiSvgIcon-root': {
                    fontSize: '2rem',
                    color: theme.palette.warning.main,
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.2)'
                    }
                  }
                }}>
                  {[...Array(testimonial?.rating)].map((_, i) => (
                    <StarIcon key={i} />
                  ))}
                </Box>

                {/* Testimonial Text */}
                <Typography
                  variant="body1"
                  sx={{
                    mb: 3,
                    fontSize: '1.1rem',
                    lineHeight: 1.6,
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    position: 'relative',
                    '&::before, &::after': {
                      fontSize: '5rem',
                      color: theme.palette.primary.light,
                      position: 'absolute',
                      opacity: 0.3,
                      lineHeight: 1
                    },
                    '&::before': {
                      content: '"\\201C"',  // Left double quotation mark
                      left: -20,
                      top: -30  // Adjusted vertical position
                    },
                    '&::after': {
                      content: '"\\201D"',  // Right double quotation mark
                      right: 30,
                      bottom: -50  // Adjusted vertical position
                    }
                  }}
                >
                  {testimonial.testimonial}
                </Typography>

                {/* User Info */}
                <Box sx={{ mt: 'auto', textAlign: 'center' }}>
                  <Typography variant="h6" fontWeight={800}>
                    {testimonial.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {testimonial.position}
                  </Typography>
                </Box>
              </Paper>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      <style>{`
        .swiper-pagination-bullet-active {
          opacity: 1 !important;
          width: 24px !important;
          border-radius: 8px !important;
        }
      `}</style>
    </Box>
  );
};

export default Testimonials;