import React, { useEffect } from "react";
import { Drawer, Box, Typography, IconButton, Divider, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {  removeFromCart } from "../../../redux/slices/cartSlice";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";



const CartDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { guestCart, serverCart} = useSelector((state) => state.cart);


  const cartItems = isAuthenticated ? serverCart : guestCart;

  // 

  const handleRemove = async (id, name) => {
    try {
      await dispatch(removeFromCart(id)).unwrap();
      toast.success(`${name} removed from cart`);
    } catch (error) {
      toast.error("Failed to remove from cart");
      console.error("Error removing from cart:", error);
    }
  };


  const subtotal = cartItems.reduce((acc, item) => {
    return acc + (item.product.price * (item.quantity ?? 1));
  }, 0);




  return (
    <Drawer anchor="right" open={open} onClose={onClose}>

      <Box
        sx={{
          width: { xs: 300, sm: 400 },
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        {
          !cartItems.length ? (<Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "#f8f8f8" }}>
            <Box>
              <img
                src="/empty_cart.png"
                alt="Empty Cart"
                style={{ width: '200px', marginBottom: '20px' }}
              />
              <Typography variant="h6" fontWeight={"bold"}>Your cart is empty.</Typography>

              <Button href="/shop" onClick={onclose} variant="contained" sx={{ borderRadius: 5, mt: 2, color: "white !important" }}>Continue Shopping</Button>
            </Box>

          </Box>) : (
            <>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Shopping cart
                </Typography>
                <IconButton onClick={onClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>

              <Divider />

              {/* Cart Items */}
              <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2 }}>
                {cartItems.count === 0 ? (
                  <Typography variant="body1">Your cart is empty.</Typography>
                ) : (
                  cartItems.map((item, index) => (
                    <React.Fragment key={item.product.id}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          position: "relative"
                        }}
                      >
                        {/* Product Image */}
                        <Box
                          component="img"
                          src={item.product.image}
                          alt={item.product.name}
                          sx={{
                            width: 50,
                            height: 50,
                            borderRadius: 1,
                            objectFit: "cover",
                            mr: 2,

                          }}
                        />
                        {/* Product Info */}
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                            {item.product.name}
                          </Typography>
                          {/* <Typography variant="caption" color="text.secondary">
                    Vendor: {item.product.vendor}
                  </Typography> */}
                          <Typography variant="body2" sx={{ mt: 0.5 }}>
                            {item.quantity} x ₹{parseFloat(item.product.price)}
                          </Typography>
                        </Box>
                        <IconButton
                          size="small"
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                          }}
                          onClick={() => handleRemove(item.product.id, item.product.name)}
                        >
                          <CloseIcon fontSize="small" sx={{ fontSize: "0.8rem" }} />
                        </IconButton>
                        {/* Right-aligned Price */}
                        <Typography variant="body2" sx={{ fontWeight: "bold", mt: 3 }}>
                          ₹{(parseFloat(item.product.price) * item.quantity).toFixed(2)}
                        </Typography>
                      </Box>
                      {index !== cartItems.length - 1 && <Divider sx={{ my: 1 }} />}
                    </React.Fragment>
                  ))
                )}
              </Box>

              <Divider />

              {/* Footer: Subtotal & Actions */}
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography variant="subtitle1">Subtotal:</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    ₹{subtotal.toFixed(2)}
                  </Typography>
                </Box>
                <Link to={"/cart"}>
                  <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 1 }}
                  >
                    View cart
                  </Button>
                </Link>

                {/* <Link to={"/cart"}>
                  <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Checkout
                  </Button>
                </Link> */}

              </Box>
            </>
          )
        }

      </Box>
    </Drawer>
  );
};

export default CartDrawer;
