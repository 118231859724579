import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import {
    Box,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    Button,
} from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import Seo from '../components/Seo/Seo';

const OrderConfirmedPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [transactionId, setTransactionId] = useState('');
    const [countdown, setCountdown] = useState(3);
    const user = authService.getCurrentUser();
    const token = user ? user.token : null;

    // Extract transaction ID from URL query parameters
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const txnId = params.get('merchantTransactionId');
        if (txnId) {
            setTransactionId(txnId);
        } else {
            setPaymentStatus('SUCCESS'); // Handle COD orders
        }
    }, [location]);

    // Fetch payment status from API
    useEffect(() => {
        const fetchPaymentStatus = async () => {
            try {
                if (transactionId) {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/phonepe/status?merchantTransactionId=${transactionId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setPaymentStatus(
                        response.data.status_data.data.responseCode
                    );
                }
            } catch (error) {
                console.error('Error fetching payment status:', error);
                setPaymentStatus('FAILED');
            }
        };

        if (transactionId) {
            fetchPaymentStatus();
        }
    }, [transactionId, token]);

    // Countdown and redirect to home page
    useEffect(() => {
        if (paymentStatus !== null) {
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev === 1) {
                        clearInterval(timer);
                        navigate('/');
                    }
                    return prev - 1;
                });
            }, 2000);

            return () => clearInterval(timer);
        }
    }, [paymentStatus, navigate]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            bgcolor="#f4f6f8"
        >
            <Seo title='Order Confirmed - "Vegingo' />
            <Card
                sx={{
                    width: '100%',
                    maxWidth: 500,
                    boxShadow: 3,
                    borderRadius: 2,
                    p: 3,
                }}
            >
                <CardContent>
                    {paymentStatus === null ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                            <Typography sx={{ ml: 2 }} variant="h6">
                                Verifying your order...
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box textAlign="center" mb={3}>
                                {paymentStatus === 'SUCCESS' ? (
                                    <CheckCircle
                                        sx={{ fontSize: 60, color: 'green' }}
                                    />
                                ) : (
                                    <Error
                                        sx={{ fontSize: 60, color: 'red' }}
                                    />
                                )}
                            </Box>
                            <Typography
                                variant="h4"
                                textAlign="center"
                                color={
                                    paymentStatus === 'SUCCESS'
                                        ? 'green'
                                        : 'error'
                                }
                            >
                                Order{' '}
                                {paymentStatus === 'SUCCESS'
                                    ? 'Confirmed'
                                    : 'Failed'}
                            </Typography>
                            <Box mt={2}>
                                {transactionId && (
                                    <Typography
                                        variant="body1"
                                        textAlign="center"
                                    >
                                        Transaction ID: {transactionId}
                                    </Typography>
                                )}
                                <Typography
                                    variant="body2"
                                    textAlign="center"
                                    mt={1}
                                    color="text.secondary"
                                >
                                    Payment Status: {paymentStatus}
                                </Typography>
                            </Box>
                            <Box mt={3} textAlign="center">
                                {paymentStatus === 'SUCCESS' ? (
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                    >
                                        Thank you for shopping with us! Your
                                        order has been confirmed and will be
                                        delivered soon.
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Unfortunately, we couldn't confirm
                                            your order due to a payment issue.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ mt: 2 }}
                                            onClick={() =>
                                                window.location.reload()
                                            }
                                        >
                                            Retry Payment
                                        </Button>
                                    </>
                                )}
                            </Box>
                            <Typography
                                variant="body2"
                                textAlign="center"
                                color="text.secondary"
                                mt={2}
                            >
                                Redirecting to homepage in {countdown}{' '}
                                seconds...
                            </Typography>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default OrderConfirmedPage;
