import React from "react";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/core/Footer";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const MainLayout = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <Navbar />
      <main style={{ marginBottom: isSmallScreen ? 50 : 0 }}>
        <Outlet />
      </main>

      {!isSmallScreen && <Footer />}
    </>
  );
};

export default MainLayout;
