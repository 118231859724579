import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container } from "@mui/material";
import SectionTitle from "../core/SectionTitle";
import DealsOfTheDayCard from "./DealsOfTheDayCard";
import { fetchDealOfTheDay } from "../../services/productService"; 

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
const bgImageURL = `${baseCloudinaryURL}/v1732274381/vegingo/Untitled_design_22_ncorll.png`;

export default function DealsOfTheDay() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [deals, setDeals] = useState([]);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  useEffect(() => {
    const loadDealsOfTheDay = async () => {
      setStatus("loading");
      try {
        const data = await fetchDealOfTheDay();
        setDeals(data);
        setStatus("succeeded");
      } catch (error) {
        setError(error.message);
        setStatus("failed");
      }
    };

    loadDealsOfTheDay();
  }, []);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <Box
      sx={{
        background: `url(${bgImageURL})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        py: 2,
        mt: 10,
      }}
    >
      <Container maxWidth="lg">
        <SectionTitle t1={"Deals Of The Day"} />
        <Box sx={{ position: "relative", pb: 4 }}>
          <Swiper
            autoplay={{ delay: 3000 }}
            slidesPerView={isSmallScreen ? 1 : 2}
            spaceBetween={40}
            onSlideChange={handleSlideChange}
            modules={[Autoplay]}
            className="mySwiper3"
            style={{
              zIndex: 10,
              
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {deals.slice(0, 5).map((deal) => (
              <SwiperSlide style={{ height: "100%", zIndex: 1 }} key={deal.id}>
                <DealsOfTheDayCard product={deal} />
              </SwiperSlide>
            ))}
          </Swiper>


          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              gap: 1,
            }}
          >
            {deals.slice(0, 4).map((_, index) => (
              <Box
                key={index}
                onClick={() => handleDotClick(index)}
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor:
                    index === activeIndex
                      ? theme.palette.primary.main
                      : theme.palette.grey[400],
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
