import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import Container from '@mui/material/Container';
import SectionTitle from '../core/SectionTitle';
import Box from "@mui/material/Box";
import CategoryFilter from './CategoryFilter';
import CustomSlider from './CustomSlider';
import ProductCard from '../product/ProductCard';
import { fetchNewProducts } from "../../services/productService";
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

export default function NewProducts() {
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [newProducts, setNewProducts] = useState([]);
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadNewProducts = async () => {
            setStatus('loading');
            try {
                const data = await fetchNewProducts();
                setNewProducts(data);
                setStatus('succeeded');
            } catch (error) {
                setError(error.message);
                setStatus('failed');
            }
        };

        loadNewProducts();
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategoryId(category);
    };

    const filteredProducts = selectedCategoryId === 0 ? newProducts : newProducts.filter(item => item.category_id === selectedCategoryId);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 5 }}>
            <SectionTitle t1={"New Products"} t2={"Discover the Latest Arrivals"} />
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", my:2 }}>
                <CategoryFilter
                    selectedCategoryId={selectedCategoryId}
                    onCategoryChange={handleCategoryChange}
                />

         
                    <Link to={"/shop"}>
                        <Typography variant='body2' fontWeight={"bold"} sx={{ color: "green", textDecoration: "underline", "&:hover": { color: "orange" } }}>View More &rarr;</Typography>
                    </Link>

                
            </Box>

            <CustomSlider
                items={filteredProducts}
                renderSlide={(item) => <ProductCard product={item} />}
                maxSlides={10}
                navigationClass="new-products"
            />


        </Container>
    );
}
