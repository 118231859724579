import React from 'react';
import api from '../../apiCollection';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';

const GoogleLoginButton = () => {
  const handleLogin = () => {
    window.location.href = `${api.google.redirect}`;
  };

  return (
    <Button fullWidth size='small' variant='contained' sx={{
      py: 1.5,
      borderRadius: 2, backgroundColor: "background.default", boxShadow: 2, "&:hover": {
        bgcolor: "#f1f1f1"
      }
    }} onClick={handleLogin}>
      <img style={{ width: "20px" }} src="/google.png"></img>
      <Typography variant="body2" sx={{ ml: 1, color: "black", textTransform: "none", fontWeight: 500 }}>Login with Google</Typography>
    </Button>
  );
};

export default GoogleLoginButton;
