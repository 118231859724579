import React from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Seo from '../components/Seo/Seo';

const FAQPage = () => {
    const faqs = [
        {
            question: 'What is VeginGo?',
            answer: 'VeginGo is an online platform that delivers fresh vegetables and fruits to your doorstep in Lucknow.',
        },
        {
            question: 'How can I place an order?',
            answer: 'To place an order, simply browse through our products, add items to your cart, and proceed to checkout.',
        },
        {
            question: 'What are the payment options available?',
            answer: 'We accept multiple payment methods including credit cards, debit cards, UPI, and cash on delivery.',
        },
        {
            question: 'Do you deliver in my area?',
            answer: 'Currently, we deliver all across Lucknow. Please enter your location at checkout to confirm delivery availability.',
        },
        {
            question: 'What if my items are damaged upon delivery?',
            answer: 'If any item is damaged, please contact us within 24 hours of delivery. We will arrange for a replacement or refund.',
        },
        {
            question: 'Can I cancel or modify my order?',
            answer: 'Yes, you can cancel or modify your order before it is shipped. Please contact customer support for assistance.',
        },
        {
            question: 'How do I track my order?',
            answer: 'Once your order is shipped, you will receive a tracking link via email or SMS. You can use this link to track your order status.',
        },
        {
            question: 'What is your return policy?',
            answer: 'If you are not satisfied with the quality of any product, you can return it within 24 hours of delivery for a replacement or refund.',
        },
        {
            question: 'Is there a minimum order amount for delivery?',
            answer: 'Yes, we have a minimum order amount for delivery to ensure efficient service. You can check the current minimum at checkout.',
        },
        {
            question: 'Are the fruits and vegetables organic?',
            answer: 'We offer both organic and conventional produce. Organic products are clearly labeled on our website.',
        },
        {
            question: 'How can I contact customer support?',
            answer: 'You can reach our customer support team via phone, email, or the live chat feature on our website.',
        },
        {
            question: 'Do you offer discounts or promotions?',
            answer: 'Yes, we frequently offer discounts and promotions. Sign up for our newsletter to stay updated on the latest offers.',
        },
        {
            question: 'Can I schedule my delivery?',
            answer: 'Yes, you can select a preferred delivery slot at checkout to ensure your order arrives at a convenient time.',
        },
        {
            question: 'How do I change my delivery address?',
            answer: 'To change your delivery address, go to your account settings or contact customer support before the order is shipped.',
        },
    ];

    return (
        <Container
            maxWidth="md"
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
            <Seo title="Faq - Vegingo" />
            <Typography
                variant="h4"
                gutterBottom
                style={{ textAlign: 'center' }}
            >
                Frequently Asked Questions
            </Typography>
            {faqs.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
};

export default FAQPage;
