import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Box, Container} from "@mui/material";
import { Link } from "react-router-dom";

const HeroSlider = () => {
  const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

  const slides = [
    {
      id: 1,
      image: `${baseCloudinaryURL}/v1737902548/vegingo/Freshness_Delivered_to_Your_Doorstep_3_gw6y1k.jpg`,
      link: "/shop",
    },
    {
      id: 2,
      image: `${baseCloudinaryURL}/v1737902550/vegingo/Freshness_Delivered_to_Your_Doorstep_2_hwsab4.jpg`,
      link: "/shop",
    },
    {
      id: 3,
      image: `${baseCloudinaryURL}/v1737902545/vegingo/Freshness_Delivered_to_Your_Doorstep_5_vw6ndo.jpg`,
      link: "/shop",
    },
    {
      id: 4,
      image: `${baseCloudinaryURL}/v1737902547/vegingo/Freshness_Delivered_to_Your_Doorstep_4_nzugu8.jpg`,
      link: "/shop",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Container sx={{ mt: 5 }} maxWidth="lg">
      <Box
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: 2,
          width: "100%",
          maxHeight: "500px",
        }}
      >
        <Swiper
          modules={[Autoplay, EffectFade, Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true, dynamicBullets: true }}
          autoplay={{ delay: 5000 }}
          loop
          effect="fade"
          fadeEffect={{ crossFade: true }}
          style={{ width: "100%", maxHeight: "500px" }}
          onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id} style={{ maxHeight: "500px" }}>
              <Link to={slide.link}>
                <img
                  src={slide.image}
                  alt="slide"
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    objectFit: "cover",
                  }}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <style>
        {`
          .swiper-pagination-bullet {
            background: white !important;
            width: 12px !important;
            height: 12px !important;
          }
        `}
      </style>
    </Container>
  );
};

export default HeroSlider;