import React from "react";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { debounce, Box, Button, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useState } from "react";
import { removeFromCart, updateCart } from "../../redux/slices/cartSlice";



const QuantitySelector = ({ cartItem, size = "medium" }) => {
    const dispatch = useDispatch();
    const [localQuantity, setLocalQuantity] = useState(cartItem?.quantity || 1);


    // Debounced function to update cart on the server
    const debouncedUpdateCart = useCallback(
        debounce(async (newQuantity) => {
            if (newQuantity <= 0) {
                try {
                    await dispatch(removeFromCart(cartItem.product.id)).unwrap();
                    toast.success(`${cartItem.product.name} removed from cart`);
                } catch (error) {
                    toast.error("Failed to remove from cart");
                    console.error("Error removing from cart:", error);
                }
            } else {
                try {
                    await dispatch(updateCart({
                        itemId: cartItem.product.id,
                        itemData: {
                            quantity: newQuantity,
                            product_unit: cartItem.product_unit,
                        },
                    })).unwrap();
                    toast.success(`${cartItem.product.name} quantity updated to ${newQuantity}`);
                } catch (error) {
                    toast.error("Failed to update cart");
                    console.error("Error updating cart:", error);
                }
            }
        }, 300),
        [dispatch, cartItem]
    );

    const handleIncrement = () => {
        const newQuantity = localQuantity + 1;
        setLocalQuantity(newQuantity);
        debouncedUpdateCart(newQuantity);
    };

    const handleDecrement = () => {
        const newQuantity = localQuantity - 1;
        if (newQuantity > 0) {
            setLocalQuantity(newQuantity);
            debouncedUpdateCart(newQuantity);
        } else {
            debouncedUpdateCart(newQuantity);
            setLocalQuantity(1); 
        }
    };
    

    // Define styles based on size
    const sizeStyles = {
        small: {
            containerMinWidth: "100px",
            buttonMinWidth: "24px",
            buttonPadding: "1px",
            buttonFontSize: "0.75rem",
            typographyFontSize: "0.875rem",
        },
        medium: {
            containerMinWidth: "120px",
            buttonMinWidth: "32px",
            buttonPadding: "2px",
            buttonFontSize: "0.875rem",
            typographyFontSize: "1rem",
        },
        large: {
            containerMinWidth: "140px",
            buttonMinWidth: "40px",
            buttonPadding: "2px",
            buttonFontSize: "1rem",
            typographyFontSize: "1rem",
        },
    };


    const styles = sizeStyles[size];

    return (
        <Box
            sx={{
                width:"100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                bgcolor: "#f8f8f8",
                border: "1px solid lightGray",
                borderRadius: 1,
                minWidth: styles.containerMinWidth
            }}
        >
            <Button
                variant="contained"
                onClick={handleDecrement}
                sx={{
                    minWidth: styles.buttonMinWidth,
                    padding: styles.buttonPadding,
                    fontSize: styles.buttonFontSize,
                }}
            >
                -
            </Button>
            <Typography variant="body1" sx={{ fontSize: styles.typographyFontSize }}>
                {localQuantity}
            </Typography>
            <Button
                variant="contained"
                onClick={handleIncrement}
                sx={{
                    minWidth: styles.buttonMinWidth,
                    padding: styles.buttonPadding,
                    fontSize: styles.buttonFontSize,
                }}
            >
                +
            </Button>
        </Box>
    );
};


export default QuantitySelector;