// redux/slices/productSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchProducts } from "../../services/productService";

export const loadProducts = createAsyncThunk(
  "products/loadProducts",
  async (_, { getState }) => {
    // Remove argument parameter
    const state = getState();
    const { currentPage, filters } = state.products;
    const data = await fetchProducts({ page: currentPage, ...filters });
    return data;
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    items: [],
    currentPage: 1,
    hasMore: true,
    status: "idle",
    error: null,
    filters: {
      category: "",
      tags: [],
      sort: "low_to_high",
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
      // Reset pagination when filters change
      state.currentPage = 1;
      state.items = [];
      state.hasMore = true;
    },
    resetProducts: (state) => {
      state.items = [];
      state.currentPage = 1;
      state.hasMore = true;
      state.status = "idle";
      state.error = null;
      state.filters = {
        category: "",
        tags: [],
        sort: "low_to_high",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        const {
          products: newProducts,
          pagination: { current_page, total_pages },
        } = action.payload;
        
        

        state.hasMore = current_page < total_pages;
        state.currentPage = current_page + 1;

        if (current_page === 1) {
          state.items = newProducts;
        } else {
          const uniqueProducts = newProducts.filter(
            (newItem) => !state.items.some((item) => item.id === newItem.id)
          );
          state.items.push(...uniqueProducts);
        }
      })
      .addCase(loadProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        if (action.error.name === "AbortError") {
          state.status = "idle";
        }
      });
  },
});

export const { setFilters, resetProducts } = productSlice.actions;
export default productSlice.reducer;
