import { Box, Grid, Typography, Card, CardContent, Container } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green, yellow } from "@mui/material/colors";

const DirectFromFarmBanner = () => {
  return (
    <Box sx={{ py: 2, backgroundColor: "#f8f8f8", mt: { xs: 5, sm: 10 } }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Left Side - Image Section */}
          <Grid item xs={12} md={5}  sx={{ textAlign: "center",display:"flex",justifyContent:"center", alignItems:"center" }}>
            <Box sx={{ position: "relative",width:"max-content"}}>
              <Box
                component="img"
                src="https://images.pexels.com/photos/1435904/pexels-photo-1435904.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="Farm Fresh Produce"
                sx={{
                  width: { xs: "250px", sm: "350px", md: "400px" },
                  aspectRatio: 1,
                  borderRadius: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                component="img"
                src="https://i.pinimg.com/736x/77/bc/4b/77bc4b6f2c255d037357ddca2b0d9f2d.jpg"
                alt="Direct from Farmers"
                sx={{
                  width: { xs: "120px", sm: "150px", md: "200px" },
                  height: { xs: "120px", sm: "150px", md: "200px" },
                  borderRadius: "50%",
                  position: "absolute",
                  bottom: "-10px",
                  left: "80%",
                  transform: "translateX(-50%)",
                  border: "4px solid white",
                  objectFit: "cover",
                }}
              />

            </Box>

          </Grid>

          {/* Right Side - Text Section */}
          <Grid item xs={12} md={7} textAlign={{ xs: "center", md: "left" }}>
            <Typography variant="h4" fontWeight="bold" fontSize={{ xs: "1.5rem", sm: "2rem" }}>
              Fresh Fruits & Vegetables, Direct from Farmers to Your Doorstep
            </Typography>
            <Typography variant="body1" color="text.secondary" mt={2} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
              We work closely with farmers to bring you the freshest, chemical-free produce. As a quick commerce platform, we ensure that fruits and vegetables reach your home in the shortest possible time, preserving their freshness and nutrition.
            </Typography>

            {/* Benefits List */}
            <Box mt={3}>
              {[
                "Sourced directly from local farmers",
                "Delivered fresh within hours",
                "No middlemen, ensuring fair prices for farmers",
              ].map((text, index) => (
                <Box key={index} display="flex" alignItems="center" textAlign={"left"} justifyContent={"flex-start"} mt={1}>
                  <CheckCircleIcon sx={{ color: green[500], mr: 1 }} />
                  <Typography variant="body1" fontWeight="bold">
                    {text}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Grid container spacing={2} mt={4} justifyContent="center">
              {/* Direct Sourcing Card */}
              <Grid item xs={12} sm={6} >
                <Card sx={{ backgroundColor: green[600], height: "100%", color: "white", textAlign: "center" }}>
                  <CardContent>
                    <Box component="img" src="/farmer-icon.png" alt="Direct from Farmers" sx={{ width: 50, mb: 1, display: "block", mx: "auto" }} />
                    <Typography variant="h6" fontWeight="bold">
                      Direct from Farmers
                    </Typography>
                    <Typography variant="body2">
                      We source fresh produce directly from farmers, ensuring quality and fair pricing.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Quick Delivery Card */}
              <Grid item xs={12} sm={6}>
                <Card sx={{ backgroundColor: yellow[700], color: "black", textAlign: "center", height: "100%", }}>
                  <CardContent>
                    <Box component="img" src="/express-delivery-icon.png" alt="Quick Delivery" sx={{ width: 50, mb: 1, display: "block", mx: "auto" }} />
                    <Typography variant="h6" fontWeight="bold">
                      Fast & Fresh Delivery
                    </Typography>
                    <Typography variant="body2">
                      Our quick commerce model ensures you get fresh fruits and vegetables at your doorstep in no time.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DirectFromFarmBanner;
