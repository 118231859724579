import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import { useNavigate } from 'react-router-dom';
import api from '../../apiCollection';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';



export default function SearchBar() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);


    const fetchSuggestions = async (value) => {
        if (value.trim()) {
            try {
                const response = await axios.get(`${api.products.getByName}/${encodeURIComponent(value.trim())}`);
                setSuggestions(response.data.data.products);
                setIsDropdownVisible(true);
            } catch (error) {
                console.error('Error fetching search suggestions:', error);
            }
        } else {
            setSuggestions([]);
            setIsDropdownVisible(false);
        }
    };

    const debouncedFetchSuggestions = useCallback(
        debounce((value) => fetchSuggestions(value), 300),
        []
    );

    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        debouncedFetchSuggestions(value);
    };

    const handleSearch = () => {
        if (query.trim()) {
            navigate(`/search?query=${encodeURIComponent(query.trim())}`);
            setIsDropdownVisible(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion.name);
        navigate(`/search?query=${encodeURIComponent(suggestion.name)}`);
        setIsDropdownVisible(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%", position: 'relative' }}>
            <TextField
                variant="outlined"
                placeholder={"Search products"}
                size='small'
                value={query}
                onKeyDown={handleKeyDown}
                onChange={handleSearchInputChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: { padding: 0}
                }}
                fullWidth
            />
            {isDropdownVisible && (
                <Paper elevation={4} sx={{ position: 'absolute', top: '100%', left: 0, right: 0, zIndex: 1, mt: 1 }}>
                    <List sx={{ padding: 0, margin: 0 }}>
                        {suggestions.slice(0, 6).map((suggestion) => (
                            <ListItem
                                key={suggestion.id}
                                onClick={() => handleSuggestionClick(suggestion)}
                                sx={{ cursor: 'pointer', padding: '8px', '&:hover': { bgcolor: theme.palette.action.hover } }}
                            >
                                {suggestion.name}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </Box>
    );
}