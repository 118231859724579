import React, { useState, useEffect } from 'react';
import {
    Typography, Box, Button, Divider, IconButton,
    Avatar, TextField,
    List, ListItem, ListItemAvatar, ListItemText, useTheme
} from '@mui/material';
import {
    ChatBubble,
    Favorite, Reply, Send
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../apiCollection';

const formatToIST = (dateString) => {
    const options = { timeZone: 'Asia/Kolkata', hour12: false };
    return new Date(dateString).toLocaleString('en-IN', options);
};

const CommentBox = ({ blogComments, blogId }) => {
    const theme = useTheme();
    const { user, isAuthenticated } = useSelector(state => state.auth);
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState(blogComments || []);
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [updatedCommentText, setUpdatedCommentText] = useState('');
    const navigate = useNavigate();

    console.log(blogComments);

    useEffect(() => {
        if (blogComments?.length > 0) {
            setComments(blogComments);
        }
    }, [blogComments]);

    const handleAuthCheck = () => {
        if (!isAuthenticated) {
            navigate("/account/login");
            return false;
        }
        return true;
    };

    const handleCommentSubmit = async () => {
        if (!handleAuthCheck() || !newComment.trim()) return;

        try {
            const response = await axios.post(
                `${api.blogComments.AddBlogComment}/${blogId}`,
                { comment: newComment },
                {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                    },
                }
            );

            setComments(prev => [...prev, {
                ...response.data.comment,
                user: {
                    id: user.id,
                    name: user.name
                },
                created_at: new Date().toISOString(),
            }]);
            setNewComment('');
            toast.success('Comment added successfully!');
        } catch (error) {
            toast.error(error.response?.data?.error || 'Error adding comment');
        }
    };

    const handleUpdateComment = async (commentId) => {
        if (!handleAuthCheck() || !updatedCommentText.trim()) return;

        try {
            await axios.put(
                `${api.blogComments.UpdateBlogComment}/${commentId}`,
                { comment: updatedCommentText },
                {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                    },
                }
            );

            setComments(prev =>
                prev.map(comment =>
                    comment.id === commentId
                        ? { ...comment, comment: updatedCommentText }
                        : comment
                )
            );
            setEditingCommentId(null);
            setUpdatedCommentText('');
            toast.success('Comment updated successfully!');
        } catch (error) {
            toast.error(error.response?.data?.error || 'Error updating comment');
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (!handleAuthCheck()) return;

        try {
            await axios.delete(
                `${api.blogComments.DeleteBlogComment}/${commentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                    },
                }
            );

            setComments(prev => prev.filter(c => c.id !== commentId));
            toast.success('Comment deleted successfully!');
        } catch (error) {
            toast.error(error.response?.data?.error || 'Error deleting comment');
        }
    };

    const renderComments = (items, depth = 0) => (
        <List sx={{
            width: '100%',
            pl: depth > 0 ? 4 : 0,
            borderLeft: depth > 0 ? `2px solid ${theme.palette.divider}` : 'none'
        }}>
            {items.map((comment) => (
                <React.Fragment key={comment.id}>
                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                        <ListItemAvatar>
                            <Avatar src={comment.user.avatar}>
                                {comment.user.name.charAt(0)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography fontWeight="600">{comment.user.name}</Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {formatToIST(comment.created_at)}
                                    </Typography>
                                </Box>
                            }
                            secondary={
                                <>
                                    {editingCommentId === comment.id ? (
                                        <Box sx={{ mt: 1 }}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={updatedCommentText}
                                                onChange={(e) => setUpdatedCommentText(e.target.value)}
                                            />
                                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => handleUpdateComment(comment.id)}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    size="small"
                                                    onClick={() => setEditingCommentId(null)}
                                                >
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <>
                                            <Typography variant="body2" color="text.primary">
                                                {comment.comment}
                                            </Typography>
                                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                {user?.id === comment.user.id && (
                                                    <>
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                setEditingCommentId(comment.id);
                                                                setUpdatedCommentText(comment.comment);
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() => handleDeleteComment(comment.id)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </>
                                                )}
                                                {/* <IconButton size="small">
                                                    <Favorite fontSize="small" />
                                                    <Typography variant="caption" sx={{ ml: 0.5 }}>
                                                        {comment.likes || 0}
                                                    </Typography>
                                                </IconButton> */}
                                            </Box>
                                        </>
                                    )}
                                </>
                            }
                        />
                    </ListItem>
                    {comment.replies && renderComments(comment.replies, depth + 1)}
                </React.Fragment>
            ))}
        </List>
    );

    return (
        <Box sx={{
            bgcolor: 'background.paper',
            borderRadius: 4,
            p: 3,
            boxShadow: theme.shadows[1]
        }}>
            <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <ChatBubble sx={{ mr: 1, color: 'primary.main' }} />
                {comments?.length || 0} Comments
            </Typography>

            <Box sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                    <Avatar sx={{ width: 40, height: 40 }} />
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Write your comment..."
                        multiline
                        rows={2}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        InputProps={{
                            sx: {
                                borderRadius: 4,
                                bgcolor: 'background.default',
                                '&:hover fieldset': { borderColor: 'primary.main' }
                            }
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                    <Button
                        variant="contained"
                        endIcon={<Send />}
                        onClick={handleCommentSubmit}
                        sx={{ borderRadius: 4, px: 3 }}
                    >
                        Post Comment
                    </Button>
                </Box>
            </Box>

            <Divider sx={{ my: 3 }} />

            {comments.length > 0 ? (
                renderComments(comments)
            ) : (
                <Typography color="text.secondary" textAlign="center" py={4}>
                    No comments yet. Be the first to share your thoughts!
                </Typography>
            )}
        </Box>
    );
};

export default CommentBox;