import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    IconButton,
    useMediaQuery,
    useTheme,
    Grid,
    SwipeableDrawer
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DownloadSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const DownloadAppModal = () => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const modalContent = (
        <>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
                <Grid item xs={12} md={6}>
                    <Typography color="secondary" fontWeight={500} variant="h5" component="h2" gutterBottom>
                        Download Our App
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Enhance your experience with our mobile app! Available on both iOS and Android platforms.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate("/install")}
                        startIcon={<DownloadSharp />}
                        fullWidth
                        sx={{ backgroundColor: "primary.main" }}
                    >
                        Download Now
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center",
                        height: isMobile ? 150 : 200
                    }}>
                        <img 
                            src="https://res.cloudinary.com/dbi8wjrcl/image/upload/v1726850774/vegingo/Untitled_design__6_-removebg-preview_jmnde0.png" 
                            style={{ height: "100%", width: "auto" }} 
                            alt="App Preview" 
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );

    if (isMobile) {
        return (
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                onOpen={() => setOpen(true)}
                sx={{
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        padding: 2,
                    }
                }}
            >
                <Box sx={{ px: 2, pb: 2 }}>
                    
                    <Box sx={{ 
                        width: 40,
                        height: 4,
                        backgroundColor: 'grey.400',
                        borderRadius: 2,
                        mx: 'auto',
                        my: 1
                    }} />
                    {modalContent}
                </Box>
            </SwipeableDrawer>
        );
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="download-app-modal"
            aria-describedby="download-app-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
            }}>
                {modalContent}
            </Box>
        </Modal>
    );
};

export default DownloadAppModal;