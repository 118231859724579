import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Link } from 'react-router-dom';
import { Divider, useTheme, Chip, keyframes } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const shine = keyframes`
  0% { left: -50%; }
  100% { left: 150%; }
`;



export default function BlogCard({ blog }) {
    const theme = useTheme();
    
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <Card sx={{
            borderRadius: 4,
            boxShadow: theme.shadows[4],
            overflow: "hidden",
            position: "relative",
            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            bgcolor: 'background.paper',
            border: `1px solid ${theme.palette.divider}`,
            '&:hover': {
                transform: 'translateY(-8px) rotate(1deg)',
                boxShadow: theme.shadows[8],
                '& .blog-image': {
                    transform: 'scale(1.08)'
                },
                '& .shine': {
                    animation: `${shine} 1s ease`
                }
            }
        }}>
            {/* Image Container */}
            <Box sx={{ 
                position: "relative",
                overflow: 'hidden',
                height: 280
            }}>
                {/* Shine Effect */}
                <Box className="shine" sx={{
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    width: '50%',
                    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)',
                    zIndex: 1
                }} />

                <CardMedia
                    component="img"
                    className="blog-image"
                    image={blog.image}
                    alt={blog.title}
                    sx={{ 
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)'
                    }}
                />
                
                {/* Gradient Overlay */}
                <Box sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '40%',
                    background: `linear-gradient(to top, ${theme.palette.mode === 'dark' 
                        ? 'rgba(0,0,0,0.8)' 
                        : 'rgba(0,0,0,0.6)'} 0%, transparent 100%)`
                }}/>
                
                {/* Date Badge */}
                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        background: 'linear-gradient(135deg, #ff6b6b 0%, #ff8e8e 100%)',
                        padding: '8px 16px',
                        borderRadius: 2,
                        boxShadow: theme.shadows[3],
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" sx={{ 
                        fontWeight: 800,
                        color: 'white',
                        lineHeight: 1
                    }}>
                        {new Date(blog.updated_at).getDate()}
                    </Typography>
                    <Typography variant="caption" sx={{ 
                        color: 'white',
                        fontWeight: 600,
                        textTransform: 'uppercase'
                    }}>
                        {formatDate(blog.updated_at).split(' ')[0]}
                    </Typography>
                </Box>
            </Box>

            <CardContent sx={{ 
                px: 3,
                pt: 3,
                pb: 2
            }}>
                {/* Author & Comments */}
                <Box sx={{ 
                    display: 'flex',
                    gap: 2,
                    mb: 2.5,
                    '& .MuiChip-root': {
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: theme.shadows[2]
                        }
                    }
                }}>
                    <Chip
                        icon={<PersonIcon fontSize="small" style={{color:"white"}}/>}
                        label={blog.author}
                        size="small"
                        sx={{
                            bgcolor: theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText,
                            fontWeight: 700,
                            px: 1.5
                        }}
                    />
                    <Chip
                        icon={<ChatBubbleOutlineIcon fontSize="small"  style={{color:"white"}}/>}
                        label={`${blog.comments || 0} Comments`}
                        size="small"
                        sx={{
                            bgcolor: theme.palette.primary.dark,
                            color: theme.palette.secondary.contrastText,
                            fontWeight: 700,
                            px: 1.5
                        }}
                    />
                </Box>

                {/* Title */}
                <Typography variant="h6" sx={{ 
                    fontWeight: 800,
                    lineHeight: 1.3,
                    mb: 2,
                    minHeight: 64,
                    background: `linear-gradient(45deg, ${theme.palette.text.primary}, ${theme.palette.primary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    transition: 'all 0.4s ease',
                    '&:hover': {
                        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }
                }}>
                    {blog.title}
                </Typography>

                {/* Description */}
                <Typography variant="body2" sx={{ 
                    color: 'text.secondary',
                    height: 72,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '20%',
                        background: `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`
                    }
                }}>
                    {blog.shortDesc}
                </Typography>
            </CardContent>

            {/* Read More Button */}
            <Link to={`/blog/${blog.title}`} style={{ textDecoration: "none" }}>
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    endIcon={<ArrowForwardIcon sx={{ transition: 'transform 0.3s ease' }} />}
                    sx={{
                        py: 1.8,
                        borderRadius: 0,
                        background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
                        '&:hover': {
                            background: `linear-gradient(45deg, ${theme.palette.secondary.dark}, ${theme.palette.primary.dark})`,
                            '& .MuiButton-endIcon': {
                                transform: 'translateX(4px)'
                            }
                        },
                        fontWeight: 700,
                        letterSpacing: 0.8,
                        textTransform: 'uppercase'
                    }}
                >
                    Continue Reading
                </Button>
            </Link>
        </Card>
    );
}