import React from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FaCarrot, FaAppleAlt, FaShoppingBag } from 'react-icons/fa';


const categories = [
    { id: 0, name: 'All', icon: '' },
    { id: 1, name: 'Vegetables', icon: <FaCarrot /> },
    { id: 6, name: 'Fruits', icon: <FaAppleAlt /> },
    { id: 7, name: 'Groceries', icon: <FaShoppingBag /> },
];

const StyledButton = styled(Button)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.primary.main : 'white',
    color: selected ? '#FFF' : '#000',
    margin: '0 4px',
    padding: '1px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: selected ? theme.palette.primary.dark : '#E0E0E0',
    },
    transition: 'all 0.3s ease',
}));

const CategoryFilter = ({ selectedCategoryId, onCategoryChange }) => {
    return (
        <ButtonGroup>
            {categories.map((category) => (
                <StyledButton
                    key={category.id}
                    onClick={() => onCategoryChange(category.id)}
                    selected={selectedCategoryId === category.id}
                >
                    <Typography variant='body2' fontWeight={500} sx={{ marginRight: 1 }}>
                        {category.icon}
                    </Typography>
                    <Typography variant='body2' fontWeight={500} sx={{ textTransform: "none" }}>
                        {category.name}
                    </Typography>
                </StyledButton>
            ))}
        </ButtonGroup>
    );
};

export default CategoryFilter;
