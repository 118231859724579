import React, { useEffect, useState } from 'react';
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from "@mui/material/Slide";
import MenuBar from './MenuBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import TopBar from "./TopBar";
import MobileNavbar from './MobileNavbar';
import { useDispatch, useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import { fetchCart } from '../../redux/slices/cartSlice';
import AnnouncementBar from './AnnouncementBar';




const Navbar = () => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const trigger = useScrollTrigger();

  const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
   
  
    useEffect(() => {
      if (isAuthenticated) {
        dispatch(fetchCart());
      }
    }, [isAuthenticated, dispatch]);
  
    
 

  return (
    <>
      {!isSmallScreen && <TopBar />}

      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          position='sticky'
          sx={{ transition: 'all 0.3s ease-in-out', top: 0, left: 0, bgcolor: 'background.default' }}
        >
          <Toolbar sx={{p:0}}>
            <MenuBar />
          </Toolbar>
        </AppBar>
      </Slide>
      <AnnouncementBar/>


      {/* Mobile navigation for small screens */}
      {isSmallScreen && <MobileNavbar/>}
    </>
  );
};

export default Navbar;
