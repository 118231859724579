import React, { useEffect, useState } from 'react';
import Testimonials from '../components/home/Testimonials';
import OurProducts from '../components/home/OurProducts';
import NewProducts from '../components/home/NewProducts';
import BestSeller from '../components/home/BestSeller';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import HeroSlider from '../components/home/HeroSlider';
import PopularCategoryFilter from '../components/home/PopularCategoryFilter';
import DealsOfTheDay from '../components/home/DealsOfTheDay';
import Features from '../components/home/Features';
import LatestBlogs from '../components/home/LatestBlogs';
import DirectFromFarmBanner from '../components/home/DirectFromFarmBanner';
import WideRangeBanner from '../components/home/WideRangeBanner';
import DownloadApp from '../components/home/DownloadApp';
import Seo from '../components/Seo/Seo';
import CouponsSection from '../components/home/CouponsSection';

export default function HomePage() {
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
        localStorage.setItem('hasSeenModal', 'true');
    };

    useEffect(() => {
        const hasSeenModal = localStorage.getItem('hasSeenModal');
        if (!hasSeenModal) {
            setModalOpen(true);
        }
    }, []);

    return (
        <>
            <Seo title="Vegingo" desc="Fresh Vegetables in Lucknow" />
            {/* Modal for warning */}
            <Dialog open={modalOpen} onClose={handleCloseModal}>
                <DialogTitle>Website Under Development ⚠️</DialogTitle>
                <DialogContent>
                    <Typography>
                        This website is currently under development. There may
                        be bugs, and certain features might not work as
                        expected. Please do not make any payments at this time.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseModal}
                        color="warning"
                        variant="contained"
                    >
                        Understood
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Main Home content */}
            <HeroSlider />
            <CouponsSection/>

            <PopularCategoryFilter />
            <DealsOfTheDay />
            <NewProducts />
            <DirectFromFarmBanner />
            <BestSeller />
            <WideRangeBanner />
            <OurProducts />
            <Testimonials />
            <LatestBlogs />
            <DownloadApp />
            <Features />
        </>
    );
}
