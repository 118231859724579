import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CartTableItem from './CartTableItem';



export default function CartTable() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { guestCart, serverCart } = useSelector((state) => state.cart);
  const cartItems = isAuthenticated ? serverCart : guestCart;

  


  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ bgcolor: "#f8f8f8" }}>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.map((item) => (
            <CartTableItem key={item.product.id} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
