import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Grid,
    Paper,
    Divider,
    Container,
    useMediaQuery,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { fetchProductBySlug } from '../services/productService';
import Loading from '../components/core/Loading';
import { useTheme } from '@mui/material';
import AddToCartButton from '../components/product/AddToCartButton';
import RatingReview from '../components/product/RatingReview';
import { useSelector } from 'react-redux';
import QuantitySelector from '../components/product/QuantitySelector';
import Seo from '../components/Seo/Seo';

const ProductDetailsPage = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const [error, setError] = useState('');
    const slug = location.pathname.split('/').pop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { isAuthenticated } = useSelector((state) => state.auth);
    const { serverCart, guestCart } = useSelector((state) => state.cart);

    const cart = isAuthenticated ? serverCart : guestCart;
    const cartItem = product
        ? cart.find((item) => item.product?.id === product.id)
        : null;

    useEffect(() => {
        const fetchProduct = async () => {
            setLoading(true);
            setError('');
            try {
                const data = await fetchProductBySlug(slug);
                if (data) {
                    setProduct(data);
                } else {
                    setError('Product not found.');
                }
            } catch (err) {
                setError(
                    'An error occurred while fetching the product details.'
                );
            } finally {
                setLoading(false);
            }
        };
        fetchProduct();
    }, [slug]);

    if (loading) return <Loading />;

    if (error) {
        return (
            <Box
                sx={{
                    minHeight: '80vh',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <img
                        src="/product-not-found.webp"
                        width="100%"
                        style={{ maxWidth: 400 }}
                        alt="Not Found"
                    />
                    <Typography variant="h5" fontWeight={600}>
                        {error}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (!product) return null;

    return (
        <Container sx={{ my: isMobile ? 4 : 6, px: isMobile ? 2 : 0 }}>
            <Seo
                title={product.name}
                desc="Fresh Vegetables at your door step in lucknow"
            />
            <Grid container spacing={isMobile ? 3 : 6}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderRadius: 2,
                            overflow: 'hidden',
                            boxShadow: 2,
                            maxWidth: '100%',
                            height: isMobile ? 300 : 500,
                        }}
                    >
                        <img
                            src={
                                product.image ||
                                'https://via.placeholder.com/500'
                            }
                            alt={product.name}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={2}
                        sx={{ p: isMobile ? 2 : 4, borderRadius: 2 }}
                    >
                        <Typography
                            variant={isMobile ? 'h5' : 'h4'}
                            fontWeight={600}
                            gutterBottom
                        >
                            {product.name}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                mb: 2,
                            }}
                        >
                            {product.discounted_price && (
                                <Typography
                                    variant={isMobile ? 'h6' : 'h5'}
                                    fontWeight={600}
                                    color="primary"
                                >
                                    ₹{product.discounted_price}
                                </Typography>
                            )}
                            <Typography
                                variant={
                                    product.discounted_price
                                        ? 'body1'
                                        : isMobile
                                        ? 'h6'
                                        : 'h5'
                                }
                                sx={{
                                    textDecoration: product.discounted_price
                                        ? 'line-through'
                                        : 'none',
                                    color: product.discounted_price
                                        ? 'gray'
                                        : theme.palette.primary.main,
                                }}
                            >
                                ₹{product.price}
                            </Typography>
                        </Box>

                        <Typography
                            variant="body2"
                            color="text.secondary"
                            mb={2}
                        >
                            {product.description || 'No description available.'}
                        </Typography>

                        {cartItem ? (
                            <QuantitySelector
                                cartItem={cartItem}
                                size={isMobile ? 'medium' : 'large'}
                            />
                        ) : (
                            <AddToCartButton
                                product={product}
                                size={isMobile ? 'medium' : 'large'}
                            />
                        )}

                        <Divider sx={{ my: 3 }} />

                        <Typography variant="h6" gutterBottom>
                            Delivery Information
                        </Typography>
                        <Typography variant="body2">
                            {product.deliveryInfo ||
                                'Get your order delivered within 10-30 minutes!'}
                        </Typography>
                        <Typography variant="body2">
                            Express delivery available for select locations.
                        </Typography>

                        <Divider sx={{ my: 3 }} />

                        <Typography variant="body2" color="text.secondary">
                            * Prices include all taxes and duties.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Box sx={{ mt: isMobile ? 4 : 6 }}>
                <RatingReview productId={product.id} />
            </Box>
        </Container>
    );
};

export default ProductDetailsPage;
