// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyARq8xJ0TrMnRHfBJYkXo7r8Psut8MNZII",
  authDomain: "vegingo-be82f.firebaseapp.com",
  projectId: "vegingo-be82f",
  storageBucket: "vegingo-be82f.appspot.com",
  messagingSenderId: "672950832013",
  appId: "1:672950832013:web:41472b6f2b69992c77cbc4",
  measurementId: "G-6WNSM0XS60",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

const vapidKey =
  "BNuDki-QW77cyizAEUjgEBScUxPaJsN0lsnQXTnURwYSnb13V9c5625iDwbdQBTL6z1TTdq5fc8TbTM88MPWtMY";

export const getDeviceToken = async () => {
  try {
    // Check if notifications are allowed
    if (Notification.permission === "denied") {
      alert(
        "You have blocked notifications. Please enable them in your browser settings."
      );
      throw new Error(
        "Notification permission was denied. Please enable notifications in your browser settings."
      );
    }

    // If permission is not granted yet, request permission
    if (Notification.permission !== "granted") {
      await Notification.requestPermission();
    }

    // Get device token from Firebase Messaging
    const token = await getToken(messaging, { vapidKey });

    if (token) {
      
      return token;
    } else {
      throw new Error("Failed to get device token.");
    }
  } catch (error) {
    console.error("Error getting device token:", error);
    throw error;
  }
};

// Handle incoming messages (foreground)
export const handleIncomingMessages = () => {
  onMessage(messaging, (payload) => {
    
  });
};
