import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CouponModal from './CouponModal';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DiscountIcon from '@mui/icons-material/Discount';
import Confetti from 'react-confetti';
import AddressModal from './AddressModal';
import PaymentButton from '../../pages/PaymentButton';
import FreeDelivery from './cart-drawer/FreeDelivery';

export default function CartSummary() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { guestCart, serverCart } = useSelector((state) => state.cart);

  const cartItems = isAuthenticated ? serverCart : guestCart;

  const { addresses } = useSelector(state => state.address);
  const { selectedCoupon, selectedAddress } = useSelector(state => state.order);


  const subtotal = cartItems.reduce((acc, item) => {
    return acc + (item.product.discounted_price * (item.quantity ?? 1));
  }, 0);


  const [note, setNote] = useState("");
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isFreeDelivery, setIsFreeDelivery] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(selectedAddress || '');


  const deliveryCharge = isFreeDelivery ? 0 : 600;


  const defaultAddress = useMemo(
    () => addresses.find(address => address.is_default),
    [addresses]
  );

  const deliveryAddress = useMemo(
    () => addresses.find(address => address.id === selectedAddress),
    [addresses, selectedAddress]
  );


  useEffect(() => {
    if (!selectedAddressId && defaultAddress) {
      setSelectedAddressId(defaultAddress.id);
    }
  }, [defaultAddress, selectedAddressId]);

  useEffect(() => {
    if (!coupon && selectedCoupon) {
      setCoupon(selectedCoupon);
    }
  }, [selectedCoupon, coupon]);


  const discount = useMemo(() => {
    if (!coupon) return 0;
    const discountAmount = parseInt(coupon.discount_amount, 10) || 0;
    return coupon.discount_type === "percentage"
      ? parseFloat((subtotal / 100) * discountAmount)
      : parseFloat(discountAmount);
  }, [coupon, subtotal]);

  // Memoized calculation for grand total
  const grandTotal = useMemo(
    () => parseFloat(subtotal + deliveryCharge - discount),
    [subtotal, deliveryCharge, discount]
  );

  // Consolidated bill details for the PaymentButton
  const billDetails = useMemo(
    () => ({
      cartTotal: subtotal,
      deliveryCharge,
      discount,
      grandTotal,
    }),
    [subtotal, deliveryCharge, discount, grandTotal]
  );

  // Coupon selection handler using useCallback
  const handleCouponSelection = useCallback((selectedCoupon) => {
    if (selectedCoupon) {
      setCoupon(selectedCoupon);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    } else {
      setCoupon(null);
    }
  }, []);

  return (
    <Box sx={{bgcolor: 'background.paper', borderRadius: 2 }}>
      {showConfetti && <Confetti width={400} height={window.innerHeight} />}

      {/* Coupon Section */}
      {isAuthenticated && (
        <Box sx={{
          mb: 3,
          p: 2,
          bgcolor: 'grey.50',
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <DiscountIcon color="primary" sx={{ fontSize: 32 }} />
              <Box>
                <Typography variant="subtitle1" fontWeight={600}>
                  {coupon ? 'Coupon Applied' : 'Add Coupon'}
                </Typography>
                {coupon && (
                  <Typography variant="body2" color="success.main">
                    Saved ₹{discount}
                  </Typography>
                )}
              </Box>
            </Box>
            <Button
              variant={coupon ? "outlined" : "contained"}
              color="primary"
              size="small"
              onClick={() => setIsCouponModalOpen(true)}
              sx={{
                borderRadius: 50,
                textTransform: 'uppercase',
                letterSpacing: 0.5,
                px: 2.5
              }}
            >
              {coupon ? "Change" : "Add"}
            </Button>
          </Box>
        </Box>
      )}

      {/* Free Delivery Toggle */}
      <FreeDelivery
        cartTotal={subtotal}
        isFreeDelivery={isFreeDelivery}
        onChange={setIsFreeDelivery}
        sx={{ mb: 3 }}
      />

      {/* Bill Details */}
      <Box sx={{
        mb: 3,
        p: 2,
        bgcolor: 'grey.50',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider'
      }}>
        <Typography variant="subtitle1" fontWeight={600} gutterBottom>
          Order Summary
        </Typography>

        <Box sx={{ mb: 1.5 }}>
          <BillRow
            icon={<ReceiptIcon />}
            label="Subtotal"
            value={`₹${parseFloat(subtotal).toFixed(2)}`}
          />
          <BillRow
            icon={<DeliveryDiningIcon />}
            label="Delivery"
            value={isFreeDelivery ? 'FREE' : `₹${parseFloat(deliveryCharge).toFixed(2)}`}
            valueColor={isFreeDelivery ? 'success.main' : 'text.primary'}
          />
          {coupon && (
            <BillRow
              icon={<DiscountIcon />}
              label="Coupon Discount"
              value={`- ₹${parseFloat(discount).toFixed(2)}`}
              valueColor="success.main"
            />
          )}
        </Box>

        <Divider sx={{ my: 1.5 }} />

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1
        }}>
          <Typography variant="h6" fontWeight={700}>
            Total Amount
          </Typography>
          <Typography variant="h6" fontWeight={700} color="success.main">
            ₹{parseFloat(grandTotal).toFixed(2)}
          </Typography>
        </Box>
      </Box>

      {/* Delivery Address */}
      {isAuthenticated && (
        <Box sx={{
          mb: 3,
          p: 2,
          bgcolor: 'grey.50',
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1.5
          }}>
            <Typography variant="subtitle1" fontWeight={600}>
              Delivery Address
            </Typography>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => setIsAddressModalOpen(true)}
              sx={{ textTransform: 'uppercase' }}
            >
              {deliveryAddress ? 'Change' : 'Add Address'}
            </Button>
          </Box>

          {deliveryAddress ? (
            <Box>
              <Typography variant="body2" paragraph sx={{ mb: 0 }}>
                <Box component="span" fontWeight={500}>{deliveryAddress.user_name}</Box>
                <br />
                {deliveryAddress.building_name_area}, {deliveryAddress.landmark}
                <br />
                {deliveryAddress.city}, {deliveryAddress.state} - {deliveryAddress.pincode}
                <br />
                Phone: {deliveryAddress.phone_number}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary">
              No address selected
            </Typography>
          )}
        </Box>
      )}

      {/* Order Notes */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" fontWeight={600} gutterBottom>
          Order Notes
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Special instructions, delivery notes..."
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              '&:hover fieldset': { borderColor: 'primary.main' },
            }
          }}
        />
      </Box>

      {/* Payment Action */}
      <Box sx={{ mt: 4 }}>
        {isAuthenticated ? (
          deliveryAddress ? (
            <PaymentButton
              billDetails={billDetails}
              selectedAddress={selectedAddressId}
              orderNote={note}
              selectedCoupon={coupon?.coupon_code || ''}
              sx={{ py: 1.5, fontSize: '1rem' }}
            />
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setIsAddressModalOpen(true)}
              sx={{
                fontSize: '1rem',
                fontWeight: 600,
                textTransform: 'uppercase',
                letterSpacing: 0.5
              }}
            >
              Add Address to Continue
            </Button>
          )
        ) : (
          <Button
            component={Link}
            to="/account/login"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              textTransform: 'uppercase',
              letterSpacing: 0.5,
              color:"white !important"
            }}
          >
            Login to Checkout
          </Button>
        )}
      </Box>

      {/* Modals */}
      <CouponModal
        open={isCouponModalOpen}
        handleClose={() => setIsCouponModalOpen(false)}
        onSelectCoupon={handleCouponSelection}
      />
      <AddressModal
        selectedAddressId={selectedAddressId}
        onChange={setSelectedAddressId}
        open={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
      />
    </Box>
  );
}

// Helper component for bill rows
const BillRow = ({ icon, label, value, valueColor = 'text.primary' }) => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1.5
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
      {React.cloneElement(icon, { sx: { color: 'text.secondary', fontSize: 20 } })}
      <Typography variant="body2" color="text.primary">
        {label}
      </Typography>
    </Box>
    <Typography variant="body2" color={valueColor} fontWeight={500}>
      {value}
    </Typography>
  </Box>
);

