import React from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Seo from '../components/Seo/Seo';

const ShippingReturnPolicyPage = () => {
    return (
        <Container sx={{ marginY: 4 }}>
            <Seo title="Return Policy - Vegingo" />
            <Typography variant="h4" align="center" gutterBottom>
                Shipping and Return Policy
            </Typography>

            <Box sx={{ marginY: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Shipping Policy
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Delivery Locations</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            We deliver fresh vegetables and fruits to select
                            areas. Please check your pin code at checkout to
                            confirm availability.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Delivery Charges</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Free delivery on orders above ₹500. A small delivery
                            fee of ₹50 applies to orders below ₹500.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Delivery Time</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            We ensure same-day delivery for orders placed before
                            12 PM. Orders placed after 12 PM will be delivered
                            the next day.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box sx={{ marginY: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Return Policy
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Eligibility for Returns</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            We accept returns within 24 hours of delivery for
                            items that are damaged, spoiled, or incorrect.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Return Process</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            To initiate a return, contact our customer support
                            with your order details and photos of the issue. We
                            will arrange a pickup or provide further
                            instructions.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Refunds</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Refunds for eligible returns will be processed
                            within 3-5 business days to your original payment
                            method.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Typography
                variant="body2"
                align="center"
                color="text.secondary"
                sx={{ marginTop: 3 }}
            >
                For more assistance, contact us at support@example.com or call
                us at +91-9876543210.
            </Typography>
        </Container>
    );
};

export default ShippingReturnPolicyPage;
