import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import CartList from '../components/cart/cart-drawer/CartList';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SimilarProducts from '../components/cart/SimilarProducts';
import { useDispatch, useSelector } from 'react-redux';
import CartTable from '../components/cart/CartTable';
import { Button, Typography, useMediaQuery } from '@mui/material';
import CartSummary from '../components/cart/CartSummary';
import { fetchCart } from '../redux/slices/cartSlice';
import Loading from '../components/core/Loading';
import Seo from '../components/Seo/Seo';

export default function CartPage() {
    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const { guestCart, serverCart, loading } = useSelector(
        (state) => state.cart
    );

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCart());
        }
    }, [isAuthenticated, dispatch]);

    const cartItems = isAuthenticated ? serverCart : guestCart;

    if (loading.fetchCart) {
        return <Loading />;
    }

    if (!cartItems.length) {
        return (
            <Box
                sx={{
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <img
                        src="/empty_cart.png"
                        alt="Empty Cart"
                        style={{ width: '300px', marginBottom: '20px' }}
                    />
                    <Typography variant="h6" fontWeight={'bold'}>
                        Your cart is empty.
                    </Typography>

                    <Button
                        href="/shop"
                        onClick={onclose}
                        variant="contained"
                        sx={{
                            borderRadius: 5,
                            mt: 2,
                            color: 'white !important',
                        }}
                    >
                        Continue Shopping
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            <Seo title="Cart - Vegingo" />
            <Container sx={{ minHeight: '100vh', my: 5 }} maxWidth="lg">
                <Grid columnSpacing={2} rowGap={5} container>
                    <Grid item md={8} xs={12}>
                        {isSmallScreen ? <CartList /> : <CartTable />}

                        <SimilarProducts />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Paper
                            elevation={2}
                            sx={{ p: 2, position: 'sticky', top: 20 }}
                        >
                            <CartSummary />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
