import React, { useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import OrderItem from './OrderItem';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function OrderList() {
    const { orderHistory } = useSelector(state => state.order);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const navigate = useNavigate();

    const handleViewDetails = (order) => {
        setSelectedOrder(order);
    };

    const handleCloseDetails = () => {
        setSelectedOrder(null);
    };

    return (
        <>
            {selectedOrder ? (
                <OrderItem
                    order={selectedOrder}
                    onClose={handleCloseDetails}
                    showDetails={true}
                />
            ) : (
                <>
                    {orderHistory.length > 0 ? (
                        <List>
                            {orderHistory.map((order, index) => (
                                <React.Fragment key={index}>
                                    <ListItem>
                                        <OrderItem
                                            order={order}
                                            onViewDetails={() => handleViewDetails(order)}
                                        />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                        >
                            <img width={300} src="/order-empty.png" alt='order not found' />
                            <Typography variant="h5" color="textSecondary" fontWeight={600}>
                                No orders found.
                            </Typography>
                            <Button size='small' onClick={()=>navigate("/shop")} variant={"contained"} sx={{borderRadius:5, mt:2}}>Continue Shopping</Button>
                        </Box>
                    )}
                </>
            )}
        </>
    );
}
