import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import SectionTitle from '../core/SectionTitle';
import CategoryFilter from './CategoryFilter';
import CustomSlider from './CustomSlider';
import ProductCard from '../product/ProductCard';
import { fetchBestSellers } from '../../services/productService'; 
import { Link } from 'react-router-dom';

export default function BestSellers() {
  const [bestSellers, setBestSellers] = useState([]); 
  const [status, setStatus] = useState('idle'); 
  const [error, setError] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0); 

  useEffect(() => {
    const loadBestSellersData = async () => {
      setStatus('loading');
      try {
        const data = await fetchBestSellers();
        setBestSellers(data);
        setStatus('succeeded');
      } catch (err) {
        setError(err.message);
        setStatus('failed');
      }
    };

    loadBestSellersData();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategoryId(category);
  };

  const filteredProducts = selectedCategoryId === 0 ? bestSellers : bestSellers.filter(item => item.category_id === selectedCategoryId);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 5, md: 10 } }}>
      <SectionTitle t1="Best Sellers" t2="Shop Our Most Popular Items" />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", my: 2 }}>
        <CategoryFilter selectedCategoryId={selectedCategoryId} onCategoryChange={handleCategoryChange} />

        <Link to={"/shop"}>
          <Typography variant='body2' fontWeight={"bold"} sx={{ color: "green", textDecoration: "underline", "&:hover": { color: "orange" } }}>View More &rarr;</Typography>
        </Link>
      </Box>

      <CustomSlider
        items={filteredProducts}
        renderSlide={(item) => <ProductCard product={item} />}
        maxSlides={10}
        navigationClass="best-seller"
      />

    </Container>
  );
}
