import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function CustomSlider({ items, renderSlide, maxSlides = 10, navigationClass }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Swiper
        navigation={isLargeScreen ? {
          nextEl: `.${navigationClass}-next`,
          prevEl: `.${navigationClass}-prev`,
        } : false}
        modules={[Navigation]}
        loop={items.length > 5} 
        breakpoints={{
          320: { slidesPerView: 2, spaceBetween: 8 },
          480: { slidesPerView: 3, spaceBetween: 10 },
          768: { slidesPerView: 4, spaceBetween: 12 },
          1024: { slidesPerView: 5, spaceBetween: 15 },
        }}
        style={{ width: "100%", padding:2}}
      >
        {items.slice(0, maxSlides).map((item, index) => (
          <SwiperSlide key={item.id || index}>{renderSlide(item)}</SwiperSlide>
        ))}
      </Swiper>

      {isLargeScreen && (
        <>
          <Box
            className={`${navigationClass}-prev`}
            sx={{
              position: "absolute",
              top: "50%",
              left: 10,
              zIndex: 100,
              transform: "translateY(-50%)",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              borderRadius: "50%",
              width: 36,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: 1,
              transition: "all 0.3s",
              "&:hover": { backgroundColor: theme.palette.primary.dark },
            }}
          >
            <KeyboardArrowLeftIcon />
          </Box>

          <Box
            className={`${navigationClass}-next`}
            sx={{
              position: "absolute",
              top: "50%",
              right: 10,
              zIndex: 100,
              transform: "translateY(-50%)",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              borderRadius: "50%",
              width: 36,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: 1,
              transition: "all 0.3s",
              "&:hover": { backgroundColor: theme.palette.primary.dark },
            }}
          >
            <KeyboardArrowRightIcon />
          </Box>
        </>
      )}
    </Box>
  );
}
