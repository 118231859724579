import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import couponService from '../../services/cartService';
import CouponItem from './CouponItem';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { setSelectedCoupon as setSelectedCouponRedux } from '../../redux/slices/orderSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 350, sm: 400 },
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  overflow: 'hidden',
};

export default function CouponModal({ open, handleClose, onSelectCoupon }) {
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedCoupon: selectedCouponRedux } = useSelector((state) => state.order);
  const [selectedCoupon, setSelectedCoupon] = useState(selectedCouponRedux || null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await couponService.getAllCoupons();
        setCoupons(response.data);
        setFilteredCoupons(response.data);
      } catch (err) {
        console.error(err);
        setError('Failed to load coupons');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchCoupons();
    }
  }, [open]);

  useEffect(() => {
    const filtered = coupons.filter((coupon) =>
      coupon.coupon_code.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCoupons(filtered);
  }, [searchTerm, coupons]);

  const handleCouponSelect = (coupon) => {
    if (selectedCoupon?.id === coupon.id) {
      setSelectedCoupon(null);
      onSelectCoupon(null);
    } else {
      setSelectedCoupon(coupon);
    }
  };

  const handleApplyCoupon = () => {
    if (selectedCoupon) {
      dispatch(setSelectedCouponRedux(selectedCoupon));
      onSelectCoupon(selectedCoupon);
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {/* Header */}
        <Box sx={{ px: 3, pt: 3, pb: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
          <Typography variant="h6" align="center">
            Select a Coupon
          </Typography>
        </Box>

        {/* Search */}
        <Box sx={{ px: 3, py: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search for a coupon..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>

        <Divider />

        {/* Coupon List */}
        <Box sx={{ height: 300, overflowY: 'auto', px: 3, py: 2 }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : error ? (
            <Typography color="error" align="center">
              {error}
            </Typography>
          ) : filteredCoupons.length > 0 ? (
            filteredCoupons.map((coupon) => (
              <CouponItem
                key={coupon.id}
                coupon={coupon}
                selectedCoupon={selectedCoupon}
                onSelect={handleCouponSelect}
              />
            ))
          ) : (
            <Typography align="center">No coupons available.</Typography>
          )}
        </Box>

        {/* Action Buttons */}
        {selectedCoupon && (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              px: 3,
              py: 2,
              borderTop: '1px solid',
              borderColor: 'divider',
              backgroundColor: 'background.default',
            }}
          >
            <Button fullWidth variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" color="primary" onClick={handleApplyCoupon}>
              Apply Coupon
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
