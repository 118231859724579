import React, { useState } from 'react';
import {
    Container, TextField, Button, Grid, Box,
    Typography, Paper, useTheme, keyframes
} from '@mui/material';
import contactService from '../services/contactService';
import { toast } from 'react-toastify';
import Seo from '../components/Seo/Seo';
import SendIcon from '@mui/icons-material/Send';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

export default function ContactPage() {
    const theme = useTheme();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({ ...prev, [id]: value }));
        setErrors(prev => ({ ...prev, [id]: '' }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.subject.trim()) newErrors.subject = 'Subject is required';
        if (!formData.message.trim()) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsSubmitting(true);
        try {
            await contactService.submitContactForm({ ...formData });
            toast.success('Message sent successfully!', { position: 'top-center' });
            setFormData({ name: '', email: '', subject: '', message: '' });
        } catch (error) {
            toast.error('Failed to send message. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container maxWidth="lg" sx={{
            minHeight: '100vh',
            py: { xs: 4, md: 8 },
            background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        }}>
            <Seo title="Contact Us - VeginGo" />

            <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 6 } }}>
                <Typography variant="h2" sx={{
                    fontWeight: 800,
                    mb: 2,
                    fontSize: { xs: '2rem', md: '3rem' },
                    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}>
                    Get in Touch
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{
                    maxWidth: 600,
                    mx: 'auto',
                    fontSize: { xs: '0.875rem', md: '1rem' }
                }}>
                    Have questions or suggestions? We're always here to help!
                </Typography>
            </Box>

            <Grid container spacing={{ xs: 4, md: 6 }} justifyContent="center" alignItems="center">
                {/* Illustration Section */}
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{
                        position: 'relative',
                        width: { xs: '100%', md: 400 },
                        maxWidth: 400,
                        height: { xs: 300, md: 400 },
                        animation: `${floatAnimation} 4s ease-in-out infinite`,
                        '&:before': {
                            content: { xs: 'none', md: '""' },
                            position: 'absolute',
                            top: -20,
                            left: -20,
                            right: 20,
                            bottom: 20,
                            background: theme.palette.primary.main,
                            zIndex: -1,
                            borderRadius: 4,
                            boxShadow: theme.shadows[4],
                        },
                        '&:after': {
                            content: { xs: 'none', md: '""' },
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            border: `4px solid ${theme.palette.primary.main}`,
                            borderRadius: 4,
                            zIndex: 1,
                            pointerEvents: 'none',
                        }
                    }}>
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            borderRadius: 4,
                            overflow: 'hidden',
                            zIndex: 2,
                            boxShadow: theme.shadows[4],
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                transition: 'transform 0.4s ease',
                            },
                        }}>
                            <img
                                src="/contact_illustration.jpg"
                                alt="Contact Illustration"
                                loading="lazy"
                            />
                        </Box>
                    </Box>
                </Grid>

                {/* Form Section */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={0} sx={{
                        p: { xs: 2, md: 4 },
                        borderRadius: 4,
                        background: theme.palette.mode === 'dark'
                            ? 'rgba(255,255,255,0.05)'
                            : 'rgba(255,255,255,0.9)',
                        backdropFilter: 'blur(12px)',
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: `0 16px 32px ${theme.palette.mode === 'dark'
                            ? 'rgba(0,0,0,0.3)'
                            : 'rgba(0,0,0,0.1)'}`
                    }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        label="Your Name"
                                        variant="outlined"
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                }
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                }
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="subject"
                                        label="Subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        error={!!errors.subject}
                                        helperText={errors.subject}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                }
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="message"
                                        label="Your Message"
                                        multiline
                                        rows={4}
                                        value={formData.message}
                                        onChange={handleChange}
                                        error={!!errors.message}
                                        helperText={errors.message}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.primary.main,
                                                }
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        endIcon={<SendIcon />}
                                        disabled={isSubmitting}
                                        fullWidth
                                        sx={{
                                            py: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                                            color: 'white',
                                            fontWeight: 700,
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                transform: { md: 'translateY(-2px)', xs: 'none' },
                                                boxShadow: `0 8px 24px ${theme.palette.primary.main}40`,
                                            },
                                            '&.Mui-disabled': {
                                                background: theme.palette.action.disabledBackground,
                                            }
                                        }}
                                    >
                                        {isSubmitting ? 'Sending...' : 'Send Message'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
