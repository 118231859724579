import React from 'react';
import { Modal, IconButton, Box, Typography, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import AddToCartButton from './AddToCartButton';
import QuantitySelector from './QuantitySelector'; // Assuming you have this component
import { useSelector } from 'react-redux';

export default function ProductModal({ open, onClose, product }) {
  const { guestCart, serverCart} = useSelector((state) => state.cart);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const cartItems = isAuthenticated ? serverCart : guestCart;
  const cartItem = cartItems.find((item) => item.product.id === product.id);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="product-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          maxWidth: '900px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 4,
          outline: 'none',
        }}
      >
        {/* Close Button */}
        <IconButton
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper.dark',
            },
          }}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        {/* Image Section */}
        <Box
          sx={{
            flex: 1,
            bgcolor: 'grey.100',
            borderRadius: 2,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: { xs: '250px', md: '400px' },
          }}
        >
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src={product.image}
            alt={product.name}
          />
        </Box>

        {/* Content Section */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Product Name */}
          <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary' }}>
            {product.name}
          </Typography>

          {/* Product Description */}
          <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
            {product.description}
          </Typography>

          <Divider />

          {/* Product Details */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
                Vendor
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {product.brand}
              </Typography>
            </Box> */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
                Status
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: product.is_in_stock ? 'success.main' : 'error.main' }}
              >
                {product.is_in_stock ? 'In Stock' : 'Out Of Stock'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
                Quantity
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {product.stock_quantity} {product.product_unit}
              </Typography>
            </Box>
          </Box>

          <Divider />

          {/* Pricing Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 700, color: 'primary.main' }}>
              ₹{product.discounted_price}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.disabled', textDecoration: 'line-through' }}
            >
              ₹{product.price}
            </Typography>
          </Box>

          {/* Add to Cart / Quantity Selector */}
          <Box sx={{ mt: 2 }}>
            {cartItem ? (
              <QuantitySelector cartItem={cartItem} quantity={cartItem.quantity} />
            ) : (
              <AddToCartButton product={product} size='large' />
            )}
          </Box>

          {/* View Details Link */}
          <Box sx={{ textAlign: 'center' }}>
            <Link
              to={`/product/${product.slug}`}
              style={{
                textDecoration: 'none',
                fontSize: '0.9rem',
                fontWeight: 500,
                color: 'primary.main',
                transition: 'color 0.3s',
                '&:hover': {
                  color: 'primary.dark',
                },
              }}
            >
              View Full Details
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}