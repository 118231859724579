import React from 'react'
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import QuantitySelector from '../../product/QuantitySelector';


export default function CartListItem({ item }) {
    return (
        <Card sx={{ my: 1, justifyContent: 'space-between', display: "flex", width: "100%", boxShadow: "none", overflow: "visible" }}>
            <Box sx={{ display: "flex", flex: 6, alignItems: "center", gap: 2 }}>
                <Box sx={{ position: "relative", width: 50, height: 50 }}>
                    <CardMedia
                        component="img"
                        image={item.product.image}
                        alt={item.product.name}
                        sx={{ width: "100%", height: "100%", borderRadius: 2 }}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="caption" sx={{ fontWeight: "800" }}>{item.product.name}</Typography>
                    <Typography sx={{ fontWeight: "600", color: "gray" }} variant="caption">x {item.quantity} {item.product_unit}</Typography>
                </Box>
            </Box>

            <Box sx={{ display: "flex", flex: 6, gap: 2, alignItems: "center" }}>
                <Box sx={{ pl: 5 }}>

                    <QuantitySelector cartItem={item} size="small" />
                </Box>
                <Typography color="primary" sx={{ fontWeight: 500 }} variant='body2'>
                    ₹{Math.round(item.product.offer_price || item.product.discounted_price) * item.quantity}
                </Typography>
            </Box>
        </Card>
    );
}
