import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EjectOutlinedIcon from '@mui/icons-material/EjectOutlined';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Badge } from '@mui/material';
import { logout } from '../../redux/slices/authSlice';
import { Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import { AccountCircle, ExitToApp, Login, PersonAdd, Replay, ShoppingBag } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';


const SettingDrawer = ({ open, onClose }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    onClose();
  };

  const listItems = isAuthenticated
    ? [
      {
        title: 'My Profile',
        link: '/account',
        icon: <AccountCircle />,
      },
      {
        title: 'Logout',
        action: handleLogout,
        icon: <ExitToApp />,
      },
    ]
    : [
      {
        title: 'Login',
        link: '/account/login',
        icon: <Login />,
      },
      // {
      //   title: 'Register Account',
      //   link: '/account/signUp',
      //   icon: <PersonAdd />,
      // },
      {
        title: 'My Cart',
        link: '/cart',
        icon: <ShoppingBag />,
      },
    ];

  return (
    <Drawer anchor="top" open={open} onClose={onClose}>
      <List >
        {listItems.map((item, index) => (
          <ListItemButton
            key={index}
            component={item.link ? Link : 'div'}
            to={item.link || undefined}
            onClick={item.action || undefined}
          >
            <ListItem divider onClick={onClose} sx={{ width: '100%' }}>
              <ListItemIcon sx={{ minWidth: 36 }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};



export default function MobileNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAuthenticated } = useSelector(state => state.auth);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const { serverCart, guestCart } = useSelector((state) => state.cart);

  const cart = isAuthenticated ? serverCart : guestCart;



  const theme = useTheme();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        background: theme.palette.primary.main,
        zIndex: 1200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        py: 1,
        boxShadow: '0px 0px 20px 5px rgba(0,0,0,0.4)',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}
    >
      <Link to="/">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
          }}
        >
          <HomeOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
          <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
            Home
          </Typography>
        </Box>
      </Link>

      <Link to="/shop">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
          }}
        >

          <ShoppingBagOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />

          <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
            Shop
          </Typography>
        </Box>
      </Link>

      <Link to="/cart">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
          }}
        >
          <Badge badgeContent={cart.length} color="secondary">
            <ShoppingCartOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
          </Badge>
          <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
            Cart
          </Typography>
        </Box>
      </Link>


      {/* <Box
        onClick={handleDrawerOpen}
        sx={{
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'column',
        }}
      >
        <SettingsOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
        <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
          Settings
        </Typography>
      </Box> */}

      <Link to={"/account/reorder"}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
          }}
        >
          <Replay fontSize="medium" sx={{ color: 'white' }} />
          <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
            Reorder
          </Typography>
        </Box>
      </Link>
          
                <Box
                  onClick={handleScrollToTop}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer',
                    flexDirection: 'column',
                  }}
                >
                  <EjectOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
                  <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
                    To Top
                  </Typography>
                </Box>
      <SettingDrawer open={drawerOpen} onClose={handleDrawerClose} />
    </Box>
  );
}
