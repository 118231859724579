import React from "react";
import { Box, Typography, Paper, Container } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SectionTitle from "../core/SectionTitle";

const coupons = [
  {
    id: 1,
    discount: "Flat ₹75 OFF",
    condition: "Orders Above ₹499",
    color: "linear-gradient(135deg, #FF6B6B 0%, #FF8E8E 100%)",
  },
  {
    id: 2,
    discount: "Flat ₹50 OFF",
    condition: "Orders Above ₹349",
    color: "linear-gradient(135deg, #4CAF50 0%, #81C784 100%)",
  },
  {
    id: 3,
    discount: "10% OFF",
    condition: "Orders Above ₹199",
    color: "linear-gradient(135deg, #FFD700 0%, #FFEE58 100%)",
  },
  {
    id: 4,
    discount: "Flat ₹100 OFF",
    condition: "Orders Above ₹799",
    color: "linear-gradient(135deg, #6A11CB 0%, #2575FC 100%)",
  },
];

const CouponCard = ({ discount, condition, color }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        background: color,
        padding: 1,
        width: { xs: 250, sm: 300 },
        height: { xs: 80, sm: 100 },
        flexShrink: 0,
        position: "relative",
        overflow: "hidden",
        transition: "transform 0.3s ease",
        '&:hover': {
          transform: "translateY(-4px)",
        },
        '&::before, &::after': {
          content: '""',
          width: 20,
          height: 20,
          backgroundColor: "white",
          borderRadius: "50%",
          position: "absolute",
        },
        '&::before': { top: 0, left: 50, transform: "translateY(-50%)" },
        '&::after': { bottom: 0, left: 50, transform: "translateY(50%)" },
      }}
    >
      {/* Glossy overlay effect */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "linear-gradient(120deg, rgba(255,255,255,0.15) 0%, transparent 100%)",
        }}
      />

      <Box
        sx={{
          width: { xs: 50, sm: 60 },
          height: { xs: 50, sm: 60 },
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.15)",
          mr: { xs: 1.5, sm: 2 },
          zIndex: 1,
        }}
      >
        <LocalOfferIcon sx={{ color: "white", fontSize: { xs: 24, sm: 30 } }} />
      </Box>

      <Box
        sx={{
          border: "2px dashed rgba(255,255,255,0.5)",
          borderRadius: "8px",
          p: { xs: 1, sm: 1.5 },
          background: "rgba(255,255,255,0.1)",
          backdropFilter: "blur(4px)",
          zIndex: 1,
        }}
      >
        <Typography sx={{
          fontWeight: 800,
          fontSize: { xs: 14, sm: 16 },
          color: "white",
          textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          mb: { xs: 0.5, sm: 0 }
        }}>
          {discount}
        </Typography>
        <Typography sx={{
          fontSize: { xs: 11, sm: 12 },
          color: "rgba(255,255,255,0.9)",
          fontWeight: 500,
        }}>
          {condition}
        </Typography>
      </Box>

      {/* Scissor cut effect */}
      <Box
        sx={{
          position: "absolute",
          right: { xs: -8, sm: -10 },
          top: "50%",
          transform: "translateY(-50%) rotate(-90deg)",
          color: "white",
          fontSize: { xs: 20, sm: 24 },
          textShadow: "0 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        ✂
      </Box>
    </Paper>
  );
};

const CouponsSection = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <SectionTitle t1={"Exclusive Deals"} t2={" Special offers curated just for you!"} />
        <Box sx={{
           display: "flex", gap: 2, mt: 2,  flexWrap: 'nowrap', overflowX:"auto",
          pb: { xs: 2, sm: 0 },
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none'
        }}>
          {coupons.map((coupon) => (
            <CouponCard key={coupon.id} {...coupon} />
          ))}
        </Box>
      </Box>
    </Container>

  );
};

export default CouponsSection;