import React, { useEffect, useRef } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Typography,
  LinearProgress,
  Box,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import FiltersNew from "../components/shop/FiltersNew";
import ProductCard from "../components/product/ProductCard";
import { loadProducts, setFilters } from "../redux/slices/productSlice";
import ErrorIcon from "@mui/icons-material/Error";
import { ArrowUpward } from "@mui/icons-material";

const ShopPageNew = () => {
  const dispatch = useDispatch();
  const { items, status, error, filters = {}, hasMore } = useSelector(
    (state) => state.products
  );
  const sentinelRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const tagsParam = searchParams.get("tags");
    const categoryParam = searchParams.get("category");
    const newFilters = { tags: [], category: " ", ...filters };

    if (tagsParam) {
      newFilters.tags = tagsParam.split(",");
    }
    if (categoryParam) {
      newFilters.category = categoryParam;
    }
    dispatch(setFilters(newFilters));
  }, []);


  const handleFilterChange = (newFilters) => {
    dispatch(setFilters(newFilters));

    const params = {};
    if (newFilters.tags && newFilters.tags.length > 0) {
      params.tags = newFilters.tags.join(",");
    }
    if (newFilters.category) {
      params.category = newFilters.category;
    }
    setSearchParams(params);
  };


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMore && status !== "loading") {
          dispatch(loadProducts());
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) observer.observe(sentinelRef.current);
    return () => {
      if (sentinelRef.current) observer.unobserve(sentinelRef.current);
    };
  }, [hasMore, status, dispatch]);

  useEffect(() => {
    dispatch(loadProducts());
  }, [dispatch, filters]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, px: { xs: 1, sm: 2 }, minHeight: "100vh" }}>
      <FiltersNew filters={filters} onChange={handleFilterChange} />

      {status === "loading" && !items.length && (
        <LinearProgress sx={{ width: "100%", mt: 2, height: 4 }} />
      )}

      <Grid container spacing={3} sx={{ mt: 2 }}>
        {items.map((product, index) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={2}
            key={product.id}
            sx={{
              opacity: 0,
              animation: "fadeIn 0.3s ease-in forwards",
              animationDelay: `${(index % 10) * 0.1}s`,
              "@keyframes fadeIn": {
                from: { opacity: 0, transform: "translateY(20px)" },
                to: { opacity: 1, transform: "translateY(0)" },
              },
            }}
          >
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>

      {!error && status !== "loading" && items.length === 0 && (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography variant="h6" color="textSecondary">
            No products found matching your criteria
          </Typography>
        </Box>
      )}

      <div ref={sentinelRef} style={{ height: "20px" }} />
      {!hasMore && items.length > 0 && (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            No more products to show
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowUpward />}
            onClick={() => {
              dispatch(setFilters({ tags: [], category: "" }));
              setSearchParams({});
              dispatch(loadProducts());
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            }}

          >
            Continue Shopping
          </Button>
        </Box>
      )}

      {status === "loading" && items.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress size={40} />
        </Box>
      )}

      {error && (
        <Typography
          color="error"
          align="center"
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ErrorIcon /> {error}
        </Typography>
      )}
    </Container>
  );
};

export default ShopPageNew;
