import React, { useState } from 'react';
import { 
    Box, Typography, Grid, Container, List, ListItem, ListItemIcon, 
    ListItemText, TextField, InputAdornment, IconButton, Button, 
    useTheme, useMediaQuery, darken, lighten 
} from '@mui/material';
import { 
    Email, Phone, LocationOn, Facebook, WhatsApp, Instagram, 
    LinkedIn, EmailOutlined, Send, Download 
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import api from '../../apiCollection';

const Footer = () => {
    const [email, setEmail] = useState('');
    const theme = useTheme();
    const primaryDark = darken(theme.palette.primary.main, 0.4);
    const secondaryLight = lighten(theme.palette.secondary.main, 0.3);

    const handleSubscribe = async () => {
        try {
            const response = await axios.post(api.newsletter, { email });
            const message = response.data.message || 'Subscription successful!';
            toast[response.status === 201 ? 'success' : 'info'](message, {
                position: 'top-center',
                theme: 'colored',
            });
            setEmail('');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Subscription failed. Please try again.', {
                position: 'top-center',
                theme: 'colored',
            });
        }
    };

    return (
        <Box
            component="footer"
            sx={{
                background: `
                    linear-gradient(145deg, 
                        ${primaryDark} 20%, 
                        ${theme.palette.secondary.main} 50%, 
                        ${darken(theme.palette.primary.main, 0.6)} 80%
                    )`,
                color: 'common.white',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Container maxWidth="xl" sx={{ py: 6, position: 'relative' }}>
                <Grid container spacing={6} justifyContent="space-between">
                    {/* Logo Section */}
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ 
                            width: 200, 
                            height: 200, 
                            p: 2, 
                            bgcolor: 'rgba(255,255,255,0.1)', 
                            borderRadius: '50%',
                            backdropFilter: 'blur(10px)'
                        }}>
                            <img 
                                src="/Vegingo-logo-circular.png" 
                                alt="VeginGo Logo" 
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        </Box>
                    </Grid>

                    {/* Newsletter Section */}
                    <Grid item xs={12} md={5} sx={{ textAlign: 'center' }}>
                        <Box sx={{ 
                            bgcolor: 'rgba(255,255,255,0.05)', 
                            p: 4, 
                            borderRadius: 4,
                            backdropFilter: 'blur(8px)'
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                                <EmailOutlined sx={{ 
                                    fontSize: 40, 
                                    color: secondaryLight,
                                    filter: 'drop-shadow(0 4px 6px rgba(0,0,0,0.2))'
                                }} />
                                <Typography variant="h4" sx={{ 
                                    fontWeight: 700,
                                    background: `linear-gradient(45deg, ${secondaryLight}, ${theme.palette.primary.light})`,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent'
                                }}>
                                    Stay Updated
                                </Typography>
                            </Box>
                            
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubscribe}
                                                endIcon={<Send />}
                                                sx={{
                                                    py: 1.5,
                                                    borderRadius: 2,
                                                    bgcolor: 'primary.main',
                                                    '&:hover': {
                                                        bgcolor: 'primary.dark',
                                                        transform: 'translateY(-2px)',
                                                        boxShadow: 4
                                                    },
                                                    transition: 'all 0.3s ease'
                                                }}
                                            >
                                                Subscribe
                                            </Button>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        bgcolor: 'rgba(255,255,255,0.1)',
                                        borderRadius: 2,
                                        '& input': {
                                            color: 'common.white',
                                            py: 1.5
                                        },
                                        '& fieldset': {
                                            borderColor: 'rgba(255,255,255,0.3)!important'
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Social Links */}
                    <Grid item xs={12} md={3}>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            gap: 2,
                            flexWrap: 'wrap'
                        }}>
                            {[
                                { icon: <Facebook fontSize='1.2rem'  />, color: '#1877F2', link: 'https://facebook.com' },
                                { icon: <WhatsApp  />, color: '#25D366', link: 'https://wa.me/918765126842' },
                                { icon: <Instagram  />, color: '#E1306C', link: 'https://instagram.com' },
                                { icon: <LinkedIn  />, color: '#0A66C2', link: 'https://linkedin.com' },
                                { icon: <Download  />, color: theme.palette.primary.main, link: '/install' },
                            ].map((item, index) => (
                                <Link key={index} to={item.link} target="_blank">
                                    <IconButton
                                        sx={{
                                            bgcolor: item.color,
                                            color: 'common.white',
                                            p: 1,
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.1) rotate(5deg)',
                                                boxShadow: `0 8px 16px ${darken(item.color, 0.2)}`
                                            }
                                        }}
                                    >
                                        {item.icon}
                                    </IconButton>
                                </Link>
                            ))}
                        </Box>
                    </Grid>
                </Grid>

                {/* Footer Content */}
                <Grid container spacing={4} sx={{ mt: 6 }}>
                    {/* About Section */}
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ 
                            mb: 3,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: 1,
                            color: secondaryLight
                        }}>
                            Why Choose Us?
                        </Typography>
                        <Box sx={{ 
                            bgcolor: 'rgba(255,255,255,0.05)',
                            p: 3,
                            borderRadius: 2,
                            backdropFilter: 'blur(8px)'
                        }}>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                🥦 Farm-Fresh Produce
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                🌱 Sustainable Practices
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                ⚡ Lightning-Fast Delivery
                            </Typography>
                            <Button 
                                component={Link}
                                to="/about-us"
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    mt: 2,
                                    borderColor: 'rgba(255,255,255,0.3)',
                                    '&:hover': {
                                        borderColor: secondaryLight,
                                        color: secondaryLight
                                    }
                                }}
                            >
                                Learn More →
                            </Button>
                        </Box>
                    </Grid>

                    {/* Quick Links */}
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ 
                            mb: 3,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: 1,
                            color: secondaryLight
                        }}>
                            Quick Links
                        </Typography>
                        <List disablePadding>
                            {[
                                { text: 'About Us', link: '/about-us' },
                                { text: 'FAQ', link: '/faq' },
                                { text: 'Blog', link: '/blog' },
                                { text: 'Careers', link: '/careers' },
                                { text: 'Contact', link: '/contact' },
                            ].map((item, index) => (
                                <ListItem 
                                    key={index} 
                                    disablePadding
                                    sx={{ mb: 1 }}
                                >
                                    <Button
                                        component={Link}
                                        to={item.link}
                                        fullWidth
                                        sx={{
                                            justifyContent: 'flex-start',
                                            color: 'common.white',
                                            px: 2,
                                            py: 1,
                                            borderRadius: 1,
                                            '&:hover': {
                                                bgcolor: 'rgba(255,255,255,0.1)'
                                            }
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {item.text}
                                        </Typography>
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    {/* Contact Info */}
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ 
                            mb: 3,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: 1,
                            color: secondaryLight
                        }}>
                            Get in Touch
                        </Typography>
                        <List disablePadding>
                            {[
                                { 
                                    icon: <LocationOn sx={{ color: secondaryLight }} />,
                                    text: 'Sector I, Jankipuram, Lucknow, UP 226021',
                                    link: 'https://maps.app.goo.gl/example'
                                },
                                { 
                                    icon: <Email sx={{ color: secondaryLight }} />,
                                    text: 'vegingo.help@gmail.com',
                                    link: 'mailto:vegingo.help@gmail.com'
                                },
                                { 
                                    icon: <Phone sx={{ color: secondaryLight }} />,
                                    text: '+91 8765126842',
                                    link: 'tel:+918765126842'
                                },
                            ].map((item, index) => (
                                <ListItem 
                                    key={index} 
                                    disablePadding
                                    sx={{ mb: 2 }}
                                >
                                    <Button
                                        component="a"
                                        href={item.link}
                                        startIcon={item.icon}
                                        fullWidth
                                        sx={{
                                            justifyContent: 'flex-start',
                                            color: 'common.white',
                                            px: 2,
                                            py: 1.5,
                                            borderRadius: 1,
                                            '&:hover': {
                                                bgcolor: 'rgba(255,255,255,0.1)'
                                            },
                                            textTransform:"none"
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {item.text}
                                        </Typography>
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Container>

            {/* Copyright Section */}
            <Box sx={{ 
                bgcolor: 'rgba(0,0,0,0.2)',
                py: 3,
                textAlign: 'center',
                borderTop: `1px solid ${theme.palette.divider}`
            }}>
                <Typography variant="body2" sx={{ letterSpacing: 1 }}>
                    © {new Date().getFullYear()} VeginGo. All rights reserved.
                    <Button 
                        component={Link}
                        to="/terms"
                        sx={{ 
                            ml: 2,
                            color: 'common.white',
                            textDecoration: 'underline',
                            '&:hover': { color: secondaryLight }
                        }}
                    >
                        Terms & Conditions
                    </Button>
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;