import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useTheme, alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const CouponItem = ({ coupon, selectedCoupon, onSelect }) => {
  const theme = useTheme();
  const date = new Date(coupon.end_date);
  const expiryDate = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  const expiryTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });

  const { guestCart, serverCart } = useSelector(state => state.cart);
  const { isAuthenticated } = useSelector(state => state.auth);
  const cartItems = isAuthenticated ? serverCart : guestCart;
  const subtotal = cartItems.reduce((acc, item) => {
    return acc + (item.product.price * (item.quantity ?? 1));
  }, 0);
  const isValidCoupon = subtotal >= Number(coupon.min_purchase_amt);
  const isSelected = selectedCoupon && selectedCoupon.id === coupon.id;

  return (
    <Box
      onClick={() => isValidCoupon && onSelect(coupon)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: isSelected
          ? `2px dashed ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        p: 2,
        mb: 2,
        backgroundColor: isSelected
          ? alpha(theme.palette.primary.main, 0.1)
          : theme.palette.background.paper,
        opacity: isValidCoupon ? 1 : 0.6,
        cursor: isValidCoupon ? 'pointer' : 'not-allowed',
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: isValidCoupon ? theme.shadows[3] : 'none',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Checkbox
          checked={isSelected}
          onChange={() => { }}
          disabled={!isValidCoupon}
          sx={{ p: 0, mr: 1 }}
        />
        <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 600 }}>
          {coupon.coupon_code}
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
        Save {coupon.description}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {coupon.discount_type === "percentage"
          ? `${coupon.discount_amount}% off on minimum purchase of Rs. ${coupon.min_purchase_amt}.`
          : `₹${parseInt(coupon.discount_amount)} off on minimum purchase of Rs. ${coupon.min_purchase_amt}.`}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        Expires on: {expiryDate}, {expiryTime}
      </Typography>
    </Box>
  );
};

export default CouponItem;
