import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createOrderFromCart } from '../redux/slices/orderSlice';
import axios from 'axios';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import authService from '../services/authService';

export default function PaymentButton({
    billDetails,
    selectedAddress,
    selectedCoupon,
    orderNote,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAuthToken = () => {
        const user = authService.getCurrentUser();
        const token = user ? user.token : null;
        
        return token;
    };

    

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('PhonePe');
    const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);

    const amount = billDetails?.grandTotal || 0;

    const handlePayment = async () => {
        if (!['PhonePe', 'POD'].includes(paymentMethod)) {
            toast.error('Please select a valid payment method.');
            return;
        }
        setIsModalOpen(false);

        try {
            setIsLoadingOverlay(true);

            await dispatch(
                createOrderFromCart({
                    addressId: selectedAddress,
                    orderNote,
                    selectedCoupon,
                    deliveryCharge: billDetails.deliveryCharge,
                    paymentMethod,
                })
            ).unwrap();

            if (paymentMethod === 'PhonePe') {
                await initiatePhonePePayment();
            } else if (paymentMethod === 'POD') {
                navigate('/order-confirmed', { replace: true });
            }
        } catch (error) {
            console.error('Error placing order:', error);
            toast.error('Failed to place order. Please try again.');
        } finally {
            setIsLoadingOverlay(false);
            setIsModalOpen(false);
        }
    };

    const initiatePhonePePayment = async () => {
        if (!amount || amount <= 0) {
            toast.error('Invalid payment amount.');
            return;
        }

        const token = getAuthToken();

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/phonepe`,
                { amount },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.status === 200 && response.data?.url) {
                window.location.href = response.data.url;
            } else {
                toast.error(
                    response.data?.message || 'Failed to create payment.'
                );
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            toast.error(
                error.response?.data?.message || 'Error initiating payment'
            );
        }
    };

    return (
        <Box>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setIsModalOpen(true)}
                sx={{ color: 'white' }}
            >
                Proceed to pay
            </Button>

            {/* Modal for Payment Selection */}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="payment-modal-title"
                aria-describedby="payment-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        width: { md: 400, xs: 300 },
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        position: 'relative',
                    }}
                >
                    <Typography
                        id="payment-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ mb: 2 }}
                    >
                        Select Payment Method
                    </Typography>

                    <IconButton
                        onClick={() => setIsModalOpen(false)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>

                    <Divider />
                    <RadioGroup
                        value={paymentMethod}
                        onChange={(e) => {
                            setPaymentMethod(e.target.value);
                        }}
                        sx={{ mb: 2 }}
                    >
                        <FormControlLabel
                            value="PhonePe"
                            control={<Radio />}
                            label="Pay Online"
                        />
                        <FormControlLabel
                            value="POD"
                            control={<Radio />}
                            label="Pay on Delivery (POD)"
                        />
                    </RadioGroup>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handlePayment}
                        sx={{ color: 'white', mt: 2 }}
                    >
                        Confirm Payment Method
                    </Button>
                </Box>
            </Modal>

            {/* Loading Overlay */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.appBar + 1,
                }}
                open={isLoadingOverlay}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
