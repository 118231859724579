import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import useApiFetch from '../hooks/useApiFetch';
import Loading from '../components/core/Loading';
import BlogCard from '../components/blog/BlogCard';
import { api } from '../apiCollection';
import Error from '../components/core/Error';
import Seo from '../components/Seo/Seo';
import { keyframes } from '@mui/system';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-8px); }
  100% { transform: translateY(0); }
`;

const tags = [
    'nutrition', 'health', 'wellness', 'gardening', 'organic',
    'sustainability', 'environment', 'farming', 'technology',
    'innovation', 'green'
];

const BlogPage = () => {
    const allBlogsUrl = api.blog.getAll;
    const { data, loading, error } = useApiFetch(allBlogsUrl);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const blogData = data?.data;

    if (loading) return <Loading />;
    if (error) return <Error code={error.request.status} />;

    const handleTagClick = (tag) => {
        setSelectedTags(prev => prev.includes(tag)
            ? prev.filter(t => t !== tag)
            : [...prev, tag]
        );
    };

    const filteredBlogs = blogData?.filter(blog => {
        const searchMatch = blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            blog.content.toLowerCase().includes(searchQuery.toLowerCase());
        const tagMatch = selectedTags.length > 0
            ? selectedTags.every(tag => blog.tags.includes(tag))
            : true;
        return searchMatch && tagMatch;
    }) || [];

    return (
        <Box sx={{ minHeight: '100vh', bgcolor: 'background.paper' }}>
            <Seo title="Blog - VeginGo" />
            <Container maxWidth="xl" sx={{ py: { xs: 4, sm: 8 } }}>
                <Grid container spacing={4}>
                    {/* Filters Sidebar */}
                    <Grid item xs={12} md={3}>
                        <Box sx={{
                            position: 'sticky',
                            top: 80,
                            bgcolor: 'background.default',
                            borderRadius: 4,
                            p: 3,
                            boxShadow: 2,
                        }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search articles..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        borderRadius: 3,
                                        bgcolor: 'background.paper',
                                        '&:hover fieldset': { borderColor: 'primary.main' }
                                    }
                                }}
                            />

                            <Typography variant="h6" sx={{
                                mt: 3,
                                mb: 2,
                                fontWeight: 700,
                                color: 'text.primary'
                            }}>
                                Explore Topics
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                '& .MuiChip-root': {
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    }
                                }
                            }}>
                                {tags.map(tag => (
                                    <Chip
                                        key={tag}
                                        label={tag}
                                        onClick={() => handleTagClick(tag)}
                                        variant={selectedTags.includes(tag) ? 'filled' : 'outlined'}
                                        color="primary"
                                        sx={{
                                            fontWeight: 600,
                                            ...(selectedTags.includes(tag) && {
                                                background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
                                                color: 'white'
                                            })
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    {/* Blog Posts Grid */}
                    <Grid item xs={12} md={9}>
                        {filteredBlogs.length === 0 ? (
                            <Box sx={{
                                height: '60vh',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                animation: `${floatAnimation} 3s ease-in-out infinite`
                            }}>
                                <Typography variant="h5" sx={{
                                    mb: 2,
                                    fontWeight: 700,
                                    color: 'text.secondary'
                                }}>
                                    🧐 No articles found
                                </Typography>
                                <Typography color="textSecondary">
                                    Try adjusting your search or filters
                                </Typography>
                            </Box>
                        ) : (
                            <Grid container spacing={4}>
                                {filteredBlogs.map(blog => (
                                    <Grid item xs={12} sm={6} lg={4} key={blog.id}>
                                        <BlogCard
                                            blog={blog}
                                            sx={{
                                                transition: 'transform 0.3s ease',
                                                '&:hover': {
                                                    transform: 'translateY(-8px)'
                                                }
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default BlogPage;