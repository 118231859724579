import React from "react";
import { Grid, Typography, Box, Container, keyframes } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VerifiedIcon from "@mui/icons-material/Verified";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { styled } from "@mui/material/styles";

const rotate = keyframes`
  from { transform: rotateY(0deg); }
  to { transform: rotateY(360deg); }
`;

const FeatureCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  height: "100%",
  minHeight: 280,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.shadows[4],
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    minHeight: 'auto',
    padding: theme.spacing(3),
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[8],
    '& .feature-icon': {
      animation: `${rotate} 1s ease`,
    },
    '& .feature-title': {
      background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }
  },
}));

const Features = () => {
  const features = [
    {
      icon: <LocalShippingIcon />,
      title: "Fast Delivery",
      description: "Get fresh vegetables and fruits delivered to your doorstep within 24 hours.",
    },
    {
      icon: <AccessTimeIcon />,
      title: "Freshness Guaranteed",
      description: "Farm-to-table produce with maximum freshness and nutrition preserved.",
    },
    {
      icon: <VerifiedIcon />,
      title: "Quality Assured",
      description: "Stringent quality checks and 100% organic certification.",
    },
    {
      icon: <SupportAgentIcon />,
      title: "24/7 Support",
      description: "Instant support through chat, email, or phone.",
    },
  ];

  return (
    <Box sx={{
      bgcolor: 'background.default',
      py: 8,
      background: `linear-gradient(135deg, 
        rgba(245, 245, 245, 0.8) 0%,
        rgba(255, 255, 255, 0.9) 100%)`,
    }}>
      <Container maxWidth="lg">
        <Grid container spacing={{ xs: 2, sm: 4 }} justifyContent="center">
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FeatureCard>
                <Box className="feature-icon" sx={{
                  width: { xs: 50, sm: 80 },
                  height: { xs: 50, sm: 80 },
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: 'rgba(76, 175, 80, 0.1)',
                  mb: { xs: 0, sm: 3 },
                  mr: { xs: 2, sm: 0 },
                  transition: 'all 0.3s ease',
                }}>
                  {React.cloneElement(feature.icon, {
                    sx: {
                      fontSize: { xs: 30, sm: 40 },
                      color: '#2E7D32'
                    }
                  })}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    className="feature-title"
                    variant={"h6"}
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      mb: { xs: 0.5, sm: 2 },
                      textAlign: { xs: 'left', sm: 'center' },
                      transition: 'all 0.3s ease',
                      fontSize: { xs: "1rem", md: "1.3rem" }
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      textAlign: { xs: 'left', sm: 'center' },
                      lineHeight: { xs: 1.3, md: 1.6 },
                      fontSize: { xs: "0.75rem", md: "0.85rem" },
                      px: { xs: 0, sm: 2 },
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;