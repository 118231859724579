import { Box, Grid, Typography, Card, CardContent, Container } from "@mui/material";
import { green, yellow } from "@mui/material/colors";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const WideRangeBanner = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  return (
    <Box
      sx={{
        position: "relative",
        py: { xs: 3, sm: 5 },
        backgroundColor: "#f8f8f8",
        mt: { xs: 3, sm: 10 }
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="center"
        >
          {/* Image Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ textAlign: "center" }}
          >
            <Box
              component="img"
              src="https://images.pexels.com/photos/2751755/pexels-photo-2751755.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt="Fresh Vegetables"
              sx={{
                width: { xs: "100%", sm: "80%", md: "100%" },
                maxHeight: { xs: "300px", sm: "400px", md: "500px" },
                borderRadius: 2,
                objectFit: "cover",
              }}
            />
          </Grid>

          {/* Text Section */}
          <Grid
            item
            xs={12}
            md={6}
            ref={ref}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ fontSize: { xs: "1.8rem", sm: "2.2rem" } }}
            >
              Wide Range of Fresh & Quality Products
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              mt={2}
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Explore a variety of fresh fruits, vegetables, and daily essentials carefully selected to meet your needs and enhance your lifestyle.
            </Typography>

            {/* Animated Counting (Runs when in view) */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                mt: 2
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color="green"
                  sx={{ fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" } }}
                >
                  {inView ? <CountUp start={0} end={10000} duration={2.5} separator="," suffix="+" /> : "10K+"}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
                >
                  Happy Customers & Counting
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color="green"
                  sx={{ fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" } }}
                >
                  {inView ? <CountUp start={0} end={500} duration={2.5} separator="," suffix="+" /> : "500+"}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
                >
                  Products Available
                </Typography>
              </Box>
            </Box>

            {/* Feature Cards */}
            <Grid container spacing={4} mt={2}>
              <Grid item  xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: green[600],
                    color: "white",
                    p: 2,
                    width: "100%",
                    boxShadow: 3
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                  <Box component="img" src="/vegetable-basket-icon.png" alt="Quick Delivery" sx={{ width: 50, mb: 1,display: "block", mx: "auto" }} />
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
                    >
                      Diverse Product Range
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: yellow[700],
                    color: "black",
                    p: 2,
                    width: "100%",
                    boxShadow: 3
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                  <Box component="img" src="/nature-product-icon.png" alt="Quick Delivery" sx={{ width: 50, mb: 1,display: "block", mx: "auto" }} />
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
                    >
                      Convenience & Quality
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WideRangeBanner;
