import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Divider,
  useTheme,
  Grid
} from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import GoogleLoginButton from '../components/google-auth/GoogleLoginButton';

const SendOTPPage = () => {
  const [mobile, setMobile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/client/sendOtpForLogin`, {
        phone_number: mobile
      });
      toast.success('OTP sent successfully!');
      navigate('/account/verify-otp', { state: { mobile } });
    } catch (err) {
      toast.error(err.response?.data?.message || 'Failed to send OTP');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ minHeight: '80vh', display: "flex", justifyContent: "center", alignItems: 'center' }}>
      <Grid container>
        <Grid item sm={6} xs={12}>  <Box sx={{
          width: '100%',
          p: {xs:0, md:4},
          mt:{xs:2, md:0},
          boxShadow: "none",
          backgroundColor: 'background.paper'
        }}>
          <Typography color={"primary"} variant="h4" component="h1" align="center" gutterBottom sx={{ fontWeight: 700 }}>
            Welcome Back
          </Typography>
          <Typography variant="body1" align="center" color="text.secondary" mb={4}>
            Enter your mobile number to sign in or create an account
          </Typography>

          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              label="Mobile Number"
              placeholder="Enter 10-digit number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value.replace(/\D/g, '').slice(0, 10))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon color="action" />
                  </InputAdornment>
                ),
                inputProps: {
                  pattern: '[0-9]{10}',
                  style: { fontSize: '1.1rem' }
                }
              }}
              sx={{ mb: 3 }}
            />

            <Button
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              disabled={mobile.length !== 10 || isLoading}
              sx={{
                py: 1.5,
                borderRadius: 2,
                textTransform: 'none',
                bgcolor: theme.palette.primary.main,
                '&:hover': {
                  bgcolor: theme.palette.primary.dark
                }
              }}
            >
              {isLoading ? 'Sending OTP...' : 'Continue with Mobile'}
            </Button>
          </Box>

          <Divider sx={{ my: 4, color: 'text.secondary' }}>
            <Typography variant="body2" color="text.secondary">OR</Typography>
          </Divider>
          <Box sx={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <GoogleLoginButton />

          </Box>
        </Box></Grid>
        <Grid item sm={6} xs={12}>
          <Box sx={{ width: "100%", height: "100%", maxHeight: "500px", }}>
            <img src="/login_illustration.svg" style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="" />

          </Box>
        </Grid>
      </Grid>

    </Container>
  );
};

export default SendOTPPage;