import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Chip,
  Radio,
  Divider,
  Box,
  Stack
} from '@mui/material';
import {
  Home,
  MoreVert,
  Edit,
  Delete,
  Star,
  Business,
} from '@mui/icons-material';
import { removeAddress, setDefaultAddress } from '../../redux/slices/addressSlice';
import { setSelectedAddress } from '../../redux/slices/orderSlice';

const AddressItem = ({ address, onEdit, enableRadio, selectedAddressId, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleMoreClick = (event) => setAnchorEl(event.currentTarget);
  const handleMoreClose = () => setAnchorEl(null);

  const handleEdit = () => {
    onEdit(address);
    handleMoreClose();
  };

  const handleDelete = () => {
    dispatch(removeAddress(address.id));
    handleMoreClose();
  };

  const handleMakeDefault = () => {
    dispatch(setDefaultAddress(address.id));
    handleMoreClose();
  };

  const handleChange = () => {
    onSelect(address.id);
    dispatch(setSelectedAddress(address));
  };

  useEffect(() => {
    if (address.is_default === 1 && selectedAddressId !== address.id) {
      onSelect(address.id);
      dispatch(setSelectedAddress(address));
    }
  }, [address, selectedAddressId, onSelect, dispatch]);

  return (
    <Card 
      variant="outlined"
      sx={{
        mb: 2,
        position: 'relative',
        border: selectedAddressId === address.id ? '2px solid' : '1px solid',
        borderColor: selectedAddressId === address.id ? 'primary.main' : 'divider',
        transition: 'all 0.2s ease',
        '&:hover': {
          boxShadow: 1,
          borderColor: 'primary.light'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', p: 2 }}>
        
        <Avatar sx={{ 
          mr: 2, 
          mt: 1,
          bgcolor: 'primary.main', 
          color: 'white',
          width: 40,
          height: 40
        }}>
          {address.location_type === "home"?<Home/>:<Business/>}
          
        </Avatar>

        <CardContent sx={{ flex: 1, p: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1" fontWeight={600}>
              {address.user_name}
            </Typography>
            {address.is_default === 1 && (
              <Chip
                label="Default"
                size="small"
                variant="outlined"
                color="primary"
                sx={{ ml: 1.5, borderRadius: 1 }}
              />
            )}
          </Box>

          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              {address.building_name_area}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address.landmark}, {address.city} - {address.pincode}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address.state}, {address.country}
            </Typography>
            <Typography variant="body2" color="text.secondary" mt={1}>
              Phone: {address.phone_number}
            </Typography>
          </Stack>
        </CardContent>

        <IconButton 
          onClick={handleMoreClick}
          sx={{ alignSelf: 'flex-start', color: 'text.secondary' }}
        >
          <MoreVert fontSize="small" />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMoreClose}
        elevation={2}
      >
        <MenuItem onClick={handleEdit} dense>
          <Edit fontSize="small" sx={{ mr: 1.5, color: 'text.secondary' }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} dense>
          <Delete fontSize="small" sx={{ mr: 1.5, color: 'text.secondary' }} />
          Delete
        </MenuItem>
        <Divider />
        <MenuItem 
          onClick={handleMakeDefault} 
          dense
          disabled={address.is_default === 1}
        >
          <Star fontSize="small" sx={{ mr: 1.5, color: 'text.secondary' }} />
          {address.is_default === 1 ? 'Default Address' : 'Make Default'}
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default AddressItem;