import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Button,
    Container,
    TextField,
    Typography,
    Box,
    IconButton,
    useTheme,
    CircularProgress,
    Grid
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { verifyOTP } from '../redux/slices/authSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SmsIcon from '@mui/icons-material/Sms';
import axios from 'axios';

const VerifyOTPPage = () => {
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(60);
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (!state?.mobile) {
            toast.error('Mobile number not found');
            navigate('/');
        }
    }, [state, navigate]);

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [resendTimer]);

    const handleResendOTP = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/client/resendOtpForLogin`, {
                phone_number: state.mobile,
                retry_type: "text"
            });
            toast.success('OTP resent successfully!');
            setResendTimer(60);
            setOtp('');
        } catch (err) {
            toast.error(err.response?.data?.message || 'Failed to resend OTP');
        }
    };

    const handleChange = (index, value) => {
        if (/^\d$/.test(value) || value === '') {
            const newOtp = otp.split('');
            newOtp[index] = value;
            const joinedOtp = newOtp.join('').slice(0, 6);
            setOtp(joinedOtp);

            if (value && index < 5) {
                const nextInput = document.getElementById(`otp-input-${index + 1}`);
                if (nextInput) nextInput.focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text/plain').replace(/\D/g, '').slice(0, 6);
        if (pastedData.length === 6) {
            setOtp(pastedData);
            document.getElementById(`otp-input-5`).focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (otp.length !== 6) return;
        setIsLoading(true);

        try {
            const response = await dispatch(
                verifyOTP({
                    phone_number: state.mobile,
                    otp: otp,
                })
            ).unwrap();

            if (response?.message?.includes('New user detected')) {
                navigate('/account/register', { state: { mobile: state.mobile, otp } });
            } else {
                navigate('/');
            }
        } catch (err) {
            toast.error(err.message || 'Invalid OTP. Please try again.');
            setOtp('');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="lg" sx={{
            minHeight: '80vh',
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
        }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Box sx={{
                        width: '100%',
                        p: { xs: 0, md: 4 },
                        mt: { xs: 2, md: 0 },
                        backgroundColor: 'background.paper',
                        position: 'relative'
                    }}>

                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <SmsIcon color='primary' sx={{ fontSize: "3rem" }} />
                        </Box>

                        <Typography color={"primary"} variant="h4" component="h1" align="center" gutterBottom sx={{ fontWeight: 700 }}>
                            Verify OTP
                        </Typography>
                        <Typography variant="body1" align="center" color="text.secondary">
                            We've sent a 6-digit code to
                        </Typography>
                        <Typography variant="body1" align="center" sx={{ fontWeight: 500 }}>
                            {state?.mobile}
                        </Typography>

                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
                            <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
                                {[...Array(6)].map((_, index) => (
                                    <Grid item key={index}>
                                        <TextField
                                            variant="outlined"
                                            value={otp[index] || ''}
                                            onChange={(e) => handleChange(index, e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            onPaste={handlePaste}
                                            inputProps={{
                                                id: `otp-input-${index}`,
                                                maxLength: 1,
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                sx: {
                                                    textAlign: 'center',
                                                    fontSize: '1.5rem',
                                                    padding: '12px',
                                                    width: { xs: '15px', md: "40px" },
                                                    height: { xs: '15px', md: "40px" },
                                                }
                                            }}
                                            autoFocus={index === 0}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                    '& fieldset': {
                                                        borderColor: theme.palette.grey[300],
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: theme.palette.primary.main,
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: theme.palette.primary.main,
                                                        boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.2)',
                                                    },
                                                }
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                type="submit"
                                disabled={otp.length !== 6 || isLoading}
                                sx={{
                                    py: 1.5,
                                    borderRadius: 2,
                                    textTransform: 'none',
                                    bgcolor: theme.palette.primary.main,
                                    '&:hover': {
                                        bgcolor: theme.palette.primary.dark
                                    }
                                }}
                            >
                                {isLoading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Verify Code'
                                )}
                            </Button>

                            <Box sx={{
                                textAlign: 'center',
                                mt: 3,
                                color: 'text.secondary'
                            }}>
                                {resendTimer > 0 ? (
                                    <Typography variant="body2">
                                        Resend code in {resendTimer}s
                                    </Typography>
                                ) : (
                                    <Button
                                        variant="text"
                                        onClick={handleResendOTP}
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: '1rem'
                                        }}
                                    >
                                        Didn't receive code? Resend
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%", height: "100%", maxHeight: "500px" }}>
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain"
                            }}
                            src="/verify_illustration.svg"
                            alt="OTP Verification"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default VerifyOTPPage;