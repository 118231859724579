import { Box, Card, CardContent, Chip, Typography, useMediaQuery, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QuantitySelector from "./QuantitySelector";
import AddToCartButton from "./AddToCartButton";
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import ProductModal from "./ProductModal";
import { useTheme } from "@mui/material/styles";
import BoltIcon from '@mui/icons-material/Bolt';

export default function ProductCard({ product, listView = false }) {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { serverCart, guestCart } = useSelector((state) => state.cart);
  const navigate = useNavigate();

  const cart = isAuthenticated ? serverCart : guestCart;
  const cartItem = cart.find(item => item.product?.id === product.id);

  const discountPercentage = Math.round(
    ((product.price - product.discounted_price) / product.price * 100
    ));

  const handleOpenModal = () => {
    if (isMobileScreen) {
      navigate(`/product/${product.slug}`);
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <Card
      sx={{
        maxWidth: listView ? "100%" : { xs: "100%", sm: 300 },
        display: "flex",
        flexDirection: listView ? "row" : "column",
        alignItems: listView ? "center" : "stretch",
        p: { xs: 1, sm: 2 },
        position: "relative",
        borderRadius: 3,
        boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 32px rgba(0,0,0,0.12)',
          '.productImg': { transform: "scale(1.05)" },
          '.details-button': { opacity: 1 }
        }
      }}
    >
      {/* Image Container */}
      <Box sx={{
        position: "relative",
        width: listView ? { xs: 100, sm: 160 } : "100%",
        flexShrink: 0,
        overflow: 'hidden',
        borderRadius: 2,
        aspectRatio: '1/1'
      }}>
        {/* Image Overlay Elements */}
        <IconButton
          className="details-button"
          onClick={handleOpenModal}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 2,
            opacity: isMobileScreen ? 1 : 0,
            transition: 'opacity 0.3s',
            bgcolor: 'rgba(255,255,255,0.9)',
            backdropFilter: 'blur(4px)',
            boxShadow:1,
            '&:hover': { bgcolor: 'rgba(255,255,255,1)' }
          }}
        >
          <FullscreenOutlinedIcon fontSize="small" />
        </IconButton>

        {/* Discount & Badges */}
        <Stack direction="row" spacing={0.5} sx={{
          position: "absolute",
          top: 8,
          left: 8,
          zIndex: 2
        }}>
          {discountPercentage > 0 && (
            <Chip
              label={`${discountPercentage}% OFF`}
              size="small"
              sx={{
                background: 'linear-gradient(135deg, #ff5f6d, #ffc371)',
                color: 'white',
                fontWeight: 700,
                fontSize: '0.75rem',
                height: 24
              }}
            />
          )}
          {product.bestseller && (
            <Chip
              label={<><BoltIcon sx={{ fontSize: 14, mr: 0.5 }} />Bestseller</>}
              color="success"
              size="small"
              sx={{ fontWeight: 600, height: 24 }}
            />
          )}
        </Stack>

        {/* Product Image */}
        <Box
          component="img"
          className="productImg"
          src={product.image}
          alt={product.name}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transition: "transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,0.1) 100%)'
            }
          }}
        />
      </Box>

      {/* Product Details */}
      <CardContent
        sx={{
          textAlign: listView ? "left" : "center",
          flex: 1,
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1
        }}
      >
        {/* Product Name */}
        <Typography
          variant={isMobileScreen ? "body2" : "body1"}
          sx={{
            fontWeight: 700,
            lineHeight: 1.2,
            height: listView ? 'auto' : 40,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
          }}
        >
          {product.name}
        </Typography>

        {/* Quantity */}
        <Typography variant="caption" sx={{
          color: 'text.secondary',
          fontSize: '0.75rem',
          fontWeight: 500
        }}>
          {product.item_quantity} {product.product_unit}
        </Typography>

        {/* Pricing */}
        <Stack direction="row" alignItems="baseline" spacing={1} sx={{ mt: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 800,
              background:'linear-gradient(45deg, #2e7d32, #43a047)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            ₹{product.discounted_price}
          </Typography>
          {discountPercentage > 0 && (
            <Typography
              variant="body2"
              sx={{
                color: 'text.disabled',
                textDecoration: "line-through",
                fontSize: '0.875rem'
              }}
            >
              ₹{product.price}
            </Typography>
          )}
        </Stack>
      </CardContent>

      {/* Cart Controls */}
      <Box sx={{
        mt: 'auto',
        display: 'flex',
        justifyContent: 'center'
      }}>
        {cartItem ? (
          <QuantitySelector
            cartItem={cartItem}
            size={listView && isMobileScreen ? "small" : "medium"}
            
          />
        ) : (
          <AddToCartButton
            product={product}
            size={listView && isMobileScreen ? "small" : "medium"}
           
          />
        )}
      </Box>

      {/* Product Modal */}
      {!isMobileScreen && (
        <ProductModal
          open={openModal}
          onClose={handleCloseModal}
          product={product}
        />
      )}
    </Card>
  );
}