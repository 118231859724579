import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cartService from "../../services/cartService";

// Fetch cart from server
export const fetchCart = createAsyncThunk("cart/fetchCart", async () => {
  const response = await cartService.getCartItems();
  return response.data;
});

// Sync guest cart to server on login
export const syncGuestCartToServer = createAsyncThunk(
  "cart/syncGuestCartToServer",
  async (_, { getState, dispatch }) => {
    const { isAuthenticated } = getState().auth;
    const { guestCart } = getState().cart;

    

    if (!isAuthenticated) {
      
      return;
    }

    if (guestCart.length === 0) {
      
      return;
    }

    try {
      
      await Promise.all(
        guestCart.map((item) =>
          cartService.addItemToCart(
            item.product.id,
            item.product.product_unit
          )
        )
      );

      
      dispatch(clearGuestCart());
      dispatch(fetchCart());
    } catch (error) {
      console.error("Error syncing guest cart:", error);
      throw new Error("Cart sync failed");
    }
  }
);


// Add item to cart (handles both guest and server)
export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (item, { getState, dispatch }) => {
    const { isAuthenticated } = getState().auth;

    if (isAuthenticated) {
      const response = await cartService.addItemToCart(
        item.id,
        item.product_unit
      );
      dispatch(fetchCart());
      return response.data;
    } else {
      return item;
    }
  }
);

// Update item in cart
export const updateCart = createAsyncThunk(
  "cart/updateCart",
  async ({ itemId, itemData }, { getState, dispatch }) => {
    const { isAuthenticated } = getState().auth;

    if (isAuthenticated) {
      const response = await cartService.updateCartItem(itemId, itemData);
      // dispatch(fetchCart()); // Refresh cart after update
      return response.data;
    } else {
      // Update guest cart locally
      return { itemId, itemData };
    }
  }
);

// Remove item from cart (handles both guest and server)
export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (itemId, { getState, dispatch }) => {
    const { isAuthenticated } = getState().auth;

    if (isAuthenticated) {
      await cartService.deleteCartItem(itemId);
      dispatch(fetchCart());
    } else {
      dispatch(removeFromGuestCart(itemId));
    }
  }
);

// Clear entire cart (handles both guest and server)
export const clearCart = createAsyncThunk(
  "cart/clearCart",
  async (_, { getState, dispatch }) => {
    const { isAuthenticated } = getState().auth;

    if (isAuthenticated) {
      await cartService.clearCart();
      dispatch(fetchCart());
    } else {
      dispatch(clearGuestCart());
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    guestCart: [],
    serverCart: [],
    loading: {
      fetchCart: false,
      addToCart: false,
      updateCart: false,
    },
  },
  reducers: {
    clearGuestCart: (state) => {
      state.guestCart = [];
    },
    removeFromGuestCart: (state, action) => {
      state.guestCart = state.guestCart.filter(
        (item) => item.product.id !== action.payload
      );
    },
    updateGuestCartItem: (state, action) => {
      const { itemId, itemData } = action.payload;
      const itemIndex = state.guestCart.findIndex(
        (item) => item.product.id === itemId
      );
      if (itemIndex !== -1) {
        state.guestCart[itemIndex] = {
          ...state.guestCart[itemIndex],
          ...itemData,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Cart Cases
      .addCase(fetchCart.pending, (state) => {
        state.loading.fetchCart = true;
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.loading.fetchCart = false;
        state.serverCart = action.payload;
      })
      .addCase(fetchCart.rejected, (state) => {
        state.loading.fetchCart = false;
      })

      // Add to Cart Cases
      .addCase(addToCart.pending, (state) => {
        state.loading.addToCart = true;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.loading.addToCart = false;
        if (!state.serverCart.length) {
          state.guestCart = state.guestCart || [];
          state.guestCart.push({
            product: action.payload,
            quantity: 1,
          });
        }
      })
      .addCase(addToCart.rejected, (state) => {
        state.loading.addToCart = false;
      })

      // Update Cart Cases (More Granular)
      .addCase(updateCart.pending, (state) => {
        state.loading.updateCart = true;
      })
      .addCase(updateCart.fulfilled, (state, action) => {
        state.loading.updateCart = false;
        if (!state.serverCart.length) {
          state.guestCart = state.guestCart.map((item) =>
            item.product.id === action.payload.itemId
              ? { ...item, ...action.payload.itemData }
              : item
          );
        }
      })
      .addCase(updateCart.rejected, (state) => {
        state.loading.updateCart = false;
      });
  },
});

export const { clearGuestCart, removeFromGuestCart, updateGuestCartItem } =
  cartSlice.actions;

// Persist only the guest cart
const persistConfig = {
  key: "guestCart",
  storage,
  whitelist: ["guestCart"],
};

export default persistReducer(persistConfig, cartSlice.reducer);
