import React, { useState } from 'react';
import {
    Box, Typography, List, ListItem, Divider, Button, IconButton, Stepper, Step, StepLabel, StepContent,
    Card, CardContent, Grid, useTheme, useMediaQuery, Stack,
    CircularProgress
} from '@mui/material';
import {
    ShoppingCart, LocalShipping, CheckCircle, Close, Download, ReceiptLong,
    Inventory2, DoNotDisturbOn,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import api from '../../apiCollection';
import toast from 'react-hot-toast';


const VerticalOrderStepper = ({ currentStep, steps, isCancelled }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card variant="outlined" sx={{ mb: 3, borderRadius: 2 }}>
            <CardContent sx={{ p: isMobile ? 1.5 : 2 }}>
                <Stepper activeStep={isCancelled ? steps.length - 1 : currentStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel
                                sx={{ py: isMobile ? 1 : 2 }}
                                icon={
                                    <Box sx={{
                                        width: isMobile ? 28 : 32,
                                        height: isMobile ? 28 : 32,
                                        borderRadius: "50%",
                                        border: `2px solid ${isCancelled && index === steps.length - 1
                                            ? theme.palette.error.main
                                            : currentStep >= index && !isCancelled
                                                ? theme.palette.primary.main
                                                : theme.palette.divider}`,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor: isCancelled && index === steps.length - 1
                                            ? theme.palette.error.main
                                            : currentStep >= index && !isCancelled
                                                ? theme.palette.primary.main
                                                : 'transparent',
                                        transition: 'all 0.3s ease'
                                    }}>
                                        {React.cloneElement(step.icon, {
                                            sx: {
                                                color: isCancelled && index === steps.length - 1
                                                    ? theme.palette.common.white
                                                    : currentStep >= index && !isCancelled
                                                        ? theme.palette.common.white
                                                        : theme.palette.text.secondary,
                                                fontSize: isMobile ? 16 : 20
                                            }
                                        })}
                                    </Box>
                                }
                            >
                                <Typography
                                    variant={isMobile ? "body2" : "subtitle1"}
                                    fontWeight={600}
                                    color={isCancelled && index === steps.length - 1 ? "error" : "inherit"}
                                >
                                    {step.label}
                                </Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography variant="body2" color="text.secondary">
                                    {step.description}
                                </Typography>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </CardContent>
        </Card>
    );
};

const OrderDetails = ({ onClose, order, dateTime }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { token } = useSelector(state => state.auth);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDownloadInvoice = async () => {
        setIsDownloading(true);
        const toastId = toast.loading('Preparing invoice...');
        
        try {
            const response = await axios.get(
                `${api.order.downloadInvoice}/${order.id}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: "blob",
                    timeout: 15000 
                }
            );
    
            // Validate response structure
            if (!(response.data instanceof Blob)) {
                throw new Error('Invalid invoice format received');
            }
    
            // File name formatting
            const invoiceDate = new Date(order.created_at)
                .toLocaleDateString('en-IN', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                })
                .replace(/\//g, '-');
    
            const fileName = `Invoice_ORD${order.id}_${invoiceDate}.pdf`;
    
            // Create temporary download link
            const tempLink = document.createElement('a');
            const objectUrl = window.URL.createObjectURL(
                new Blob([response.data], { type: response.headers['content-type'] })
            );
            
            tempLink.href = objectUrl;
            tempLink.download = fileName;
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            
            // Trigger download and clean up
            tempLink.click();
            window.URL.revokeObjectURL(objectUrl);
            document.body.removeChild(tempLink);
    
            toast.success('Invoice ready! Download started...', { 
                id: toastId,
                duration: 3000 
            });
        } catch (error) {
            console.error('Download error:', error);
            
            // Enhanced error messages
            const errorMessage = axios.isCancel(error) 
                ? 'Request cancelled'
                : error.response?.status === 404
                ? 'Invoice not found'
                : error.message.includes('network')
                ? 'Network error - check your connection'
                : 'Failed to generate invoice';
    
            toast.error(`Download failed: ${errorMessage}`, { 
                id: toastId,
                duration: 5000 
            });
        } finally {
            setIsDownloading(false);
                        
            setTimeout(() => toast.dismiss(toastId), 10000);
        }
    };
    const steps = [
        { label: "Order Placed", description: "We have received your order.", icon: <ShoppingCart /> },
        { label: "Processing", description: "Your order is being prepared.", icon: <Inventory2 /> },
        { label: "Out for Delivery", description: "Your order is on the way.", icon: <LocalShipping /> },
        { label: "Delivered", description: "Your order has been delivered.", icon: <CheckCircle /> },
        { label: "Cancelled", description: "Your order has been cancelled.", icon: <DoNotDisturbOn sx={{ color: 'error.main' }} /> }
    ];

    const getCurrentStep = () => {
        switch (order.status) {
            case "CONFIRMED": return 0;
            case "PROCESSING": return 1;
            case "OUT-FOR-DELIVERY": return 2;
            case "DELIVERED": return 3;
            case "CANCELED": return 4;
            default: return 0;
        }
    };

    const isCancelled = order.status === "CANCELED";

    return (
        <Box sx={{
            width: "100%",
            bgcolor: 'background.default',
            borderRadius: 2,
        }}>
            {/* Header */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
                position: 'sticky',
                top: 0,
                bgcolor: 'background.default',
                zIndex: 1,
                py: 1,
            }}>
                <Typography variant={isMobile ? "h6" : "h5"} fontWeight={700} color="text.primary">
                    Order Details
                </Typography>
                <IconButton onClick={onClose} size="small" sx={{ color: 'text.secondary' }}>
                    <Close fontSize={isMobile ? "small" : "medium"} />
                </IconButton>
            </Box>

            {/* Order Stepper */}
            <VerticalOrderStepper currentStep={getCurrentStep()} steps={steps} isCancelled={isCancelled} />

            {/* Order Items */}
            <Card variant="outlined" sx={{ mb: 3, borderRadius: 2 }}>
                <CardContent sx={{ p: isMobile ? 1.5 : 2 }}>
                    <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                        {order.order_item.length} Items in Your Order
                    </Typography>
                    <List disablePadding>
                        {order.order_item.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem disableGutters sx={{ py: 1.5 }}>
                                    <Grid container spacing={isMobile ? 1 : 2} alignItems="center">
                                        <Grid item xs={4} sm={2}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: isMobile ? 64 : 72,
                                                    borderRadius: 1.5,
                                                    overflow: 'hidden',
                                                    bgcolor: 'background.paper',
                                                    boxShadow: 1,
                                                    transition: 'transform 0.2s ease',
                                                    '&:hover': { transform: 'scale(1.05)' }
                                                }}
                                            >
                                                <img
                                                    src={item.product.image}
                                                    alt={item.product.name}
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={7}>
                                            <Typography variant={isMobile ? "body2" : "body1"} fontWeight={500}>
                                                {item.product.name}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                {item.quantity} {item.product_unit}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={3} sx={{ textAlign: 'right' }}>
                                            <Typography variant={isMobile ? "body2" : "body1"} fontWeight={500}>
                                                ₹{item.product.discounted_price * item.quantity}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    textDecoration: 'line-through',
                                                    color: 'text.disabled'
                                                }}
                                            >
                                                ₹{item.product.price * item.quantity}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                {index < order.order_item.length - 1 && <Divider sx={{ my: 1 }} />}
                            </React.Fragment>
                        ))}
                    </List>
                </CardContent>
            </Card>

            {/* Order Summary and Information */}
            <Grid container spacing={isMobile ? 1.5 : 3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: isMobile ? 1.5 : 2 }}>
                            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                                Order Summary
                            </Typography>
                            <Stack spacing={1.5}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body2">Item Total</Typography>
                                    <Typography variant="body2">₹{order.subtotal}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body2">Delivery Fee</Typography>
                                    <Typography variant="body2" color={order.shipping_charge ? 'inherit' : 'success.main'}>
                                        {order.shipping_charge ? `₹${order.shipping_charge}` : "FREE"}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body2">Discount</Typography>
                                    <Typography variant="body2" color="success.main">
                                        - ₹{order.discount}
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: 1 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="subtitle1" fontWeight={600}>
                                        Total Amount
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={600} color="primary">
                                        ₹{order.grand_total}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: isMobile ? 1.5 : 2 }}>
                            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                                Order Information
                            </Typography>
                            <Stack spacing={1.5}>
                                <Box>
                                    <Typography variant="caption" color="text.secondary">
                                        Order ID
                                    </Typography>
                                    <Typography variant="body2">ORD{order.id}</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="text.secondary">
                                        Payment Method
                                    </Typography>
                                    <Typography variant="body2">Pay on Delivery</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="text.secondary">
                                        Delivery Address
                                    </Typography>
                                    <Typography variant="body2">
                                        {order.address.user_name}<br />
                                        {order.address.building_name_area}<br />
                                        {order.address.landmark}<br />
                                        {order.address.city}, {order.address.pincode}<br />
                                        {order.address.country}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="text.secondary">
                                        Order Date
                                    </Typography>
                                    <Typography variant="body2">{dateTime}</Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Action Buttons */}
            <Box sx={{
                display: 'flex',
                gap: 2,
                flexDirection: isMobile ? 'column' : 'row',
                pb: isMobile ? 2 : 0
            }}>
                {!isCancelled && (
                    <Button
                        variant="contained"
                        fullWidth={isMobile}
                        startIcon={isDownloading ?
                            <CircularProgress size={20} color="inherit" /> :
                            <Download />}
                        onClick={handleDownloadInvoice}
                        disabled={isDownloading}
                        size={isMobile ? "small" : "medium"}
                        sx={{
                            color: "white",
                            flex: 1,
                            '&:disabled': { backgroundColor: theme.palette.action.disabledBackground }
                        }}
                    >
                        {isDownloading ? 'Downloading...' : 'Download Invoice'}
                    </Button>
                )}
                <Button
                    variant="outlined"
                    fullWidth={isMobile}
                    startIcon={<ReceiptLong />}
                    size={isMobile ? "small" : "medium"}
                    sx={{ flex: 1 }}
                >
                    Order Support
                </Button>
            </Box>
        </Box>
    );
};

export default OrderDetails;