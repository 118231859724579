import React from 'react';
import { Grid, Card, Typography, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import SectionTitle from '../core/SectionTitle';
const categories = [
  { 
    name: "Salad Veggies", 
    items: 125, 
    image: "/salad-bowl.webp", 
    bgColor: "linear-gradient(135deg, #A8D08D 0%, #6B8E23 100%)", 
    link: "/shop?tags=salad-veggies" 
  },
  { 
    name: "Stir-Fry Veggies", 
    items: 65, 
    image: "/stir-fry.png", 
    bgColor: "linear-gradient(135deg, #FFD966 0%, #FFA500 100%)", 
    link: "/shop?tags=stir-fry-veggies" 
  },
  { 
    name: "Indian Cooking", 
    items: 60, 
    image: "/indian-food.png", 
    bgColor: "linear-gradient(135deg, #C6E0B4 0%, #4F7942 100%)", 
    link: "/shop?&category=Groceries" 
  },
  { 
    name: "Chinese Cooking", 
    items: 420, 
    image: "/chinese-food.webp", 
    bgColor: "linear-gradient(135deg, #F4B183 0%, #D2691E 100%)", 
    link: "/shop?tags=chinese" 
  },
  { 
    name: "Soup Veggies", 
    items: 83, 
    image: "/soup.png", 
    bgColor: "linear-gradient(135deg, #FFE599 0%, #DAA520 100%)", 
    link: "/shop/?tags=soup-veggies" 
  },
  { 
    name: "Daily Essentials", 
    items: 200, 
    image: "/grocery.png", 
    bgColor: "linear-gradient(135deg, #9BB7D4 0%, #005B96 100%)", 
    link: "/shop?tags=groceries" 
  },
];

const CategoryCard = ({ name, items, image, bgColor, link }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          padding: {xs:0,md:2},
          textAlign: 'center',
          boxShadow: 'none',
          background: 'transparent',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'translateY(-8px)',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            margin: '0 auto',
            width: isSmallScreen ? 100 : 150,
            height: isSmallScreen ? 100 : 150,
            '&:hover .category-image': {
              transform: 'scale(1.1) rotate(5deg)',
            },
            '&:hover .item-count': {
              opacity: 1,
              transform: 'translateY(0)',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              background: bgColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              boxShadow: 3,
            }}
          >
            <Box
              component="img"
              src={image}
              alt={name}
              className="category-image"
              sx={{
                width: '80%',
                height: '80%',
                objectFit: 'contain',
                transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))',
              }}
            />
          </Box>
          
          {/* <Typography
            className="item-count"
            variant="caption"
            sx={{
              position: 'absolute',
              bottom: 8,
              left: '50%',
              transform: 'translateX(-50%)',
              bgcolor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              px: 1,
              borderRadius: 1,
              fontSize: '0.75rem',
              opacity: 0,
              transition: 'all 0.3s ease',
            }}
          >
            {items} items
          </Typography> */}
        </Box>
        
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            fontWeight: 600,
            color: theme.palette.text.primary,
            fontSize: isSmallScreen ? '0.65rem' : '1.1rem',
            transition: 'color 0.3s ease',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          {name}
        </Typography>
      </Card>
    </Link>
  );
};

const PopularCategoryFilter = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 5, md: 10 } }}>
      <SectionTitle 
        t1="Popular Categories" 
        t2="Explore Our Top Picks" 
        sx={{ mb: 6, textAlign: 'center' }}
      />

      <Grid container justifyContent="center">
        {categories.map((category, index) => (
          <Grid item xs={4} sm={4} md={3} lg={2} key={index}>
            <CategoryCard {...category} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PopularCategoryFilter;