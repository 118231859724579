import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import authService from "../../services/authService";
import localStorageHelper from "../../helpers/localStorageHelper";
import { syncGuestCartToServer } from "./cartSlice";
import axios from "axios";

const userData = localStorageHelper.getItem("user");

const initialState = {
  isAuthenticated: !!userData,
  user: userData,
  token: userData ? userData.token : null,
  error: null,
  deviceToken: null,
};

export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async ({ phone_number, otp, name, email }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/client/verifyOtpForLogin`,
        { phone_number, otp, name, email }
      );

      if (
        !response.data.success &&
        response.data.message.includes("Invalid or expired OTP")
      ) {
        return rejectWithValue(response.data.message);
      }

      setTimeout(async () => {
        try {
          await dispatch(syncGuestCartToServer()).unwrap();
        } catch (cartError) {
          console.error("Cart sync failed:", cartError);
        }
      }, 500);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "OTP verification failed"
      );
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const data = await authService.login(email, password);
      setTimeout(async () => {
        try {
          await dispatch(syncGuestCartToServer()).unwrap();
        } catch (cartError) {
          console.error("Cart sync failed:", cartError);
        }
      }, 500);
      return data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "Login failed";
      console.error("Error during login:", error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (
    { name, email, mobileNumber, password, confirmPassword },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const data = await authService.signup(
        name,
        email,
        mobileNumber,
        password,
        confirmPassword
      );
      setTimeout(async () => {
        try {
          await dispatch(syncGuestCartToServer()).unwrap();
        } catch (cartError) {
          console.error("Cart sync failed:", cartError);
        }
      }, 500);

      return data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "Signup failed";
      console.error("Error during signup:", error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      const response = await authService.googleLogin(code);

      setTimeout(async () => {
        try {
          await dispatch(syncGuestCartToServer()).unwrap();
        } catch (cartError) {
          console.error("Cart sync failed:", cartError);
        }
      }, 500);

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "Google login failed";
      console.error("Error during Google login:", error);
      return rejectWithValue(errorMessage);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.error = null;
      authService.logout();
      localStorage.clear();
      toast.success("Logged out successfully", {
        position: "top-center",
      });
    },
    setDeviceToken: (state, action) => {
      state.deviceToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOTP.fulfilled, (state, action) => {
        if (action.payload.token) {
          state.isAuthenticated = true;
          state.user = action.payload.user;
          state.token = action.payload.token;
          state.error = null;

          localStorageHelper.setItem("user", {
            name: action.payload.user.name,
            email: action.payload.user.email,
            id:action.payload.user.id,
            token: action.payload.token,
          });

          if (!action.payload.message?.includes("New user detected")) {
            toast.success("OTP verification successful!");
          }
        }
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.data;
        state.token = action.payload.data.token;
        state.error = null;
        const { name, email, token, id } = action.payload.data;
        localStorageHelper.setItem("user", { name, email, token, id });
        toast.success("Login successful!");

        // if (state.cart.products && state.cart.products.length > 0) {
        //   action.asyncDispatch((state.cart.products));
        // }
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
        toast.error(`${action.payload}`);
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.data;
        state.token = action.payload.data.token;
        state.error = null;
        const { name, email, token, id } = action.payload.data;
        localStorageHelper.setItem("user", { name, email, token, id});
        toast.success("Signup successful!");
      })
      .addCase(signup.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
        toast.error(`${action.payload}`);
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.token = action.payload.token;
        state.error = null;
        const { name, email, token, id } = action.payload;
        localStorageHelper.setItem("user", { name, email, token, id});
        toast.success("Google login successful!");

        // if (state.products && state.products.length > 0) {
        //   action.asyncDispatch(mergeCart(state.cart.products));
        // }
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
        toast.error(`Google login failed: ${action.payload}`);
      });
  },
});

export const { logout, setDeviceToken } = authSlice.actions;

export default authSlice.reducer;
